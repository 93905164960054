import React, { Dispatch, FC, SetStateAction, useEffect, useMemo, useRef, useState } from "react";
import {
	Button,
	Form,
	Input,
	FormInstance,
	Spin,
	Select,
	Col,
	Row,
	message,
	Checkbox,
	Typography,
	Tooltip,
	notification,
} from "antd";
import styled from "@emotion/styled";
import MetaForm from "./MetaForm";
import { ModalTitleWithLastModifiedDate, VideoFormModal } from "../../utils/StyledComponents";
import { ActiveDatesObj, ActivePlatformModalData, MetadataType } from "../videos";
import {
	tagsFilterOptions,
	getDigitsOnly,
	getDurationOnly,
	getBucketKeyWithURL,
	videoListfilterOptions,
	copyString,
	getVideoDataByGracenoteId,
	updateDataWithGracenoteData,
	metadataSelectHandler,
} from "../../utils";
import { probeVideo } from "../../utils/video_api";
import { DistributionDataPropsTypes } from "../../episode";
import { TagOption } from "../../tags/hooks/utils";
import { CopyOutlined, EditOutlined, QuestionCircleOutlined } from "@ant-design/icons";
import { CloseIcon } from "../../custom-components";
import { CustomBtn } from "../../series/StyledComponent";
import { CheckboxValueType } from "antd/es/checkbox/Group";
import { CheckboxChangeEvent } from "antd/es/checkbox";
import ActiveDates from "./ActiveDates";

export const getFileNameWithURL = (url: string) => {
	try {
		const decodedURL = decodeURIComponent(url.replace(/\+/g, " "));
		const [, filePath] = decodedURL.split("//");
		if (filePath) {
			const filePathArr = filePath.split("/");
			const fileName = filePathArr[filePathArr.length - 1];
			return fileName;
		}
		return null;
	} catch (error) {
		console.error(error);
		return null;
	}
};

export const setFileName = (e: any, form: FormInstance, fieldName: string) => {
	try {
		const value = e.target.value;
		const fileName = getFileNameWithURL(value);
		if (fileName) form.setFieldValue([fieldName], fileName);
	} catch (error) {
		console.error(error);
	}
};

export const SubmitButton = styled(Button)`
	color: #000000 !important;
	font-weight: 600;
	background: #feea3c !important;
	border: 5px solid #feea3c !important;
	display: flex;
	justify-content: center;
	align-items: center;
`;

export const AddTagInputContainer = styled.div`
	display: flex;
	margin-bottom: 8px;
	button {
		margin-left: 8px;
	}
`;

export const GracenoteIdCol = styled(Col)`
	display: flex;
	align-items: center;
	justify-content: space-between;

	& .ant-form-item {
		width: 100%;
	}
`;

interface Props extends DistributionDataPropsTypes {
	isOpen: boolean;
	saveVideosData(formArray: any): void;
	form: FormInstance;
	isLoading: boolean;
	setIsLoading(value: boolean): void;
	setIsAddNewMetadata(value: boolean): void;
	setMetadataEditId(value: any): void;
	formArray: string[];
	setFormArray(value: string[]): void;
	videoEditId: string | undefined | null;
	metadataEditId: string | undefined | null;
	addNewInputHelper(): void;
	handleCancel: () => void;
	durationValue: string | number;
	setDurationValue(value: string): void;
	relatedMetadata: MetadataType[];
	metadataSelector: (value: string) => void;
	isAddNewMetadata: boolean;
	ref_id: string | undefined;
	languages: LanguageData[];
	changeHandler(): void;
	tagsData: any[];
	handleAddCustomTag: (
		customTag: string,
		related_to: "video" | "thumbnail",
		insertValue: (tagId: string) => void
	) => void;
	distributionPlatform: any[];
	videosData?: any[];
	videoLists: any[];
	activeDatesList: ActiveDatesObj[];
	setActiveDatesList: Dispatch<SetStateAction<ActiveDatesObj[]>>;
}

export type LanguageData = {
	ID: string;
	CODE: string;
	NAME: string;
};

const AddVideosMetaDataForm: FC<Props> = ({
	metadataSelector,
	setMetadataEditId,
	setIsAddNewMetadata,
	isAddNewMetadata,
	relatedMetadata,
	handleCancel,
	videoEditId,
	formArray,
	setFormArray,
	isOpen,
	saveVideosData,
	form,
	isLoading,
	ref_id,
	languages,
	changeHandler,
	tagsData,
	handleAddCustomTag,
	distributionPlatform,
	distributionCheckedList,
	distributionChange,
	onDistributionCheckAllChange,
	checkAll,
	indeterminate,
	videosData,
	videoLists,
	activeDatesList,
	setActiveDatesList,
}) => {
	const selectRef = useRef<any>();
	const [inputValue, setInputValue] = useState<string>("");
	const [timeoutId, setTimeoutId] = useState<NodeJS.Timeout | undefined>(undefined);
	const [gracenoteList, setGracenoteList] = useState<string[]>([]);
	const [isActiveDatesModalOpen, setIsActiveDatesModalOpen] = useState<boolean>(false);
	const [activeDatePlatforModalName, setActiveDatePlatforModalName] = useState<string>("");
	const [activeDateModalData, setActiveDateModalData] = useState<ActivePlatformModalData>();
	const [isFromLoading, setIsFromLoading] = useState<boolean>(false);
	useEffect(() => {
		const updateArr = gracenoteList;
		for (const video of videosData ?? []) {
			const tempGracenoteId = video.gracenote_episodic_tms_id;
			if (tempGracenoteId) {
				updateArr.push(tempGracenoteId);
			}
		}
		const update = Array.from(new Set(gracenoteList));
		setGracenoteList(update);
	}, [videosData]);

	const videoDetailsHandler = async (e: any) => {
		const videoURL = e.target.value;
		const { Key, Bucket, type } = getBucketKeyWithURL(videoURL);
		if (Key && Bucket) {
			message.info("Fetching video details. Keep updating other data.");
			const response = await probeVideo(Bucket, Key, videoURL);
			if (response.videoDurationHHMMSS) {
				form.setFieldsValue({
					video_type: type,
					video_size: response.videoSize,
					duration: response.videoDurationHHMMSS,
					video_width: response.videoWidth,
					video_height: response.videoHeight,
				});
				message.success("Video details fetched successfully");
			} else {
				message.error(response.error ?? "Fetching video details failed");
				form.resetFields(["video_type", "video_size", "duration", "video_width", "video_height"]);
			}
		} else {
			form.resetFields(["video_type", "video_size", "duration", "video_width", "video_height"]);
		}
	};

	useEffect(() => {
		setInputValue("");
	}, [ref_id, isOpen]);

	const activeDatesObj = useMemo(() => {
		const tempObj: any = {};
		activeDatesList.map(activeDate => (tempObj[activeDate.platformId] = { ...activeDate }));
		return tempObj;
	}, [activeDatesList]);

	const activeVideoListHandler = (list: CheckboxValueType[]) => {
		try {
			const tempList = list.map((value): ActiveDatesObj => {
				const { platformName, startDate, endDate, platformId } =
					activeDatesObj[value as string] ?? {};
				const newPlatformName = distributionPlatform.filter(platform => platform.id === value);
				return platformName
					? { platformId, platformName, startDate, endDate }
					: {
							platformId: value,
							startDate: "",
							endDate: "",
							platformName: newPlatformName[0]?.name ?? "",
					  };
			});
			setActiveDatesList(tempList);
		} catch (error) {
			console.error(error);
		}
	};
	const selectAllHandler = (e: CheckboxChangeEvent) => {
		onDistributionCheckAllChange(e);
		activeVideoListHandler(e.target.checked ? distributionPlatform.map(dt => dt.id) : []);
	};

	const activeDatesModalOpenHandler = (platformName: string, platformId: string) => {
		setIsActiveDatesModalOpen(true);
		setActiveDatePlatforModalName(platformName);
		setActiveDateModalData({ platformId, platformName });
	};

	const updateWithGracenoteId = async () => {
		try {
			setIsFromLoading(true);
			const gracenoteId = form.getFieldValue("gracenote_episodic_tms_id");
			if (!gracenoteId)
				return notification.error({
					message: "Gracenote Episode TMS Id not found",
					description: "Please fill in the Gracenote Episode TMS Id",
				});
			const currentMetaId = form.getFieldValue("meta_id");
			if (!currentMetaId?.length) {
				form.setFieldValue("meta_id", "add_new");
				metadataSelectHandler(
					"add_new",
					form,
					setMetadataEditId,
					setIsAddNewMetadata,
					setFormArray
				);
			}
			const { data } = await getVideoDataByGracenoteId(gracenoteId);
			updateDataWithGracenoteData(form, data);
			setIsFromLoading(false);
			notification.success({
				message: null,
				description: "Data filled successfully in respective fields.",
			});
		} catch (error: any) {
			setIsFromLoading(false);
			if (error?.response?.status === 404)
				return notification.error({
					message: null,
					description: "Provided Gracenote Episode TMS Id does not exist",
				});
			console.error("error in updateWithGracenoteId", error);
		}
	};

	const saveActiveDateHandler = (startDate: string, endDate: string) => {
		const tempObj = {
			...activeDatesObj,
			[activeDateModalData?.platformId as string]: {
				...activeDatesObj[activeDateModalData?.platformId as string],
				startDate,
				endDate,
			},
		};
		setActiveDatesList(Object.values(tempObj));
		closeActiveDateHandler();
	};

	const closeActiveDateHandler = () => {
		setIsActiveDatesModalOpen(false);
		setActiveDateModalData(undefined);
	};

	return (
		<>
			<VideoFormModal
				ref_id={ref_id}
				videoEditId={videoEditId as string | undefined}
				destroyOnClose={true}
				maskClosable={false}
				centered
				title={
					videoEditId ? (
						<ModalTitleWithLastModifiedDate
							title="Update Video and Meta Info..."
							lastModifiedDate={form.getFieldValue("video_updated_at")}
						/>
					) : (
						"Add Video and Meta Info..."
					)
				}
				open={isOpen}
				onOk={() => saveVideosData(formArray)}
				onCancel={() => {
					handleCancel();
					setFormArray(["1"]);
				}}
				okText={videoEditId ? "Update Video" : "Save Video"}
				okButtonProps={{ disabled: isLoading }}
				cancelButtonProps={{ disabled: isLoading }}
			>
				<Spin spinning={isLoading || isFromLoading}>
					<Form
						onChange={() => changeHandler()}
						requiredMark={false}
						form={form}
						name="basic"
						autoComplete="off"
						layout="vertical"
					>
						<Row id="video-form-main-row" gutter={[16, 16]}>
							<Col id="video-details" span={12}>
								<Row>
									<Col span={12}>
										<Form.Item
											label="Ref Id"
											name="video_ref_id"
											rules={[{ required: true, message: "Required Field" }]}
										>
											<Input style={{ width: "220px" }} disabled />
											{/* <Typography.Title level={4}>{ref_id}</Typography.Title> */}
										</Form.Item>
									</Col>
									{ref_id && (
										<Col span={12}>
											<Form.Item
												label="Audience Language"
												key={"audience_language"}
												name={"audience_language"}
												rules={[{ required: true, message: "Required Field" }]}
											>
												<Select placeholder="Language" allowClear={{ clearIcon: <CloseIcon /> }}>
													{languages?.map((language: LanguageData) => (
														<Select.Option value={language.NAME} key={language.ID}>
															{language.NAME}
														</Select.Option>
													))}
												</Select>
											</Form.Item>
										</Col>
									)}
								</Row>
								{ref_id && (
									<>
										<Form.Item label="Video Url" name="video_url">
											<Input
												onChange={e => {
													clearTimeout(timeoutId);
													const newTimeoutId = setTimeout(async () => {
														videoDetailsHandler(e);
													}, 1000);
													setTimeoutId(newTimeoutId);
													setFileName(e, form, "video_file_name");
												}}
											/>
										</Form.Item>
										<Form.Item label="File Name" name="video_file_name">
											<Input placeholder="File Name" />
										</Form.Item>
										<Row justify="space-around" gutter={[16, 16]}>
											<Col span={8}>
												<Form.Item
													label="Video Language"
													key={"video_language"}
													name={"video_language"}
													// rules={[{ required: true, message: "Required Field" }]}
												>
													<Select placeholder="Language" allowClear={{ clearIcon: <CloseIcon /> }}>
														{languages?.map((language: LanguageData) => (
															<Select.Option value={language.NAME} key={language.ID}>
																{language.NAME}
															</Select.Option>
														))}
													</Select>
												</Form.Item>
											</Col>
											<Col span={8}>
												<Form.Item
													label="Dubbed Language"
													key={"dub_language"}
													name={"dub_language"}
												>
													<Select placeholder="Language" allowClear={{ clearIcon: <CloseIcon /> }}>
														{languages?.map((language: LanguageData) => (
															<Select.Option value={language.NAME} key={language.ID}>
																{language.NAME}
															</Select.Option>
														))}
													</Select>
												</Form.Item>
											</Col>
											<Col span={8}>
												<Form.Item
													label="Subtitled Language"
													key={"sub_language"}
													name={"sub_language"}
												>
													<Select placeholder="Language" allowClear={{ clearIcon: <CloseIcon /> }}>
														{languages?.map((language: LanguageData) => (
															<Select.Option value={language.NAME} key={language.ID}>
																{language.NAME}
															</Select.Option>
														))}
													</Select>
												</Form.Item>
											</Col>
										</Row>

										<Form.Item label="Video Tags" name="video_tags">
											<Select
												allowClear={{ clearIcon: <CloseIcon /> }}
												mode="multiple"
												filterOption={tagsFilterOptions}
												dropdownRender={menu => (
													<>
														<AddTagInputContainer>
															<Input
																value={inputValue}
																onChange={e => setInputValue(e.target.value)}
																style={{ width: "100%" }}
																placeholder="Add new tag"
															/>
															<Button
																onClick={() => {
																	if (!inputValue?.length) message.error("Please enter a value!");
																	else
																		handleAddCustomTag(inputValue, "video", () => {
																			setInputValue("");
																		});
																}}
															>
																Add
															</Button>
														</AddTagInputContainer>
														{menu}
													</>
												)}
												ref={selectRef}
											>
												{tagsData
													.filter((tag: TagOption) => tag.related_to === "video")
													.map((tag: TagOption) => (
														<Select.Option value={tag.id} key={tag.id}>
															{tag.title}
														</Select.Option>
													))}
											</Select>
										</Form.Item>
										<Form.Item label="Video Lists" name="video_lists">
											<Select
												placeholder="Select Video List"
												allowClear
												mode="multiple"
												filterOption={videoListfilterOptions}
											>
												{videoLists.map((videoList: any) => {
													return (
														<Select.Option key={videoList.id} value={videoList.id}>
															{videoList.name}
														</Select.Option>
													);
												})}
											</Select>
										</Form.Item>
										<Form.Item label="Zype category values" name="zype_category_values">
											<Input placeholder="Zype category values" />
										</Form.Item>
										<Form.Item name="meta_id" label="Meta">
											<Select placeholder="Existing Metadata" onChange={metadataSelector}>
												<Select.Option value="add_new">Add New Metadata</Select.Option>
												{relatedMetadata?.map((metadata: MetadataType) => (
													<Select.Option value={metadata.ID} key={metadata.ID}>
														{metadata.LANGUAGE}-{metadata.TITLE}
													</Select.Option>
												))}
											</Select>
										</Form.Item>
										{isAddNewMetadata &&
											formArray.map((ele, index) => (
												<MetaForm
													key={"title" + index}
													languagesList={languages}
													ele={ele}
													index={index}
												/>
											))}
										{/* <Button disabled={Boolean(videoEditId)} type="default" onClick={() => addNewInputHelper()}>			
										+ Meta
									</Button> */}

										<Row gutter={[16, 0]}>
											<GracenoteIdCol span={16}>
												<Form.Item
													label={
														<>
															Gracenote Episodic TMS Id &nbsp;
															<Tooltip title="Only one gracenote id is allowed per video">
																<QuestionCircleOutlined />
															</Tooltip>
														</>
													}
													name="gracenote_episodic_tms_id"
												>
													<Select
														maxCount={1}
														mode="tags"
														options={gracenoteList
															.map(grac => ({ value: grac, label: grac }))
															.filter(grac => grac.value.trim())}
													/>
												</Form.Item>
												<CustomBtn
													style={{ cursor: "copy" }}
													title="Copy Gracenote Episodic TMS Id"
													icon={<CopyOutlined />}
													type={"link"}
													onClick={e => {
														e.stopPropagation();
														copyString(form.getFieldValue("gracenote_episodic_tms_id"));
													}}
												/>
											</GracenoteIdCol>
											<Col span={8}>
												<Form.Item label=" ">
													<Button onClick={updateWithGracenoteId} type="primary">
														Gracenote Update
													</Button>{" "}
													&nbsp;
													<Tooltip title="Fills in empty data in both this video, and the parent episode, from Gracenote using the Gracenote Episode TMS Id to find the episode.">
														<QuestionCircleOutlined />
													</Tooltip>
												</Form.Item>
											</Col>
										</Row>
										<Row gutter={[16, 0]}>
											<Col span={8}>
												<Form.Item label="Width (Pixels)" name="video_width">
													<Input
														placeholder="Width(in pixels)"
														onChange={e =>
															form.setFieldValue("video_width", getDigitsOnly(e.target.value))
														}
													/>
												</Form.Item>
											</Col>
											<Col span={8}>
												<Form.Item label="Height (Pixels)" name="video_height">
													<Input
														placeholder="Height(in pixels)"
														onChange={e =>
															form.setFieldValue("video_height", getDigitsOnly(e.target.value))
														}
													/>
												</Form.Item>
											</Col>
											<Col span={8}>
												<Form.Item label="Duration" name="duration">
													<Input
														placeholder="Duration"
														maxLength={12}
														onChange={e =>
															form.setFieldValue("duration", getDurationOnly(e.target.value))
														}
													/>
												</Form.Item>
											</Col>
										</Row>
										<Row gutter={[16, 0]}>
											<Col span={12}>
												<Form.Item label="Type" name="video_type">
													<Input placeholder="Type" />
												</Form.Item>
											</Col>
											<Col span={12}>
												<Form.Item label="Size" name="video_size">
													<Input
														placeholder="Size(in bytes)"
														onChange={e =>
															form.setFieldValue("size", getDigitsOnly(e.target.value))
														}
													/>
												</Form.Item>
											</Col>
										</Row>
									</>
								)}
							</Col>
							<Col id="distribution-details" span={4}>
								<Typography.Title level={3}>Distribution</Typography.Title>
								<Checkbox
									indeterminate={indeterminate}
									onChange={selectAllHandler}
									checked={checkAll}
								>
									{checkAll ? "De-Select All" : "Select All"}
								</Checkbox>
								<Checkbox.Group
									onChange={(list: CheckboxValueType[]) => {
										distributionChange(list);
										activeVideoListHandler(list);
									}}
									value={distributionCheckedList}
								>
									<Row>
										{distributionPlatform.map((platform: any) => {
											return (
												<Col span={24} key={platform.key}>
													<Checkbox value={platform.id} key={platform.key}>
														{platform.name}
													</Checkbox>
												</Col>
											);
										})}
									</Row>
								</Checkbox.Group>
							</Col>
							<Col id="distribution-range-system" span={8}>
								<Typography.Title level={3}>Active Dates</Typography.Title>
								<Row>
									{activeDatesList.length
										? activeDatesList.map(({ platformName, startDate, endDate, platformId }, i) => {
												return (
													<Col span={24} key={platformName + i}>
														<b>{platformName}</b>:&nbsp;
														<span>{startDate}</span> &nbsp;-&nbsp;
														<span>{endDate}</span> &nbsp;
														<EditOutlined
															onClick={() => activeDatesModalOpenHandler(platformName, platformId)}
														/>
													</Col>
												);
										  })
										: "Choose Distribution Platform to display active dates"}
								</Row>
							</Col>
						</Row>
					</Form>
				</Spin>
			</VideoFormModal>
			{isActiveDatesModalOpen ? (
				<ActiveDates
					closeActiveDatesModal={closeActiveDateHandler}
					isOpen={isActiveDatesModalOpen}
					platformName={activeDatePlatforModalName}
					saveActiveDateHandler={saveActiveDateHandler}
				/>
			) : null}
		</>
	);
};

export default AddVideosMetaDataForm;
