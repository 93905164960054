import React, { FC, Fragment } from "react";
import { useTagsHooks } from "./hooks/useTagsHooks";
import { Button, Col, Form, Row } from "antd";
import styled from "@emotion/styled";
import { CloseOutlined, EnterOutlined } from "@ant-design/icons";

import { CustomTable } from "../series/StyledComponent";
import { ComponentTitle } from "../custom-components";
import { TabComponentProps } from "../utils/common.interface";
import useScreenSizeHook from "../utils/useScreenSizeHook";
import AddTagsForm from "./modals/AddTagsForm";

export const TagFormContainer = styled.div`
	display: flex;
	align-items: center;
	height: 100%;

	span {
		margin-left: 4px;
	}
	button {
		margin-left: 8px;
	}
`;

export const TagFormItem = styled(Form.Item)`
	margin-bottom: 0;
`;

export const ThemedEnterOutlined = styled(EnterOutlined)`
	color: rgb(71, 203, 56);
`;

export const CloseIcon = styled(CloseOutlined)`
	font-size: 16px;
	background: #fff;
	height: 30px;
	width: 30px;
	color: #000;
	display: flex;
	align-items: center;
	justify-content: center;
	border-radius: 50%;
`;

const TagsComponent: FC<TabComponentProps> = () => {
	const { height } = useScreenSizeHook();
	const {
		tagsSeries,
		tagsData,
		isAddTagsFormOpen,
		setIsTagsFormOpen,
		saveTagsData,
		isLoading,
		handleCancel,
		form,
		editHelper,
		editId,
		handleTableChange,
		pagination,
	} = useTagsHooks();
	return (
		<Fragment>
			<Row justify="space-between" align="middle">
				<Col>
					<ComponentTitle>Tags</ComponentTitle>
				</Col>
				<Col>
					<Button onClick={() => setIsTagsFormOpen(true)}>+ Add Tag</Button>
				</Col>
			</Row>
			<CustomTable
				onChange={handleTableChange}
				columns={tagsSeries}
				scroll={{ x: 500, y: height - 265 }}
				dataSource={tagsData}
				pagination={pagination}
				loading={isLoading}
				onRow={(record: any) => {
					return {
						onClick: () => {
							editHelper(record);
						},
					};
				}}
			/>
			<AddTagsForm
				form={form}
				isLoading={isLoading}
				isOpen={isAddTagsFormOpen}
				onCancel={handleCancel}
				submitHandler={saveTagsData}
				tagsEditId={editId}
			/>
		</Fragment>
	);
};

export default TagsComponent;
