import { useEffect, useState } from "react";
import { Form, message } from "antd";
import { PaginationProps } from "antd/lib/pagination";
import { ColumnsType } from "antd/es/table";
import { useOutletContext } from "react-router-dom";
import { getBulkSelectColumns } from "../../custom-components/BulkSelectColumn";

import {
	SETTINGS_DATA,
	exportCSVWithLambda,
	getCuePointsData,
	searchHandler,
	updateUserPageSizeSettings,
	handleSelectedRowStyling,
} from "../../utils";
import { initialPaginationObj } from "../../episode/hooks/useEpisodeHook";
import cuePointsColumn from "./cuePointColumns";

import { deleteCuePoint, editHelper, saveUpdateCuePoints } from "./utils";
import useDistributionData from "../../distribution_partner/hooks/useDistributionData";

export const initialCuepointFields: string[] = ["1", "2", "3", "4", "5", "6", "7"];

interface CuePointFilterObj {
	series_filter: string[];
	distribution_platforms_filter: string[];
	offset_filter: number;
	video_lists?: string[];
}

export type SelectedCuePointsData = { key: string; id: string };

export const useCuePointsHook = () => {
	const [form] = Form.useForm();
	const filterFieldsArr = [
		"offset_filter",
		"distribution_platforms_filter",
		"series_filter",
		"video_lists_filter",
	];
	const { user, setUser, distributionPlatformData, seriesList, videoLists }: any =
		useOutletContext();
	const {
		distributionCheckedList,
		setDistributionCheckedList,
		distributionChange,
		onDistributionCheckAllChange,
		checkAll,
		indeterminate,
	} = useDistributionData();
	const [selectedCuePointsData, setSelectedCuePointsData] = useState<SelectedCuePointsData[]>([]);
	const [isAddToDistributorsModalOpen, setIsAddToDistributorsModalOpen] = useState<boolean>(false);
	const [isLoading, setIsLoading] = useState<boolean>(false);
	const [cuePointFilterModalOpen, setCuePointFilterModalOpen] = useState<boolean>(false);
	const [isExportModalOpen, setIsExportModalOpen] = useState<boolean>(false);
	const [cuePointTimeFormat, setCuePointTimeFormat] = useState<string>("");
	const [cuepointFields, setCuepointFields] = useState<string[]>(initialCuepointFields);
	const [cuePointsData, setCuePointsData] = useState<any[]>([]);
	const [cuePointEditId, setCuePointEditId] = useState<string | undefined | null>();
	const [episodeId, setEpisodeId] = useState<string | null>();
	const [isAddCuePointFormOpen, setIsCuePointFormOpen] = useState<boolean>(false);
	const [isDuplicateCuePointFormOpen, setIsDuplicateCuePointFormOpen] = useState<boolean>(false);
	const [create5SecOffset, setCreate5SecOffset] = useState<boolean>(true);
	const [searchQuery, setSearchQuery] = useState<string>("");
	const [timeoutId, setTimeoutId] = useState<NodeJS.Timeout | undefined>();
	const [cuePointFilterObj, setCuePointFilterObj] = useState<CuePointFilterObj>();
	const [pagination, setPagination] = useState<PaginationProps>({
		...initialPaginationObj,
		pageSize: user.page_sizes?.cuepoints ?? 10,
	});

	useEffect(() => {
		setIsLoading(true);
		getCuePointsDataHandler();
		// set selectedCuePointsData from local storage
		const selectedCuePointsData = JSON.parse(
			sessionStorage.getItem("selectedCuePointsData") || "[]"
		);
		setSelectedCuePointsData(selectedCuePointsData);
	}, [pagination.pageSize, pagination.current, searchQuery, cuePointFilterObj]);

	const getCuePointsDataHandler = () => {
		getCuePointsData(pagination, setPagination, setCuePointsData, setIsLoading, {
			...cuePointFilterObj,
			searchQuery,
		});
	};

	useEffect(() => {
		const selectedCuePointsData = JSON.parse(
			sessionStorage.getItem("selectedCuePointsData") || "[]"
		);
		const selectedCuePointsKeys = selectedCuePointsData.map((item: any) => item.key);
		handleSelectedRowStyling(selectedCuePointsKeys);
	}, [cuePointsData]);

	const handleTableChange = (pagination: PaginationProps, _: any, sorter: any, { action }: any) => {
		switch (action) {
			case "paginate": {
				if (pagination.pageSize !== user.page_sizes.cuepoints) {
					const updatedSettingsData: SETTINGS_DATA = {
						user_id: user.user_id,
						page_sizes: { ...user.page_sizes, cuepoints: pagination.pageSize },
					};
					setUser({ ...user, ...updatedSettingsData });
					updateUserPageSizeSettings(updatedSettingsData);
				}
				setPagination(pagination);
				break;
			}
		}
	};

	const clearCuePointsSelection = () => {
		const selectedCuePointsKeys = selectedCuePointsData.map((item: any) => item.key);
		handleSelectedRowStyling(selectedCuePointsKeys, true);
		sessionStorage.removeItem("selectedCuePointsData");
		setSelectedCuePointsData([]);
	};

	const editHandler = (record: any, isCloneEditor?: boolean) => {
		editHelper(
			record,
			form,
			setCuePointEditId,
			isCloneEditor ? setIsDuplicateCuePointFormOpen : setIsCuePointFormOpen,
			setDistributionCheckedList,
			setCuepointFields,
			setEpisodeId
		);
	};

	const editCuePoint = async () => {
		try {
			setIsLoading(true);
			const values = await form.validateFields();
			await saveUpdateCuePoints(
				values,
				episodeId,
				distributionCheckedList,
				cuepointFields,
				create5SecOffset,
				cuePointEditId
			);
			getCuePointsDataHandler();
			handleCancel();
			setEpisodeId(null);
			setCuePointEditId(undefined);
		} catch (error) {
			console.error(error);
			setIsLoading(false);
		}
	};

	const deleteCuePointHandler = async (id: string) => {
		try {
			setIsLoading(true);
			const response = await deleteCuePoint(id);
			if (response.status === 200) {
				message.success(response.data.message);
				getCuePointsDataHandler();
			}
		} catch (error) {
			console.error(error);
			setIsLoading(false);
		}
	};

	const handleCancel = () => {
		try {
			form.resetFields();
			setIsCuePointFormOpen(false);
			setCuePointEditId(undefined);
			setIsDuplicateCuePointFormOpen(false);
			setDistributionCheckedList([]);
			setCreate5SecOffset(true);
			setCuepointFields(initialCuepointFields);
		} catch (error) {
			console.error(error);
		}
	};

	const closeCuePointsExportModal = () => {
		setIsExportModalOpen(false);
		setCuePointTimeFormat("");
	};

	const exportCuePointCSV = async () => {
		try {
			if (!cuePointTimeFormat) {
				return message.error("Please select a cue point format", 3);
			}
			const data = {
				...cuePointFilterObj, // sending empty distribution_platfoms array should return all the distribution related data
				include_distribution_platforms: true,
				cuepoints: true,
				cuePointFormat: cuePointTimeFormat,
			};
			await exportCSVWithLambda(data, "assets/cuepoints", user.name, "Cuepoints Export");
			setIsExportModalOpen(false);
			setCuePointTimeFormat("");
		} catch (err) {
			console.error(err);
		}
	};

	const searchData = async () => {
		try {
			setPagination({ ...initialPaginationObj, pageSize: pagination.pageSize });
			searchHandler(form, timeoutId, setTimeoutId, setSearchQuery);
		} catch (err) {
			console.error({ err });
		}
	};

	const getFilteredCuePoints = () => {
		const data = form.getFieldsValue(filterFieldsArr);
		setCuePointFilterModalOpen(false);
		setCuePointFilterObj(data);
	};
	const cuePointFilterModalCancelHandler = () => {
		setCuePointFilterModalOpen(false);
	};
	const clearCuePointFilter = () => {
		setCuePointFilterObj(undefined);
		form.resetFields(filterFieldsArr);
	};

	let columnCuePointsBulkSelect: ColumnsType<any> = getBulkSelectColumns(
		cuePointsData,
		selectedCuePointsData,
		setSelectedCuePointsData,
		handleSelectedRowStyling,
		"selectedCuePointsData"
	);
	columnCuePointsBulkSelect = columnCuePointsBulkSelect.concat(
		cuePointsColumn(editHandler, deleteCuePointHandler)
	);

	return {
		isLoading,
		columnCuePoints: columnCuePointsBulkSelect, //cuePointsColumn(editHandler, deleteCuePointHandler),
		cuePointsData,
		isAddCuePointFormOpen,
		handleTableChange,
		pagination,
		form,
		editCuePoint,
		handleCancel,
		cuePointEditId,
		editHelper: editHandler,
		distributionPlatformData,
		distributionCheckedList,
		setDistributionCheckedList,
		distributionChange,
		onDistributionCheckAllChange,
		checkAll,
		indeterminate,
		isDuplicateCuePointFormOpen,
		cuepointFields,
		setCuepointFields,
		create5SecOffset,
		setCreate5SecOffset,
		exportCuePointCSV,
		searchData,
		isExportModalOpen,
		setIsExportModalOpen,
		closeCuePointsExportModal,
		setCuePointTimeFormat,
		cuePointTimeFormat,
		cuePointFilterModalOpen,
		setCuePointFilterModalOpen,
		getFilteredCuePoints,
		cuePointFilterModalCancelHandler,
		seriesList,
		clearCuePointFilter,
		cuePointFilterObj,
		selectedCuePointsData,
		clearCuePointsSelection,
		setIsAddToDistributorsModalOpen,
		isAddToDistributorsModalOpen,
		getCuePointsDataHandler,
		videoLists,
	};
};
