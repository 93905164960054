import React, { FC } from "react";
import { Modal, Form, Input, FormInstance, Select } from "antd";
import { tagsFilterOptions, getDigitsOnly, seriesFilterOptions } from "../../utils";
import styled from "@emotion/styled";
import { TagOption } from "../../tags/hooks/utils";
import { CloseIcon } from "../../custom-components";

export const StyledFilterModal = styled(Modal)`
	& .ant-modal-header {
		margin-bottom: 20px;
	}
	& .ant-modal-content {
		max-height: 100vh;
	}
	& .ant-modal-body {
		overflow-y: auto;
		overflow-x: hidden;
		max-height: calc(100vh - 120px);
		padding: 0 8px;
	}
	& .ant-modal-close {
		display: none;
	}
	& .ant-select {
		margin: 0;
	}
`;

const { Item } = Form;

interface EpisodeFilterModal {
	tagsData: any[];
	distributionPlatform: any[];
	filterEpisodeModalOpen: boolean;
	seriesData: any[];
	form: FormInstance;
	getFileteredData: () => void;
	filterModalCancelHandler: () => void;
}

const EpisodeFilterModal: FC<EpisodeFilterModal> = React.memo(
	({
		tagsData,
		filterEpisodeModalOpen,
		seriesData,
		form,
		getFileteredData,
		filterModalCancelHandler,
	}) => {
		return (
			<StyledFilterModal
				open={filterEpisodeModalOpen}
				okText="Filter"
				cancelText="Close"
				title="Filter Episodes By"
				closeIcon={null}
				centered
				maskClosable={false}
				onOk={getFileteredData}
				onCancel={() => {
					filterModalCancelHandler();
				}}
			>
				<Form form={form} layout="vertical">
					<Item label="Series" name="series_filter">
						<Select
							filterOption={seriesFilterOptions}
							showSearch
							mode="multiple"
							placeholder="Series"
							allowClear={{ clearIcon: <CloseIcon /> }}
						>
							{seriesData?.map((series: any) => (
								<Select.Option value={series.id} key={series.id}>
									{series.titles}
								</Select.Option>
							))}
						</Select>
					</Item>
					<Item label="Has Tags" name="episode_tags_filter">
						<Select
							allowClear={{ clearIcon: <CloseIcon /> }}
							mode={"multiple"}
							placeholder={"Have episode tags"}
							filterOption={tagsFilterOptions}
						>
							{tagsData
								.filter((tag: TagOption) => tag.related_to === "video")
								.map((tag: TagOption) => (
									<Select.Option value={tag.id} key={tag.id}>
										{tag.title}
									</Select.Option>
								))}
						</Select>
					</Item>
					<Item label="Not Tagged" name="episode_not_tagged">
						<Select
							allowClear={{ clearIcon: <CloseIcon /> }}
							mode={"multiple"}
							placeholder={"Don't have episode tags"}
							filterOption={tagsFilterOptions}
						>
							{tagsData
								.filter((tag: TagOption) => tag.related_to === "video")
								.map((tag: TagOption) => (
									<Select.Option value={tag.id} key={tag.id}>
										{tag.title}
									</Select.Option>
								))}
						</Select>
					</Item>
					<Item label="Season" name="season_filter">
						<Input
							placeholder="Season"
							onChange={e => form.setFieldValue("season_filter", getDigitsOnly(e.target.value))}
						/>
					</Item>
				</Form>
			</StyledFilterModal>
		);
	}
);

EpisodeFilterModal.displayName = "Episode Filter Modal";

export default EpisodeFilterModal;
