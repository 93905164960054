import { message } from "antd";
import { authAxios } from "../../utils/session_utils";

export interface TagsFormValues {
	id?: string;
	related_to: string;
	title: string;
}

export interface TagsObjInterface {
	[key: string]: string;
}

export type TagOption = {
	id?: string;
	title: string;
	related_to: string;
};

export const saveTagsData = async (
	values: TagsFormValues,
	tagsObj: TagsObjInterface,
	editId?: string
) => {
	try {
		if (tagsObj[`${values.title.toLowerCase()}-${values.related_to}`]) {
			const errorText = "This tag aready exists for " + values.related_to;
			message.error(errorText, 3);
			throw new Error(errorText);
		}
		const data: any = {
			tagsData: values,
		};
		if (editId) {
			data.tagsData.id = editId;
		}
		const config = {
			method: editId ? "put" : "post",
			url: `${process.env.REACT_APP_BACK_END_API}/snowflake/tags`,
			headers: {
				"Content-Type": "application/json",
			},
			data: JSON.stringify(data),
		};

		const response = await authAxios(config);
		if (!response.data.err) {
			message.success(response.data.message);
		}
	} catch (error) {
		throw new Error(error as string);
	}
};
