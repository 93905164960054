import { Button, Col, Row, Spin, message } from "antd";
import { CheckboxChangeEvent } from "antd/es/checkbox";
import React, { Dispatch, SetStateAction, useState } from "react";
import { authAxios } from "../utils/session_utils";
import { BulkAddDataModal, VideoSearchItem } from ".";
import { Checkbox } from "antd/lib";
import { SearchOutlined } from "@ant-design/icons";

export type SelectedItemData = { key: string; id: string };

interface BulkItemsTaggingModalInterface {
	isBulkTaggingModalOpen: boolean;
	setIsBulkTaggingModalOpen: Dispatch<SetStateAction<boolean>>;
	tagsList: any[];
	selectedItemData: SelectedItemData[];
	itemType: string;
	getItemsDataHandler: () => void;
}

const BulkItemsTaggingModal: React.FC<BulkItemsTaggingModalInterface> = ({
	isBulkTaggingModalOpen,
	setIsBulkTaggingModalOpen,
	tagsList,
	selectedItemData,
	itemType,
	getItemsDataHandler,
}) => {
	const [isLoading, setIsLoading] = useState<boolean>(false);
	const [searchText, setSearchText] = useState<string>("");
	const [tagCheckedList, setTagCheckedList] = useState<string[]>([]);
	const tagListPlainOptions = tagsList.map(list => ({
		value: list.id,
		label: list.name,
	}));
	const tagListIds = tagsList.map(list => list.id);
	const checkAll = tagListPlainOptions.length === tagCheckedList.length;
	const indeterminate =
		tagCheckedList.length > 0 && tagCheckedList.length < tagListPlainOptions.length;

	const tagListChange = (list: string[]) => {
		setTagCheckedList(list);
	};

	const onTagCheckAllChange = (e: CheckboxChangeEvent) => {
		setTagCheckedList(e.target.checked ? tagListIds : []);
	};

	const tagBulkItems = async () => {
		try {
			setIsLoading(true);
			const data = {
				item_type: itemType,
				item_ids: selectedItemData.map(item => item.id),
				tag_ids: tagCheckedList,
			};
			const config = {
				method: "POST",
				url: `${process.env.REACT_APP_BACK_END_API}/snowflake/bulk/add-item-tags`,
				data: JSON.stringify(data),
				headers: { "Content-Type": "application/json" },
			};
			const response = await authAxios(config);
			message.success(response.data.message);
			await getItemsDataHandler();
			setIsLoading(false);
			closeHandler();
		} catch (error) {
			setIsLoading(false);
			message.error("Could not save item list");
			console.error("error addBulkTagsToItems", error);
		}
	};

	const untagBulkItems = async () => {
		try {
			setIsLoading(true);
			const data = {
				item_type: itemType,
				item_ids: selectedItemData.map(item => item.id),
				tag_ids: tagCheckedList,
			};
			const config = {
				method: "POST",
				url: `${process.env.REACT_APP_BACK_END_API}/snowflake/bulk/remove-item-tags`,
				data: JSON.stringify(data),
				headers: { "Content-Type": "application/json" },
			};
			const response = await authAxios(config);
			message.success(response.data.message);
			await getItemsDataHandler();
			setIsLoading(false);
			closeHandler();
		} catch (error) {
			setIsLoading(false);
			message.error("Selected tags could not be removed from selected items.");
			console.error("error removeBulkItemsFromTags", error);
		}
	};

	const closeHandler = () => {
		setIsBulkTaggingModalOpen(false);
		setTagCheckedList([]);
	};

	return (
		<BulkAddDataModal
			open={isBulkTaggingModalOpen}
			onCancel={closeHandler}
			title="Add or Remove Tag(s):"
			centered
			footer={
				<>
					<Button onClick={untagBulkItems} disabled={!tagCheckedList?.length || isLoading} danger>
						Remove
					</Button>
					<Button
						onClick={tagBulkItems}
						disabled={!tagCheckedList?.length || isLoading}
						type="primary"
					>
						Add
					</Button>
				</>
			}
		>
			<Spin spinning={isLoading}>
				<VideoSearchItem
					placeholder="Search Tags"
					onChange={e => setSearchText(e.target.value)}
					suffix={<SearchOutlined />}
				/>
				{!searchText && (
					<Checkbox indeterminate={indeterminate} onChange={onTagCheckAllChange} checked={checkAll}>
						{checkAll ? "De-Select All" : "Select All"}
					</Checkbox>
				)}
				<Checkbox.Group onChange={tagListChange} value={tagCheckedList}>
					<Row gutter={[0, 4]}>
						{tagsList
							.filter(tag =>
								searchText ? tag.title.toLowerCase().includes(searchText?.toLowerCase()) : true
							)
							.map((tag: any) => {
								return (
									<Col span={24} key={tag.key}>
										<Checkbox value={tag.id} key={tag.key}>
											<b>{tag.title}</b>
										</Checkbox>
									</Col>
								);
							})}
					</Row>
				</Checkbox.Group>
			</Spin>
		</BulkAddDataModal>
	);
};

BulkItemsTaggingModal.displayName = "TaggingModal";
export default BulkItemsTaggingModal;
