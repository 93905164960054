import React, { Fragment, useEffect, useState } from "react";
import { Form, Popconfirm, Popover, Table, message } from "antd";
import { ColumnsType } from "antd/lib/table/interface";
import { DeleteOutlined, EditOutlined } from "@ant-design/icons";
import { BsFillChatSquareTextFill } from "react-icons/bs";
import { PaginationProps } from "antd/lib/pagination";
import dayjs from "dayjs";

import { CustomBtn } from "../StyledComponent";
import { useLocation, useOutletContext } from "react-router-dom";

import {
	HomeTabKeyEnum,
	SETTINGS_DATA,
	exportCSVWithLambda,
	getSeriesData,
	updateUserPageSizeSettings,
} from "../../utils";
import { NotesButton, initialPaginationObj } from "../../episode/hooks/useEpisodeHook";
import { authAxios } from "../../utils/session_utils";
import { DescriptionText } from "../../custom-components";
import { lastModifiedCol } from "../../utils/table-columns";

export type SeriesDescription = {
	languageCode: string;
	description: string;
};

export type MultipleSeriesItems = {
	title: string[];
	description: string[];
	thumbnail: any[];
};

const languageObj: any = {
	en: "English",
	es: "Spanish",
	pt: "Portuguese",
};

const DescriptionTable: React.FC<{ jsonData: SeriesDescription[] }> = ({ jsonData }) => {
	const columns = [
		{
			title: "Language",
			key: "languageCode",
			dataIndex: "languageCode",
			render: (value: string) => {
				return languageObj[value];
			},
		},
		{
			title: "Description",
			key: "description",
			dataIndex: "description",
			width: "600px",
		},
	];
	return <Table columns={columns} dataSource={jsonData} pagination={false} />;
};

const initialSeriesMultipleItems: MultipleSeriesItems = {
	title: ["1"],
	description: ["1"],
	thumbnail: [],
};

export const useSeriesHooks = () => {
	const location = useLocation();
	const {
		seriesList,
		setseriesList,
		loading,
		setLoading,
		seriesSortObj,
		user,
		setUser,
		ratings,
		languagesList,
	}: any = useOutletContext();
	const [form] = Form.useForm();
	const [isAddSeriesFormOpen, setIsSeriesFormOpen] = useState<boolean>(false);
	const [editId, setEditId] = useState<string | null>(null);
	const [seriesMultipleItems, setMultipleSeriesItems] = useState<MultipleSeriesItems>(
		initialSeriesMultipleItems
	);
	const [pagination, setPagination] = useState<PaginationProps>({
		...initialPaginationObj,
		pageSize: user.page_sizes?.series ?? 10,
		total: seriesList.length,
	});

	const [localSeriesList, setLocalSeriesList] = useState<any[]>(seriesList);

	useEffect(() => {
		if (location.pathname === HomeTabKeyEnum.SERIES) {
			form.setFieldsValue({ "0_titleLanguage": "en", "0_descriptionLanguage": "en" });
		}
	}, []);

	useEffect(() => {
		setLocalSeriesList(seriesList);
	}, [seriesList]);

	const handleTableChange = (pagination: PaginationProps, _: any, sorter: any, { action }: any) => {
		if (action === "paginate") {
			if (pagination.pageSize !== user.page_sizes.series) {
				const updatedSettingsData: SETTINGS_DATA = {
					user_id: user.user_id,
					page_sizes: { ...user.page_sizes, series: pagination.pageSize },
				};
				updateUserPageSizeSettings(updatedSettingsData);
				setUser({ ...user, ...updatedSettingsData });
			}
			setPagination(pagination);
		}
	};

	const saveUpdateSeriesData = async () => {
		try {
			const tempTitleData: any = [];
			const data = await form.validateFields();
			setLoading(true);
			seriesMultipleItems.title.forEach((_: any, index: number) => {
				tempTitleData.push(`${data[`${index}_titleText`]}-${data[`${index}_titleLanguage`]}`);
			});
			const seriesDescription = seriesMultipleItems.description.map((_: any, index: number) => {
				return {
					languageCode: `${data[`${index}_descriptionLanguage`]}`,
					description: `${data[`${index}_descriptionText`]}`,
				};
			});

			const { original_air_date } = data;
			const updatedData: any = {
				seriesData: {
					...data,
					series_description: JSON.stringify(seriesDescription),
					thumbnails: seriesMultipleItems.thumbnail,
					titles: tempTitleData.join(", "),
					original_air_date: original_air_date ? dayjs(original_air_date).format("YYYY-MM-DD") : "",
				},
			};

			if (editId) {
				updatedData["seriesData"]["id"] = editId;
			}

			const config = {
				method: editId ? "put" : "post",
				url: `${process.env.REACT_APP_BACK_END_API}/snowflake/series`,
				headers: {
					"Content-Type": "application/json",
				},
				data: JSON.stringify(updatedData),
			};
			const response = await authAxios(config);
			if (response.data) {
				getSeriesData(setseriesList, setLoading, seriesSortObj);
				setIsSeriesFormOpen(false);
				message.success(response.data.message);
				form.resetFields();
				setEditId(null);
				setMultipleSeriesItems(initialSeriesMultipleItems);
			}
		} catch (err) {
			setLoading(false);
			//console.error("erro while saving data", err);
		}
	};

	const deleteSeriesData = (seriesId: string) => {
		try {
			setLoading(true);
			const config = {
				method: "delete",
				url: `${process.env.REACT_APP_BACK_END_API}/snowflake/series/${seriesId}`,
				headers: {},
			};

			authAxios(config)
				.then(() => {
					getSeriesData(setseriesList, setLoading, seriesSortObj);
					message.success("Series deleted Successfully");
				})
				.catch(error => {
					console.error(error);
				});
		} catch (err) {
			console.error("erro", err);
		}
	};

	const handleCancel = () => {
		setMultipleSeriesItems(initialSeriesMultipleItems);
		form.resetFields();
		form.setFieldsValue({ "0_titleLanguage": "en", "0_descriptionLanguage": "en" });
		setIsSeriesFormOpen(false);
		setEditId(null);
	};

	const editHelper = (record: any) => {
		const { id, titles, series_description, original_air_date, thumbs, updated_at, created_at } =
			record;
		setEditId(id);
		// set titles fields
		titles?.split(",").map((title: string, index: number) => {
			form.setFieldsValue({
				[`${index}_titleLanguage`]: title.split("-")[1],
				[`${index}_titleText`]: title.split("-")[0],
			});
			return true;
		});
		// set series description fields
		const seriesDescription: SeriesDescription[] = JSON.parse(series_description);
		seriesDescription?.map(({ description, languageCode }, index: number) => {
			form.setFieldsValue({
				[`${index}_descriptionLanguage`]: languageCode,
				[`${index}_descriptionText`]: description,
			});
			return true;
		});
		setMultipleSeriesItems({
			thumbnail: thumbs, //still need to work
			title: titles.split(","),
			description: seriesDescription.map(desc => desc.description),
		});
		form.setFieldsValue({
			...record,
			updated_at: updated_at ?? created_at,
			original_air_date: original_air_date?.length ? dayjs(original_air_date) : undefined,
		});
		setIsSeriesFormOpen(true);
	};
	const exportSeriesData = () => {
		exportCSVWithLambda({}, "series", user.name, "Series Export");
	};
	const columnSeries: ColumnsType<object> = [
		{
			title: "Title",
			dataIndex: "titles",
			key: "title",
			width: "150px",
		},
		lastModifiedCol("descend"),
		{
			title: "Series Code",
			dataIndex: "series_code",
			key: "series_code",
			width: "120px",
			align: "center",
		},
		{
			title: "Status",
			dataIndex: "status",
			key: "status",
			width: "100px",
			align: "center",
		},
		{
			title: "Sgenno Series",
			dataIndex: "sgenno_series",
			key: "sgenno_series",
			width: "140px",
			align: "center",
		},
		{
			title: "Gracenote Series TMS Id",
			dataIndex: "gracenote_series_tms_id",
			key: "gracenote_series_tms_id",
			width: "160px",
		},
		{
			title: "Content Provider",
			dataIndex: "content_provider",
			key: "content_provider",
			width: "160px",
		},
		{
			title: "Wurl Categories",
			dataIndex: "wurl_categories",
			key: "wurl_categories",
			width: "200px",
		},
		{
			title: "Rating US",
			dataIndex: "rating_us",
			key: "rating",
			width: "160px",
		},
		{
			title: "Rating AU",
			dataIndex: "rating_au",
			key: "rating",
			width: "160px",
		},
		{
			title: "Rating Canada",
			dataIndex: "rating_canada",
			key: "rating",
			width: "160px",
		},
		{
			title: "Original Air Date",
			dataIndex: "original_air_date",
			key: "original_air_date",
			width: "160px",
		},
		{
			title: "Genre",
			dataIndex: "genre",
			key: "genre",
			width: "120px",
		},
		{
			title: "Zype Genre",
			dataIndex: "zype_genre",
			key: "zype_genre",
			width: "120px",
		},
		{
			title: "IMDB Series ID",
			dataIndex: "imdb_series_id",
			key: "imdb_series_id",
			width: "160px",
		},
		{
			title: "Thumbs",
			dataIndex: "thumbs",
			key: "thumbs",
			width: "120px",
			render: value => value.length,
		},
		{
			title: "Description",
			dataIndex: "series_description",
			key: "series_description",
			align: "center",
			width: "110px",
			render: (value: string) => {
				const jsonData: SeriesDescription[] = JSON.parse(value);
				const descriptionData = jsonData
					.filter(data => data.description)
					.map((data, i) => ({ ...data, key: data.languageCode + i }));
				return (
					<Popover
						trigger={["click", "hover"]}
						title={
							descriptionData?.length ? (
								<DescriptionTable jsonData={descriptionData} />
							) : (
								"No data found"
							)
						}
					>
						<NotesButton onClick={e => e.stopPropagation()} disabled={!value}>
							<BsFillChatSquareTextFill />
						</NotesButton>
					</Popover>
				);
			},
		},
		{
			title: "Internal Notes",
			dataIndex: "internal_notes",
			key: "internal_notes",
			align: "center",
			width: "160px",
			render: (value: string) => <DescriptionText text={value} />,
		},

		{
			title: "Action",
			dataIndex: "action",
			key: "action",
			align: "right",
			width: "80px",
			render: (value, record: any) => {
				return (
					<Fragment>
						<CustomBtn
							icon={<EditOutlined />}
							type={"link"}
							onClick={e => {
								e.stopPropagation();
								editHelper(record);
							}}
						/>
						<CustomBtn
							onClick={e => e.stopPropagation()}
							icon={
								<Popconfirm
									okButtonProps={{ danger: true }}
									title="Delete Series?"
									description="Are you sure you want to delete this series?"
									cancelText="Cancel"
									okText="Delete Series"
									onConfirm={() => deleteSeriesData(record.id)}
								>
									<DeleteOutlined title="Delete" style={{ color: "#D10000" }} />
								</Popconfirm>
							}
							type={"link"}
						/>
					</Fragment>
				);
			},
		},
	];
	return {
		columnSeries,
		seriesData: localSeriesList,
		isLoading: loading,
		setIsLoading: setLoading,
		getSeriesData,
		saveSeriesData: saveUpdateSeriesData,
		isAddSeriesFormOpen,
		setIsSeriesFormOpen,
		form,
		deleteSeriesData,
		handleCancel,
		editId,
		editHelper,
		handleTableChange,
		pagination,
		ratings,
		exportSeriesData,
		languagesList,
		seriesMultipleItems,
		setMultipleSeriesItems,
	};
};
