import React from "react";
import { Button, Col, Row, Spin, Space, Dropdown, MenuProps } from "antd";
import { CloseCircleOutlined, DownOutlined } from "@ant-design/icons";

import useCaptionsHook from "./hooks/useCaptionsHook";
import {
	ComponentTitle,
	PageTitleLastCol,
	SearchForm,
	SelectedCountTag,
} from "../custom-components";

import { CustomTable } from "../series/StyledComponent";
import AddCaptionsForm from "./modals/AddCaptionsForm";
import useScreenSizeHook from "../utils/useScreenSizeHook";
import { ClearFilterButton } from "../metadata";
import CaptionsFilterModal from "./modals/CaptionsFilterModal";
import AddItemsToDistributionPlatformModal from "../custom-components/AddItemsToDistributionPlatformModal";

const Captions = () => {
	const { height } = useScreenSizeHook();
	const {
		captionsData,
		isLoading,
		isOpenCaptionsForm,
		captionColumns,
		form,
		captionDataHandler,
		captionEditId,
		cancelHandler,
		languages,
		handleTableChange,
		editHelper,
		pagination,

		distributionPlatformData,
		distributionCheckedList,
		distributionChange,
		onDistributionCheckAllChange,
		checkAll,
		indeterminate,
		exportCaptionsCSV,
		searchData,
		isCaptionssFilterModalOpen,
		captionsFilterObj,
		setIsCaptionsfilterModalOpen,
		clearFilter,
		getFilteredCaptions,
		seriesList,
		filterModalCancelHandler,
		selectedCaptionsData,
		clearCaptionsSelection,
		isAddToDistributorsModalOpen,
		setIsAddToDistributorsModalOpen,
		setDistributionCheckedList,
		getCaptionsDataHandler,
		videoLists,
	} = useCaptionsHook();

	const bulkActionItems: MenuProps["items"] = [
		{
			label: <span>Distribution</span>,
			key: "distribution-platform",
			onClick: () => setIsAddToDistributorsModalOpen(true),
		},
	];

	return (
		<Spin spinning={isLoading}>
			<Row justify="space-between" align="middle">
				<Col>
					<ComponentTitle>Captions</ComponentTitle>
				</Col>
				<Col>
					{selectedCaptionsData.length > 0 && (
						<Space>
							<SelectedCountTag color="green">
								<span>{selectedCaptionsData.length} Selected</span>
								<span title="Clear Selection" onClick={clearCaptionsSelection} id="close-icon">
									X
								</span>
							</SelectedCountTag>
							<Dropdown menu={{ items: bulkActionItems }} trigger={["click"]}>
								<Button>
									Actions <DownOutlined />
								</Button>
							</Dropdown>
						</Space>
					)}
				</Col>
				<PageTitleLastCol>
					<Button onClick={exportCaptionsCSV}>Export</Button>
					&nbsp;
					<Button onClick={() => setIsCaptionsfilterModalOpen(true)}>Filter</Button>
					{captionsFilterObj && (
						<ClearFilterButton
							onClick={clearFilter}
							title="Remove Filter"
							icon={<CloseCircleOutlined />}
						/>
					)}
					<SearchForm form={form} searchData={searchData} placeholder="Search by Ref ID" />
				</PageTitleLastCol>
			</Row>
			<CustomTable
				onChange={handleTableChange}
				columns={captionColumns}
				dataSource={captionsData}
				scroll={{ x: 500, y: height - 265 }}
				pagination={pagination}
				onRow={(record: any) => {
					return {
						onClick: () => {
							editHelper(record);
						},
					};
				}}
			/>
			<AddCaptionsForm
				isLoading={isLoading}
				cancelHandler={cancelHandler}
				editCaptionId={captionEditId}
				form={form}
				isCaptionsModalOpen={isOpenCaptionsForm}
				saveUpdateCaptionsData={captionDataHandler}
				languages={languages}
				distributionPlatformData={distributionPlatformData}
				distributionCheckedList={distributionCheckedList}
				distributionChange={distributionChange}
				onDistributionCheckAllChange={onDistributionCheckAllChange}
				checkAll={checkAll}
				indeterminate={indeterminate}
			/>
			<CaptionsFilterModal
				captionsFilterModalOpen={isCaptionssFilterModalOpen}
				form={form}
				seriesData={seriesList}
				getFilteredCaptions={getFilteredCaptions}
				languages={languages}
				distributionPlatformData={distributionPlatformData}
				captionsFilterModalCancelHandler={filterModalCancelHandler}
				videoLists={videoLists}
			/>
			<AddItemsToDistributionPlatformModal
				setDistributionCheckedList={setDistributionCheckedList}
				isAddToDistributorsModalOpen={isAddToDistributorsModalOpen}
				setIsAddToDistributorsModalOpen={setIsAddToDistributorsModalOpen}
				distributionPlatformData={distributionPlatformData}
				distributionCheckedList={distributionCheckedList}
				distributionChange={distributionChange}
				onDistributionCheckAllChange={onDistributionCheckAllChange}
				checkAll={checkAll}
				indeterminate={indeterminate}
				selectedItemData={selectedCaptionsData}
				selectedItemType="Caption"
				getItemsDataHandler={getCaptionsDataHandler}
			/>
		</Spin>
	);
};

export default Captions;
