import React, { FC } from "react";
import { Col, Form, Row, Select } from "antd";
import {
	distributionPlatformFilterOptions,
	seriesFilterOptions,
	tagsFilterOptions,
	videoListfilterOptions,
} from "../../../utils";
import { BorderedRow, CloseIcon } from "../..";
import { LanguageData } from "../../../videos/modals/AddVideosMetaDataForm";
import { TagOption } from "../../../tags/hooks/utils";

const { Item } = Form;

interface CommonItemProps {
	name?: string;
	label?: string;
	placeholder?: string;
	mode?: "multiple" | "tags";
}

interface VideoListFilterFormItemProp extends CommonItemProps {
	videoLists: { id: string; name: string }[];
}

export const VideoListSelectItem: FC<VideoListFilterFormItemProp> = ({
	videoLists,
	name,
	label,
	placeholder,
	mode,
}) => {
	return (
		<Item label={label ?? "Video Lists"} name={name ?? "video_lists"}>
			<Select
				filterOption={videoListfilterOptions}
				mode={mode}
				allowClear={{ clearIcon: <CloseIcon /> }}
				showSearch
				placeholder={placeholder ?? "Video Lists"}
			>
				{videoLists?.map((videoList: any) => (
					<Select.Option value={videoList.id} key={videoList.id}>
						{videoList.name}
					</Select.Option>
				))}
			</Select>
		</Item>
	);
};

interface SeriesFormItemProps extends CommonItemProps {
	seriesData: any[];
}

export const SeriesSelectItem: FC<SeriesFormItemProps> = ({
	seriesData,
	name,
	label,
	placeholder,
	mode,
}) => {
	return (
		<Item name={name ?? "series"} label={label ?? "Series"}>
			<Select
				filterOption={seriesFilterOptions}
				showSearch
				mode={mode}
				placeholder={placeholder ?? "Series"}
				allowClear={{ clearIcon: <CloseIcon /> }}
			>
				{seriesData
					.sort((a, b) => a.titles.localeCompare(b.titles))
					.map((series: any) => (
						<Select.Option value={series.id} key={series.id}>
							{series.titles}
						</Select.Option>
					))}
			</Select>
		</Item>
	);
};

export const EpisodeRelatedFiltersForAssets: FC<{ seriesData: any[]; videoLists: any[] }> = ({
	videoLists,
	seriesData,
}) => {
	return (
		<BorderedRow>
			<Col span={24}>
				<SeriesSelectItem name="series_filter" seriesData={seriesData} mode="multiple" />
			</Col>
			<Col span={24}>
				<VideoListSelectItem name="video_lists_filter" videoLists={videoLists} mode="multiple" />
			</Col>
			<Col span={24}></Col>
		</BorderedRow>
	);
};

interface DistributionPlatformFilterFormItemProps extends CommonItemProps {
	distributionPlatformData: any[];
}

export const DistributionPlatformsItem: FC<DistributionPlatformFilterFormItemProps> = ({
	distributionPlatformData,
	name,
	label,
	placeholder,
	mode,
}) => {
	return (
		<Item name={name ?? "distribution_platforms"} label={label ?? "Distribution Platforms"}>
			<Select
				mode={mode}
				allowClear={{ clearIcon: <CloseIcon /> }}
				placeholder={placeholder ?? "Distribution Platforms"}
				filterOption={distributionPlatformFilterOptions}
			>
				{distributionPlatformData?.map((platform: any) => (
					<Select.Option value={platform.id} key={platform.id}>
						{platform.name}
					</Select.Option>
				))}
			</Select>
		</Item>
	);
};

interface TagsFilterFormItemProps extends CommonItemProps {
	tagsData: any[];
	for_thumbnails?: boolean;
}

export const TagsFilterItem: FC<TagsFilterFormItemProps> = ({
	tagsData,
	name,
	label,
	placeholder,
	mode,
	for_thumbnails,
}) => {
	return (
		<Item name={name ?? "tags"} label={label ?? "Tags"}>
			<Select
				mode={mode}
				allowClear={{ clearIcon: <CloseIcon /> }}
				placeholder={placeholder ?? "Tags"}
				filterOption={tagsFilterOptions}
			>
				{tagsData
					.filter((tag: TagOption) =>
						for_thumbnails ? tag.related_to === "thumbnail" : tag.related_to !== "thumbnail"
					)
					.map((tag: TagOption) => (
						<Select.Option value={tag.id} key={tag.id}>
							{tag.title}
						</Select.Option>
					))}
			</Select>
		</Item>
	);
};

interface LanguageFilterFormItemProps extends CommonItemProps {
	languages: any[];
	for_thumbnails?: boolean;
}

export const LanguageSelectItem: FC<LanguageFilterFormItemProps> = ({
	languages,
	name,
	label,
	placeholder,
	mode,
}) => {
	return (
		<Item name={name ?? "languages"} label={label ?? "Languages"}>
			<Select
				allowClear={{ clearIcon: <CloseIcon /> }}
				mode={mode}
				placeholder={placeholder ?? "Language"}
			>
				{languages?.map((language: LanguageData) => (
					<Select.Option value={language.NAME} key={language.ID}>
						{language.NAME}
					</Select.Option>
				))}
			</Select>
		</Item>
	);
};

export const CaptionTypeFilterItem: FC<CommonItemProps> = ({ name, label, placeholder, mode }) => {
	return (
		<Item name={name ?? "caption_type_filter"} label={label ?? "Caption Type"}>
			<Select
				allowClear={{ clearIcon: <CloseIcon /> }}
				mode={mode}
				placeholder={placeholder ?? "Caption Type"}
			>
				<Select.Option value="scc">SCC</Select.Option>
				<Select.Option value="vtt">VTT</Select.Option>
				<Select.Option value="srt">SRT</Select.Option>
			</Select>
		</Item>
	);
};
