import React from "react";
import { useCuePointsHook } from "./hooks/useCuePoints";
import { Button, Col, Row, Space, Dropdown, MenuProps } from "antd";
import {
	ComponentTitle,
	PageTitleLastCol,
	SearchForm,
	SelectedCountTag,
} from "../custom-components";
import { CustomTable } from "../series/StyledComponent";
import useScreenSizeHook from "../utils/useScreenSizeHook";
import CuePointsFormModal from "./modals/CuePointsFormModal";
import CuePointExportModal from "./modals/CuePointExportModal";
import CuePointFilterModal from "./modals/CuePointFilterModal";
import { ClearFilterButton } from "../metadata";
import { CloseCircleOutlined, DownOutlined } from "@ant-design/icons";
import AddItemsToDistributionPlatformModal from "../custom-components/AddItemsToDistributionPlatformModal";

const CuePointssComponent = () => {
	const { height } = useScreenSizeHook();
	const {
		cuePointsData,
		pagination,
		handleTableChange,
		isLoading,
		columnCuePoints,
		isAddCuePointFormOpen,
		form,
		editCuePoint,
		handleCancel,
		cuePointEditId,
		editHelper,
		distributionPlatformData,
		distributionCheckedList,
		setDistributionCheckedList,
		distributionChange,
		onDistributionCheckAllChange,
		checkAll,
		indeterminate,
		isDuplicateCuePointFormOpen,
		cuepointFields,
		setCuepointFields,
		create5SecOffset,
		setCreate5SecOffset,
		exportCuePointCSV,
		searchData,
		isExportModalOpen,
		setIsExportModalOpen,
		closeCuePointsExportModal,
		setCuePointTimeFormat,
		cuePointTimeFormat,
		cuePointFilterModalOpen,
		setCuePointFilterModalOpen,
		getFilteredCuePoints,
		cuePointFilterModalCancelHandler,
		seriesList,
		clearCuePointFilter,
		cuePointFilterObj,
		selectedCuePointsData,
		clearCuePointsSelection,
		isAddToDistributorsModalOpen,
		setIsAddToDistributorsModalOpen,
		getCuePointsDataHandler,
		videoLists,
	} = useCuePointsHook();

	const bulkActionItems: MenuProps["items"] = [
		{
			label: <span>Distribution</span>,
			key: "distribution-platform",
			onClick: () => setIsAddToDistributorsModalOpen(true),
		},
	];

	return (
		<>
			<Row justify="space-between" align="middle">
				<Col>
					<ComponentTitle>Cue Points</ComponentTitle>
				</Col>
				<Col>
					{selectedCuePointsData.length > 0 && (
						<Space>
							<SelectedCountTag color="green">
								<span>{selectedCuePointsData.length} Selected</span>
								<span title="Clear Selection" onClick={clearCuePointsSelection} id="close-icon">
									X
								</span>
							</SelectedCountTag>
							<Dropdown menu={{ items: bulkActionItems }} trigger={["click"]}>
								<Button>
									Actions <DownOutlined />
								</Button>
							</Dropdown>
						</Space>
					)}
				</Col>
				<PageTitleLastCol>
					<Button onClick={() => setIsExportModalOpen(true)}>Export</Button>
					&nbsp;
					<Button onClick={() => setCuePointFilterModalOpen(true)}>Filter</Button>
					{cuePointFilterObj && (
						<ClearFilterButton
							onClick={clearCuePointFilter}
							title="Remove Filter"
							icon={<CloseCircleOutlined />}
						/>
					)}
					<SearchForm form={form} searchData={searchData} placeholder="Search by Ref ID" />
				</PageTitleLastCol>
			</Row>
			<CustomTable
				onChange={handleTableChange}
				columns={columnCuePoints}
				dataSource={cuePointsData}
				scroll={{ y: height - 265 }}
				loading={isLoading}
				pagination={pagination}
				onRow={(record: any) => {
					return {
						onClick: () => {
							editHelper(record);
						},
					};
				}}
			/>
			<CuePointsFormModal
				create5SecOffset={create5SecOffset}
				setCreate5SecOffset={setCreate5SecOffset}
				cuePointEditId={cuePointEditId}
				isOpen={isAddCuePointFormOpen}
				saveEditCuePointsData={editCuePoint}
				handleCancel={handleCancel}
				isLoading={isLoading}
				form={form}
				distributionPlatformData={distributionPlatformData}
				distributionCheckedList={distributionCheckedList}
				distributionChange={distributionChange}
				onDistributionCheckAllChange={onDistributionCheckAllChange}
				checkAll={checkAll}
				indeterminate={indeterminate}
				isDuplicateCuePointFormOpen={isDuplicateCuePointFormOpen}
				cuepointFields={cuepointFields}
				setCuepointFields={setCuepointFields}
			/>
			<CuePointExportModal
				closeCuePointsExportModal={closeCuePointsExportModal}
				isOpen={isExportModalOpen}
				exportCuePoints={exportCuePointCSV}
				setCuePointTimeFormat={setCuePointTimeFormat}
				cuePointTimeFormat={cuePointTimeFormat}
			/>
			<CuePointFilterModal
				cuePointFilterModalOpen={cuePointFilterModalOpen}
				form={form}
				seriesData={seriesList}
				getFilteredCuePoints={getFilteredCuePoints}
				distributionPlatformData={distributionPlatformData}
				cuePointFilterModalCancelHandler={cuePointFilterModalCancelHandler}
				videoLists={videoLists}
			/>
			<AddItemsToDistributionPlatformModal
				setDistributionCheckedList={setDistributionCheckedList}
				isAddToDistributorsModalOpen={isAddToDistributorsModalOpen}
				setIsAddToDistributorsModalOpen={setIsAddToDistributorsModalOpen}
				distributionPlatformData={distributionPlatformData}
				distributionCheckedList={distributionCheckedList}
				distributionChange={distributionChange}
				onDistributionCheckAllChange={onDistributionCheckAllChange}
				checkAll={checkAll}
				indeterminate={indeterminate}
				selectedItemData={selectedCuePointsData}
				selectedItemType="CuePoint"
				getItemsDataHandler={getCuePointsDataHandler}
			/>
		</>
	);
};

export default CuePointssComponent;
