import React, { FC } from "react";
import {
	Button,
	Col,
	DatePicker,
	Form,
	FormInstance,
	Input,
	Row,
	Select,
	Tabs,
	Tooltip,
	Typography,
} from "antd";
import styled from "@emotion/styled";

import { CustomTable } from "../series/StyledComponent";
import { tagsFilterOptions, getDigitsOnly, seriesFilterOptions } from "../utils";
import { Ratings } from "../../App";
import { EpisodeDataTabKeys, EpisodeRelatedDataTabs } from "./hooks/useEpisodeHook";
import useScreenSizeHook from "../utils/useScreenSizeHook";
import { TagOption } from "../tags/hooks/utils";
import { QuestionCircleOutlined } from "@ant-design/icons";
import { CloseIcon } from "../custom-components";
import { ModalTitleWithLastModifiedDate } from "../utils/StyledComponents";

export const ContainerRow = styled(Row)`
	background-color: #fff;
	padding: 24px 12px;
	min-height: calc(100vh - 141px);
`;

export const StyledForm = styled(Form)`
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: flex-start;
	height: calc(100vh - 164px);
	overflow-y: auto;
	& .ant-row {
		max-width: 400px;
	}
`;

export const FullWidthRow = styled(Row)`
	width: 100%;
`;

export const FullWidthFormItem = styled(Form.Item)`
	width: 100%;
`;

export const ButtonRow = styled(FullWidthRow)`
	width: 100%;
	margin-top: 16px;
	.ant-btn {
		margin: 0 4px;
		min-width: 100px;
	}
`;

export const FormCol = styled(Col)`
	max-height: calc(100vh - 114px);
	overflow: hidden auto;
`;

export const VideoTableCol = styled(Col)`
	border-left: 1px solid #f0f0f0;
	padding-left: 24px;
`;

export const EmptyDataContainer = styled.div`
	height: calc(100% - 16px);
	display: flex;
	justify-content: center;
	align-items: center;
	font-size: 24px;
	font-weight: 600;
`;

interface AddEpisodeForm {
	episodeEditId: undefined | string | null;
	form: FormInstance;
	seriesData: any[];
	tagsData: any[];
	cancelEpisodeFormHandler: () => void;
	saveUpdateDataHandler: () => void;
	episodeDataTabs: EpisodeRelatedDataTabs;
	episodeRelatedDataTabHandler: (value: EpisodeDataTabKeys) => void;
	ratings: Ratings | undefined;
}
const AddEpisodeForm: FC<AddEpisodeForm> = ({
	tagsData,
	cancelEpisodeFormHandler,
	saveUpdateDataHandler,
	seriesData,
	form,
	episodeEditId,
	ratings,
	episodeRelatedDataTabHandler,
	episodeDataTabs,
}) => {
	const { height } = useScreenSizeHook();
	const TabsChildren = () => {
		return (
			<CustomTable
				columns={episodeDataTabs.column}
				dataSource={episodeDataTabs["data"][episodeDataTabs.key]}
				pagination={false}
				scroll={{ y: height - 265 }}
				onRow={(record: any) => {
					return {
						onClick: () => {
							episodeDataTabs["editHelper"](record);
						},
					};
				}}
			/>
		);
	};
	return (
		<ContainerRow>
			<FormCol xs={24} lg={episodeEditId ? 8 : 24}>
				<StyledForm form={form} layout="vertical" requiredMark={false}>
					<FullWidthRow gutter={[8, 0]}>
						<Col span={24}>
							{episodeEditId ? (
								<ModalTitleWithLastModifiedDate
									title="Edit Episode"
									lastModifiedDate={form.getFieldValue("updated_at")}
								/>
							) : (
								<Typography.Title level={2}>Create Episode</Typography.Title>
							)}
						</Col>
					</FullWidthRow>
					<FullWidthRow gutter={[8, 0]}>
						<Col sm={24} md={12}>
							<Form.Item
								label={
									<Tooltip title="Episode Ref IDs are case in-sensitive." placement="top">
										Ref Id <QuestionCircleOutlined />
									</Tooltip>
								}
								name="ref_id"
								rules={[{ required: true, message: "Required Field" }]}
							>
								<Input placeholder="Ref ID" disabled={Boolean(episodeEditId)} />
							</Form.Item>
						</Col>
						<Col sm={24} md={12}>
							<Form.Item
								label="Series"
								name="series_id"
								rules={[{ required: true, message: "Required Field" }]}
							>
								<Select
									filterOption={seriesFilterOptions}
									showSearch
									allowClear={{ clearIcon: <CloseIcon /> }}
									placeholder="Series"
								>
									{seriesData?.map((series: any) => (
										<Select.Option value={series.id} key={series.id}>
											{series.titles}
										</Select.Option>
									))}
								</Select>
							</Form.Item>
						</Col>
					</FullWidthRow>
					{!episodeEditId ? (
						<>
							<FullWidthRow gutter={[8, 0]}>
								<Col span={24}>
									<Form.Item
										label={
											<Tooltip
												title="If you know the Gracenote ID of this new episode, fill it in to pull all the episode metadata from Gracenote for both the new episode and a new linked Video.  If you leave it blank, a new empty episode for the specified Series is created."
												placement="top"
											>
												Gracenote ID <QuestionCircleOutlined />
											</Tooltip>
										}
										name="episode_gracenote_id"
									>
										<Input placeholder="Gracenote Episode TMS ID (optional)" />
									</Form.Item>
								</Col>
							</FullWidthRow>
							<ButtonRow justify="end">
								<Button onClick={cancelEpisodeFormHandler}>Cancel</Button>
								<Button onClick={saveUpdateDataHandler} type="primary">
									{episodeEditId ? "Update" : "Save"}
								</Button>
							</ButtonRow>
						</>
					) : (
						<>
							<FullWidthRow gutter={[8, 0]}>
								<Col sm={24} md={12}>
									<Form.Item label="Status" name="status">
										<Input placeholder="Status" />
									</Form.Item>
								</Col>
								<Col sm={24} md={6}>
									<Form.Item label="Season" name="season">
										<Input
											placeholder="Season"
											onChange={e => form.setFieldValue("season", getDigitsOnly(e.target.value))}
										/>
									</Form.Item>
								</Col>
								<Col sm={24} md={6}>
									<Form.Item label="Episode" name="episode">
										<Input
											placeholder="Episode"
											onChange={e => form.setFieldValue("episode", getDigitsOnly(e.target.value))}
										/>
									</Form.Item>
								</Col>
							</FullWidthRow>
							<FullWidthRow gutter={[8, 0]}>
								<Col sm={24} md={12}>
									<Form.Item label="Sgenno Season" name="sgenno_season">
										<Input placeholder="Sgenno Season" />
									</Form.Item>
								</Col>
								<Col sm={24} md={12}>
									<Form.Item label="Sgenno Episode" name="sgenno_episode">
										<Input placeholder="Sgenno Episode" />
									</Form.Item>
								</Col>
							</FullWidthRow>
							<FullWidthRow gutter={[8, 0]}>
								<Col span={24}>
									<Form.Item label="NBC Ref ID" name="nbc_ref_id">
										<Input placeholder="NBC Ref ID" />
									</Form.Item>
								</Col>
							</FullWidthRow>
							<FullWidthRow gutter={[8, 0]}>
								<FullWidthFormItem name="episode_tags" label="Episode Tags">
									<Select
										allowClear={{ clearIcon: <CloseIcon /> }}
										placeholder="Episode Tags"
										mode="multiple"
										filterOption={tagsFilterOptions}
									>
										{tagsData
											.filter((tag: TagOption) => tag.related_to === "video")
											.map((tag: TagOption) => (
												<Select.Option value={tag.id} key={tag.id}>
													{tag.title}
												</Select.Option>
											))}
									</Select>
								</FullWidthFormItem>
							</FullWidthRow>
							<FullWidthRow gutter={[8, 0]}>
								<Col span={24}>
									<Form.Item label="Original Air Date" name="original_air_date">
										<DatePicker format={["MM/DD/YYYY"]} placeholder="MM/DD/YYYY" />
									</Form.Item>
								</Col>
								{/* <Col sm={24} md={12}>
                            <Form.Item label="Air Date Ep#" name="air_data_episode" rules={[{ required: true, message: "Required Field" }]}>
                                <Input placeholder='Air Date Episode' />
                            </Form.Item>
                        </Col> */}
							</FullWidthRow>
							<FullWidthRow gutter={[8, 0]}>
								<FullWidthFormItem name="internal_notes" label="Internal Notes">
									<Input.TextArea placeholder="Internal Notes for episode" />
								</FullWidthFormItem>
							</FullWidthRow>
							<FullWidthRow gutter={[8, 0]}>
								<Col span={24}>Ratings:</Col>
								<Col sm={24} md={8}>
									<Form.Item label="US" name="rating_us">
										<Select allowClear={{ clearIcon: <CloseIcon /> }}>
											{ratings?.us?.map((rating: string, i: number) => (
												<Select.Option value={rating} key={"us" + rating + i}>
													{rating}
												</Select.Option>
											))}
										</Select>
									</Form.Item>
								</Col>
								<Col sm={24} md={8}>
									<Form.Item label="AU" name="rating_au">
										<Select allowClear={{ clearIcon: <CloseIcon /> }}>
											{ratings?.au?.map((rating: string, i: number) => (
												<Select.Option value={rating} key={"au" + rating + i}>
													{rating}
												</Select.Option>
											))}
										</Select>
									</Form.Item>
								</Col>
								<Col sm={24} md={8}>
									<Form.Item label="Canada" name="rating_canada">
										<Select allowClear={{ clearIcon: <CloseIcon /> }}>
											{ratings?.canada?.map((rating: string, i: number) => (
												<Select.Option value={rating} key={"canada" + rating + i}>
													{rating}
												</Select.Option>
											))}
										</Select>
									</Form.Item>
								</Col>
							</FullWidthRow>
							<FullWidthRow gutter={[8, 0]}>
								<FullWidthFormItem name="rating_descriptors_us" label="Rating Descriptors US">
									<Input.TextArea placeholder="Rating Descriptors US" />
								</FullWidthFormItem>
							</FullWidthRow>
							<FullWidthRow gutter={[8, 0]}>
								<FullWidthFormItem name="directors" label="Directors">
									<Input.TextArea placeholder="Directors" />
								</FullWidthFormItem>
							</FullWidthRow>
							<FullWidthRow gutter={[8, 0]}>
								<FullWidthFormItem name="actors" label="Actors">
									<Input.TextArea placeholder="Actors" />
								</FullWidthFormItem>
							</FullWidthRow>
							<FullWidthRow gutter={[8, 0]}>
								<FullWidthFormItem name="writers" label="Writers">
									<Input.TextArea placeholder="Writers" />
								</FullWidthFormItem>
							</FullWidthRow>
							<FullWidthRow gutter={[8, 0]}>
								<FullWidthFormItem name="producers" label="Producers">
									<Input.TextArea placeholder="Producers" />
								</FullWidthFormItem>
							</FullWidthRow>
						</>
					)}
				</StyledForm>
				{episodeEditId && (
					<ButtonRow justify="end">
						<Button onClick={cancelEpisodeFormHandler}>Cancel</Button>
						<Button onClick={saveUpdateDataHandler} type="primary">
							{episodeEditId ? "Update" : "Save"}
						</Button>
					</ButtonRow>
				)}
			</FormCol>
			{episodeEditId && (
				<VideoTableCol xs={24} lg={16}>
					<Tabs
						onChange={value => episodeRelatedDataTabHandler(value as unknown as EpisodeDataTabKeys)}
						items={[
							{ label: "Video", key: "videos", children: <TabsChildren /> },
							{ label: "Captions", key: "captions", children: <TabsChildren /> },
							{ label: "Cue Points", key: "cuepoints", children: <TabsChildren /> },
							{ label: "Thumbnails", key: "thumbnails", children: <TabsChildren /> },
						]}
						tabBarExtraContent={
							<Button type="primary" onClick={episodeDataTabs.addHelper}>
								{episodeDataTabs.addButtonText}
							</Button>
						}
					/>
				</VideoTableCol>
			)}
		</ContainerRow>
	);
};

export default AddEpisodeForm;
