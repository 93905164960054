import { Card, Checkbox, Col, Form, Input, Row, Select, Spin } from "antd";
import React, { FC, useState } from "react";
import { TaskRow } from "../../zype/styled-components";
import useExportHook from "./hooks/useVideoExportHook";
import { LanguageData } from "../../videos/modals/AddVideosMetaDataForm";
import styled from "@emotion/styled";
import { CloseIcon, ComponentTitle, ExportButton } from "../../custom-components";
import { TabComponentProps } from "../../utils/common.interface";
import {
	distributionPlatformFilterOptions,
	seriesFilterOptions,
	tagsFilterOptions,
} from "../../utils";

export type VideoTypesOptionType = {
	label: "MP4" | "MOV";
	value: "mp4" | "mov";
};

export const videoTypesOptionList: VideoTypesOptionType[] = [
	{
		label: "MP4",
		value: "mp4",
	},
	{
		label: "MOV",
		value: "mov",
	},
];

export const ExportCard = styled(Card)`
	& .ant-card-bordered {
		border-width: 0 !important;
	}
	& .ant-card-body {
		min-height: 250px;
		width: 400px;
		max-height: calc(100vh - 185px);
		overflow-x: hidden;
		overflow-y: auto;
	}
`;

const VideoExportComponent: FC<TabComponentProps> = () => {
	const {
		languagesList,
		seriesList,
		tagsList,
		loading,
		form,
		exportCSV,
		distributionPlatformData,
	} = useExportHook();
	const [checked, setChecked] = useState<boolean>(false);
	return (
		<Spin spinning={loading}>
			<Row justify="center">
				<Col>
					<ComponentTitle>Video Export</ComponentTitle>
				</Col>
			</Row>
			<TaskRow>
				<ExportCard
					actions={[<ExportButton type="primary" onClick={exportCSV} key="export-btn" />]}
				>
					<Form form={form} requiredMark={false} layout="vertical">
						<Form.Item label="Series" name="series">
							<Select
								filterOption={seriesFilterOptions}
								mode="multiple"
								allowClear={{ clearIcon: <CloseIcon /> }}
								showSearch
								placeholder="Series"
							>
								{seriesList?.map((series: any) => (
									<Select.Option value={series.id} key={series.id}>
										{series.titles}
									</Select.Option>
								))}
							</Select>
						</Form.Item>
						<Form.Item label="Audience Language" name="audience_language">
							<Select
								mode="multiple"
								allowClear={{ clearIcon: <CloseIcon /> }}
								placeholder="Audience Language"
							>
								{languagesList?.map((language: LanguageData) => (
									<Select.Option value={language.NAME} key={language.ID}>
										{language.NAME}
									</Select.Option>
								))}
							</Select>
						</Form.Item>
						<Form.Item name="type_filter" label="Video Type">
							<Input placeholder="Video Type (Works case in-sensitive.) e.g. mp4" />
							{/* <Select mode="multiple" allowClear={{ clearIcon: <CloseIcon /> }} placeholder="Video Type (Works case in-sensitive)">
								{videoTypesOptionList?.map((language: VideoTypesOptionType) => (
									<Select.Option value={language.value} key={language.value}>
										{language.label}
									</Select.Option>
								))}
							</Select> */}
						</Form.Item>
						<Form.Item label="Video Tags" name="video_tags">
							<Select
								mode="multiple"
								allowClear={{ clearIcon: <CloseIcon /> }}
								placeholder="Videos that have tags"
								filterOption={tagsFilterOptions}
							>
								{tagsList?.map((tagsList: any) => (
									<Select.Option value={tagsList.id} key={tagsList.id}>
										{tagsList.title}
									</Select.Option>
								))}
							</Select>
						</Form.Item>
						<Form.Item label="Episode Tags" name="episode_tags">
							<Select
								mode="multiple"
								allowClear={{ clearIcon: <CloseIcon /> }}
								placeholder="Episodes that have tags"
								filterOption={tagsFilterOptions}
							>
								{tagsList?.map((tagsList: any) => (
									<Select.Option value={tagsList.id} key={tagsList.id}>
										{tagsList.title}
									</Select.Option>
								))}
							</Select>
						</Form.Item>
						<Form.Item label="Not Tagged(Video)" name="video_not_tagged">
							<Select
								mode="multiple"
								allowClear={{ clearIcon: <CloseIcon /> }}
								placeholder="Videos that don't have tags"
								filterOption={tagsFilterOptions}
							>
								{tagsList?.map((tagsList: any) => (
									<Select.Option value={tagsList.id} key={tagsList.id}>
										{tagsList.title}
									</Select.Option>
								))}
							</Select>
						</Form.Item>
						<Form.Item label="Not Tagged(Episode)" name="episode_not_tagged">
							<Select
								mode="multiple"
								allowClear={{ clearIcon: <CloseIcon /> }}
								placeholder="Episodes that don't have tags"
								filterOption={tagsFilterOptions}
							>
								{tagsList?.map((tagsList: any) => (
									<Select.Option value={tagsList.id} key={tagsList.id}>
										{tagsList.title}
									</Select.Option>
								))}
							</Select>
						</Form.Item>
						<Form.Item label="Distribution Platforms" name="distribution_platforms">
							<Select
								mode="multiple"
								allowClear={{ clearIcon: <CloseIcon /> }}
								placeholder="Distribution Platforms"
								filterOption={distributionPlatformFilterOptions}
								onChange={(value: string) => {
									setChecked(Boolean(value.length));
								}}
							>
								{distributionPlatformData?.map((platform: any) => (
									<Select.Option value={platform.id} key={platform.id}>
										{platform.name}
									</Select.Option>
								))}
							</Select>
						</Form.Item>
						<Form.Item name="include_distribution_platforms">
							<Checkbox.Group>
								<Checkbox
									checked={checked}
									key="include_distribution_platforms"
									value="include_distribution_platforms"
								>
									Include Distribution Platforms
								</Checkbox>
							</Checkbox.Group>
						</Form.Item>
					</Form>
				</ExportCard>
			</TaskRow>
		</Spin>
	);
};

export default VideoExportComponent;
