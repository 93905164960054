import { Checkbox, Col, Form, Input, Row, Select, Spin, Typography } from "antd";
import React from "react";
import styled from "@emotion/styled";

import {
	BlueFilterInfoTitle,
	CloseIcon,
	ComponentTitle,
	ExportButton,
} from "../../custom-components";
import { TaskRow } from "../../zype/styled-components";
import { ExportCard } from "../video";
import useAssetsExportHook from "./hooks/useAssetsExportHook";
import { distributionPlatformFilterOptions, getDigitsOnly, seriesFilterOptions } from "../../utils";
import { LanguageData } from "../../videos/modals/AddVideosMetaDataForm";
import { VideoListSelectItem } from "../../custom-components/form/fliter";

const AssetTypesChecbox = styled(Checkbox.Group)`
	margin: 8px 0 16px 0;
`;

const options = [
	{
		label: "Captions",
		value: "captions",
	},
	{
		label: "Cue Points",
		value: "cue-points",
	},
	{
		label: "Thumbnails",
		value: "thumbnails",
	},
];

const { Item } = Form;

const AssetsExportComponent = () => {
	const {
		form,
		exportAssetsCSV,
		loading,
		distributionPlatformData,
		isLoading,
		seriesList,
		languages,
		videoLists,
		assetTypes,
		setAssetTypes,
	} = useAssetsExportHook();

	return (
		<Spin spinning={loading}>
			<Row justify="center">
				<Col>
					<ComponentTitle>Assets Export</ComponentTitle>
				</Col>
			</Row>
			<TaskRow>
				<ExportCard
					actions={[
						<ExportButton
							loading={isLoading}
							onClick={exportAssetsCSV}
							type="primary"
							key="assets-export-btn"
						/>,
					]}
				>
					<BlueFilterInfoTitle title="Export all assets for Episodes that contain videos matching the following filters:" />
					<Form form={form} requiredMark={false} layout="vertical">
						<Item label="Distribution Platforms" name="distribution_platforms">
							<Select
								mode="multiple"
								allowClear={{ clearIcon: <CloseIcon /> }}
								placeholder="Distribution Platforms"
								filterOption={distributionPlatformFilterOptions}
							>
								{distributionPlatformData?.map((platform: any) => (
									<Select.Option value={platform.id} key={platform.id}>
										{platform.name}
									</Select.Option>
								))}
							</Select>
						</Item>
						<Item label="Series" name="series">
							<Select
								filterOption={seriesFilterOptions}
								mode="multiple"
								allowClear={{ clearIcon: <CloseIcon /> }}
								showSearch
								placeholder="Series"
							>
								{seriesList?.map((series: any) => (
									<Select.Option value={series.id} key={series.id}>
										{series.titles}
									</Select.Option>
								))}
							</Select>
						</Item>
						<VideoListSelectItem
							name="video_lists_filter"
							videoLists={videoLists}
							mode="multiple"
						/>
						<BlueFilterInfoTitle title="Filter the Assets as follows:" />
						Asset Types
						<AssetTypesChecbox
							options={options}
							value={assetTypes}
							onChange={checkedValues => setAssetTypes(checkedValues)}
						/>
						<Item name="offset_time" label="Offset Time (Captions and Cue Points)">
							<Input
								disabled={!assetTypes.includes("captions") && !assetTypes.includes("cue-points")}
								placeholder="Offset Time(in sec)"
								onChange={e => form.setFieldValue("offset_time", getDigitsOnly(e.target.value))}
							/>
						</Item>
						<Item name="caption_type" label="Caption Type (Captions only)">
							<Select
								disabled={!assetTypes.includes("captions")}
								placeholder="Caption Type"
								allowClear={{ clearIcon: <CloseIcon /> }}
								mode="multiple"
							>
								<Select.Option value="scc">SCC</Select.Option>
								<Select.Option value="vtt">VTT</Select.Option>
								<Select.Option value="srt">SRT</Select.Option>
							</Select>
						</Item>
						<Item name="languages_filter" label="Caption Languages (Captions only)">
							<Select
								disabled={!assetTypes.includes("captions")}
								allowClear={{ clearIcon: <CloseIcon /> }}
								mode="multiple"
								placeholder="Caption Languages"
							>
								{languages?.map((language: LanguageData) => (
									<Select.Option value={language.NAME} key={language.ID}>
										{language.NAME}
									</Select.Option>
								))}
							</Select>
						</Item>
					</Form>
				</ExportCard>
			</TaskRow>
		</Spin>
	);
};

export default AssetsExportComponent;
