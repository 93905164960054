import { Input, Modal, Typography, message } from "antd";
import React, { Dispatch, SetStateAction, useState } from "react";
import { authAxios } from "../../../utils/session_utils";
import { SelectedVideoData } from "../../videos";

interface BulkDeleteModalInterface {
	selectedVideoData: SelectedVideoData[];
	isBulkDeleteModalOpen: boolean;
	setIsBulkDeleteModalOpen: Dispatch<SetStateAction<boolean>>;
	getVideosDataHandler: () => void;
	clearVideosSelection: () => void;
}

const BulkDeleteModal: React.FC<BulkDeleteModalInterface> = ({
	selectedVideoData,
	isBulkDeleteModalOpen,
	setIsBulkDeleteModalOpen,
	getVideosDataHandler,
	clearVideosSelection,
}) => {
	const [fieldText, setFieldText] = useState<string>();
	const [isLoading, setIsLoading] = useState<boolean>(false);

	const deleteAllowedCondtion = fieldText === "DELETE";

	const cancelHandler = () => {
		setFieldText(undefined);
		setIsBulkDeleteModalOpen(false);
	};

	const bulkDeleteAction = async () => {
		try {
			if (!deleteAllowedCondtion) return;
			setIsLoading(true);
			const data = {
				video_ids: selectedVideoData.map(videoData => videoData.id),
			};
			const config = {
				method: "POST",
				url: `${process.env.REACT_APP_BACK_END_API}/snowflake/bulk/delete-videos`,
				data: JSON.stringify(data),
				headers: { "Content-Type": "application/json" },
			};
			const response = await authAxios(config);
			message.success(response.data.message);
			cancelHandler();
			clearVideosSelection();
			await getVideosDataHandler();
			setIsLoading(false);
		} catch (error) {
			setIsLoading(false);
			console.error(error);
		}
	};
	return (
		<Modal
			open={isBulkDeleteModalOpen}
			title={null}
			closeIcon={null}
			cancelText="Cancel"
			okText={`Delete ${selectedVideoData.length} Video${selectedVideoData.length > 1 ? "s" : ""}`}
			okButtonProps={{
				danger: true,
				disabled: !deleteAllowedCondtion || isLoading,
				loading: isLoading,
			}}
			cancelButtonProps={{ disabled: isLoading }}
			onCancel={cancelHandler}
			onOk={bulkDeleteAction}
		>
			<Typography.Text>{`Are you sure you want to delete the ${
				selectedVideoData.length
			} video record${
				selectedVideoData.length > 1 ? "s" : ""
			}.  This can’t be undone.  Type “DELETE” in the box below and select the “Delete” button to permanently delete the records.`}</Typography.Text>
			<Input
				onChange={e => setFieldText(e.target.value)}
				placeholder={`Type "DELETE" to delete`}
				value={fieldText}
			/>
		</Modal>
	);
};

export default BulkDeleteModal;
