import { useEffect, useState } from "react";
import { Button, Form, TablePaginationConfig, message, notification } from "antd";
import { PaginationProps } from "antd/lib/pagination";
import { ColumnsType } from "antd/lib/table/interface";
import { v4 as uuidV4 } from "uuid";
import { useLocation, useNavigate, useOutletContext, useParams } from "react-router-dom";
import { getBulkSelectColumns } from "../../custom-components/BulkSelectColumn";

import {
	HomeTabKeyEnum,
	SETTINGS_DATA,
	exportCSVWithLambda,
	getEpisodeByRefId,
	getEpisodeData,
	getSignedURL,
	makeKeyInLowerCaseHelper,
	metadataSelectHandler,
	resetVideoFormFields,
	saveUpdateVideoData,
	searchHandler,
	updateHiddenEpisodesHandler,
	updateUserPageSizeSettings,
	videoEditHelper,
	handleSelectedRowStyling,
} from "../../utils";
import { ConvertedDataType } from "../../utils/common.interface";
import dayjs from "dayjs";
import { ActiveDatesObj, MetadataType, SortObj } from "../../videos/videos";
import styled from "@emotion/styled";
import { authAxios } from "../../utils/session_utils";
import cuePointsColumn from "../../cue-points/hooks/cuePointColumns";
import {
	getCaptionDataColumns,
	getColumnEpisodes,
	getEpisodeVideColumns,
} from "../../cue-points/hooks/columns";
import getThumbnailColumns from "../../thumbnails/hooks/columns";
import {
	deleteCaption,
	editCaptionHelper,
	resetCaptionForm,
	saveUpdateCaptionsData,
} from "../../captions/hooks/utils";
import { deleteCuePoint, editHelper, saveUpdateCuePoints } from "../../cue-points/hooks/utils";
import {
	deleteThumbnail,
	editThumbnailHelper,
	saveUpdateThumbnails,
} from "../../thumbnails/hooks/utils";
import useDistributionData from "../../distribution_partner/hooks/useDistributionData";
import { initialCuepointFields } from "../../cue-points/hooks/useCuePoints";
import { TagOption, saveTagsData } from "../../tags/hooks/utils";
import { orderObj } from "../../videos/hooks/useVideosHooks";

export const NotesButton = styled(Button)`
	display: flex !important;
	align-items: center;
	justify-content: center;
	width: 100%;
	background-color: transparent !important;
	box-shadow: 0 0 0;
	border-width: 0 !important;
`;
export interface EpisodeFilterObj {
	series?: string;
	season?: string;
	episode_tags?: string[];
	not_tagged?: string[];
	showHiddenEpisodes: boolean;
}

export type EpisodeDataTabKeys = "videos" | "cuepoints" | "thumbnails" | "captions";
export interface EpisodeRelatedDataTabs {
	key: EpisodeDataTabKeys;
	addButtonText: string;
	addHelper: () => void;
	editHelper: (param?: any) => void;
	addHandler: (param?: any) => void;
	data: {
		videos: any[];
		cuepoints: any[];
		captions: any[];
		thumbnails: any[];
	};
	column: ColumnsType<object>;
}

const initialEpisodeRelatedData: EpisodeRelatedDataTabs = {
	key: "videos",
	addButtonText: "",
	addHelper: () => console.info("Dummy helper"),
	editHelper: () => console.info("Dummy helper"),
	addHandler: () => console.info("Dummy handler"),
	column: [],
	data: {
		videos: [],
		cuepoints: [],
		thumbnails: [],
		captions: [],
	},
};

export const initialPaginationObj: TablePaginationConfig = {
	current: 1,
	pageSize: 10,
	position: ["bottomRight"],
	hideOnSinglePage: false,
	showSizeChanger: true,
};

export const episodeFormFields = [
	"ref_id",
	"episode_tags",
	"status",
	"series_id",
	"season",
	"episode",
	"sgenno_season",
	"sgenno_episode",
	"original_air_date",
	"rating_us",
	"rating_au",
	"rating_canada",
	"rating_descriptors_us",
	"directors",
	"actors",
	"writers",
	"producers",
	"internal_notes",
	"episode_gracenote_id",
	"updated_at",
];
export type SelectedEpisodeData = { key: string; id: string };

export const useEpisodeHook = () => {
	const location = useLocation();
	const [form] = Form.useForm();
	const param = useParams();
	const navigate = useNavigate();
	const {
		languagesList,
		tagsList,
		setTagsList,
		seriesList,
		loading,
		setLoading,
		distributionPlatformData,
		user,
		setUser,
		ratings,
		videoLists,
	}: any = useOutletContext();

	const {
		distributionCheckedList,
		setDistributionCheckedList,
		distributionChange,
		onDistributionCheckAllChange,
		checkAll,
		indeterminate,
	} = useDistributionData();

	const [selectedEpisodeData, setSelectedEpisodeData] = useState<SelectedEpisodeData[]>([]);
	const [isChangeStatusModalOpen, setIsChangeStatusModalOpen] = useState<boolean>(false);
	const [timeoutId, setTimeoutId] = useState<NodeJS.Timeout | undefined>(undefined);
	const [tagsObj, setTagsObj] = useState<any>();
	// seris states
	const [episodeData, setEpisodeData] = useState<ConvertedDataType[]>([]);
	const [isAddEpisdoeFormOpen, setIsEpisodeFormOpen] = useState<boolean>(false);
	const [episodeEditId, setEpisodeEditId] = useState<string | null>(null);
	const [filterEpisodeModalOpen, setFilterEpisodeModalOpen] = useState<boolean>(false);
	const [episodeSortObj, setEpisodeSortObj] = useState<SortObj>({
		column: "ref_id",
		order: "asc",
	});
	const [episodeFilterObj, setEpisodeFilterObj] = useState<EpisodeFilterObj>({
		showHiddenEpisodes: user.show_hidden_episodes,
	});
	const [pagination, setPagination] = useState<PaginationProps>({
		...initialPaginationObj,
		pageSize: user.page_sizes?.episodes ?? 10,
	});

	// episode states
	const [isAddVideoFormOpen, setIsVideoFormOpen] = useState<boolean>(false);
	const [videoEditId, setVideoEditId] = useState<string | null>(null);
	const [isAddNewMetadata, setIsAddNewMetadata] = useState<boolean>(false);
	const [durationValue, setDurationValue] = useState<string>("");
	const [formArray, setFormArray] = useState(["1"]);
	const [relatedMetadata, setRelatedMetadata] = useState<MetadataType[]>([]);
	const [ref_id, setRefId] = useState<string | undefined>();
	const [metadataEditId, setMetadataEditId] = useState<string | undefined>();
	const [currentTagsData, setCurrentTagsData] = useState<string[]>([]);
	const [isFilteredData, setIsFilteredData] = useState<boolean>(false);
	const [isExportModalOpen, setIsExportModalOpen] = useState<boolean>(false);
	const [currentEpisodeTagsData, setCurrentEpisodeTagsData] = useState<string[]>([]);
	const [searchQuery, setSearchQuery] = useState<string>("");
	const [videoPlayerDetails, setVideoPlayerDetails] = useState<{
		url: string;
		signedURL: string;
		fileName: string;
	}>();

	// caption states
	const [isOpenCaptionsForm, setIsOpenCaptionsForm] = useState<boolean>(false);
	const [captionEditId, setCaptionEditId] = useState<string | undefined>();

	// thumbnail states
	const [thumbnailEditId, setThumbnailEditId] = useState<string | null>();
	const [isAddThumbnailFormOpen, setIsThumbnailFormOpen] = useState<boolean>(false);

	// cuepoints states
	const [isDuplicateCuePointFormOpen, setIsDuplicateCuePointFormOpen] = useState<boolean>(false);
	const [cuePointEditId, setCuePointEditId] = useState<string | undefined | null>();
	const [isAddCuePointFormOpen, setIsCuePointFormOpen] = useState<boolean>(false);
	const [cuepointFields, setCuepointFields] = useState<string[]>(initialCuepointFields);
	const [create5SecOffset, setCreate5SecOffset] = useState<boolean>(true);
	const [exportFormat, setExportFormat] = useState<string>("videos");
	const [activeDatesList, setActiveDatesList] = useState<ActiveDatesObj[]>([]);

	useEffect(() => {
		if (location.pathname.includes(HomeTabKeyEnum.EPISODE)) {
			if (param.ref_id) {
				const refId = param.ref_id;
				paramDataHandler(refId);
			} else {
				const tempFilter = sessionStorage.getItem("episodeFilter");
				const tempFilterObj = tempFilter ? JSON.parse(tempFilter) : {};
				form.setFieldsValue({
					season_filter: tempFilterObj.season,
					series_filter: tempFilterObj.series,
					episode_tags_filter: tempFilterObj.episode_tags,
					not_tagged: tempFilterObj.not_tagged,
					showHiddenEpisodes: tempFilterObj.shoHiddenEpisodes,
				});
				sessionStorage.removeItem("episodeFilter");
				setIsEpisodeFormOpen(false);
				setIsVideoFormOpen(false);
				setIsCuePointFormOpen(false);
				setIsThumbnailFormOpen(false);
				setIsOpenCaptionsForm(false);
				setIsAddNewMetadata(false);
			}
		}
	}, [location.pathname]);

	useEffect(() => {
		if (!param.ref_id) {
			setLoading(true);
			fetchEpisodeDataHandler();
		}
	}, [pagination.current, pagination.pageSize, episodeSortObj, episodeFilterObj, searchQuery]);

	const fetchEpisodeDataHandler = async () => {
		setLoading(true);
		getEpisodeData(
			setEpisodeData,
			setLoading,
			pagination,
			setPagination,
			episodeSortObj,
			searchQuery,
			episodeFilterObj
		);
	};

	useEffect(() => {
		const selectedEpisodeData = JSON.parse(sessionStorage.getItem("selectedEpisodeData") || "[]");
		setSelectedEpisodeData(selectedEpisodeData);
		const selectedEpisodeKeys = selectedEpisodeData.map((item: any) => item.key);
		// console.log("selectedEpisodeKeys", selectedEpisodeKeys);
		handleSelectedRowStyling(selectedEpisodeKeys);
	}, [episodeData]);

	const changeStatusModalCancelHandler = () => {
		try {
			form.resetFields(["changed_status"]);
			setIsChangeStatusModalOpen(false);
		} catch (error) {
			console.error(error);
		}
	};

	const statusModalSubmitHandler = async () => {
		try {
			const { changed_status } = await form.getFieldsValue(["changed_status"]);

			if (changed_status?.length) {
				console.log("changed_status", changed_status);
				setLoading(true);
				const data = {
					episode_ids: selectedEpisodeData.map(item => item.id),
					changed_status: changed_status,
				};
				const config = {
					method: "POST",
					url: `${process.env.REACT_APP_BACK_END_API}/snowflake/bulk/change-episode-status`,
					data: JSON.stringify(data),
					headers: { "Content-Type": "application/json" },
				};
				const response = await authAxios(config);
				message.success(response.data.message, 3);
				fetchEpisodeDataHandler();
				setLoading(false);
			}
			changeStatusModalCancelHandler();
		} catch (err) {
			setLoading(false);
			console.error("error", err);
		}
	};

	const paramDataHandler = async (refId: string) => {
		try {
			if (refId) {
				setLoading(true);
				const [{ episodeData }] = await Promise.all([
					getEpisodeByRefId(refId),
					episodeRelatedDataTabHandler("videos", refId),
				]);
				const { episode_id, original_air_date, tag_ids, updated_at, created_at } = episodeData;
				setEpisodeEditId(episode_id);
				setCurrentEpisodeTagsData(tag_ids?.split(",")?.filter((tag: any) => tag?.trim()?.length));
				form.setFieldsValue({
					...episodeData,
					updated_at: updated_at ?? created_at,
					original_air_date: original_air_date?.length ? dayjs(original_air_date) : undefined,
					episode_tags: tag_ids
						?.split(",")
						?.filter((tag: any) => tag?.trim()?.length)
						?.map((tag: any) => tag),
				});
				setLoading(false);
				setIsEpisodeFormOpen(true);
			}
		} catch (err) {
			console.error("error in getting episode id", err);
		}
	};
	const handleTableChange = (
		pagination: PaginationProps,
		filters: any,
		sorter: any,
		{ action }: any
	) => {
		if (action === "paginate") {
			if (pagination.pageSize !== user.page_sizes.episodes) {
				const updatedSettingsData: SETTINGS_DATA = {
					user_id: user.user_id,
					page_sizes: { ...user.page_sizes, episodes: pagination.pageSize },
				};
				updateUserPageSizeSettings(updatedSettingsData);
				setUser({ ...user, ...updatedSettingsData });
			}
			setPagination(pagination);
		}
		if (action === "sort" && sorter.order)
			setEpisodeSortObj({ column: sorter.field, order: orderObj[sorter.order] });
	};

	// add episode related data helper
	const addVideoFormHelper = () => {
		const ref_id = form.getFieldValue("ref_id");
		setRefId(ref_id);
		setIsVideoFormOpen(true);
		form.setFieldValue("video_ref_id", ref_id);
	};

	const addCaptionFormHelper = () => {
		const ref_id = form.getFieldValue("ref_id");
		setRefId(ref_id);
		setIsOpenCaptionsForm(true);
		form.setFieldValue("caption_ref_id", ref_id);
	};

	const addCuePointHelper = () => {
		const ref_id = form.getFieldValue("ref_id");
		setRefId(ref_id);
		setIsCuePointFormOpen(true);
		form.setFieldValue("cuepoint_ref_id", ref_id);
	};

	const addThumbnailHelper = () => {
		const ref_id = form.getFieldValue("ref_id");
		setRefId(ref_id);
		setIsThumbnailFormOpen(true);
		form.setFieldValue("episode_ref_id", ref_id);
	};

	const getRelatedMetadata = async (ref_id: string) => {
		try {
			const response = await authAxios({
				method: "GET",
				url: `${process.env.REACT_APP_BACK_END_API}/snowflake/metadata?filter=${JSON.stringify({
					ref_id,
				})}`,
			});
			setRelatedMetadata(response.data.rows);
		} catch (error) {
			console.error(error);
		}
	};

	const getEpisodeRelatedVideos = async (ref_id: string) => {
		try {
			const response = await authAxios({
				method: "GET",
				url: `${process.env.REACT_APP_BACK_END_API}/snowflake/video/?filter=${JSON.stringify({
					ref_id,
					showHiddenEpisodes: true,
				})}`,
			});
			const convertedObject = await makeKeyInLowerCaseHelper(response.data.data);
			return convertedObject;
		} catch (error) {
			console.error(error);
			return [];
		}
	};

	const getEpisodeRelatedCuePoints = async (episodeId: string) => {
		try {
			const response = await authAxios({
				method: "GET",
				url: `${process.env.REACT_APP_BACK_END_API}/snowflake/cuepoints/${episodeId}`,
			});
			const convertedObject = await makeKeyInLowerCaseHelper(response.data.rows);
			return convertedObject;
		} catch (error) {
			console.error(error);
			return [];
		}
	};
	const getEpisodeRelatedThumbnails = async (episodeId: string) => {
		try {
			const response = await authAxios({
				method: "GET",
				url: `${process.env.REACT_APP_BACK_END_API}/snowflake/thumbnails/${episodeId}`,
			});
			const convertedObject = await makeKeyInLowerCaseHelper(response.data.rows);
			return convertedObject;
		} catch (error) {
			console.error(error);
			return [];
		}
	};
	const getEpisodeRelatedCaptions = async (episode_id: string) => {
		try {
			const response = await authAxios({
				method: "GET",
				url: `${process.env.REACT_APP_BACK_END_API}/snowflake/captions?filter=${JSON.stringify({
					episode_id,
				})}`,
			});
			const convertedObject = await makeKeyInLowerCaseHelper(response.data.rows);
			return convertedObject;
		} catch (error) {
			console.error(error);
			return [];
		}
	};

	// modal close methods
	const cancelEpisodeFormHandler = () => {
		setEpisodeEditId(null);
		setVideoEditId(null);
		setIsEpisodeFormOpen(false);
		form.resetFields(episodeFormFields);
		navigate("/episode");
	};

	const cancelVideoFormHandler = () => {
		setIsVideoFormOpen(false);
		setFormArray(["1"]);
		setVideoEditId(null);
		setMetadataEditId(undefined);
		setDurationValue("");
		setIsAddNewMetadata(false);
		setRefId(undefined);
		resetVideoFormFields(form);
		setDistributionCheckedList([]);
		setActiveDatesList([]);
	};

	const cancelCaptionFormHandler = () => {
		resetCaptionForm(form);
		setIsOpenCaptionsForm(false);
		setDistributionCheckedList([]);
		setCaptionEditId(undefined);
	};

	const filterModalCancelHandler = () => {
		// form.resetFields(["series_filter", "season_filter"]);
		setFilterEpisodeModalOpen(false);
	};

	const handleCuePointCancel = () => {
		try {
			const cuepointFormFields = ["cuepoint_offset_time", "cuepoint_ref_id", "offset_for_clone"];
			const cueponts = cuepointFields.map(key => "cue_points" + key);
			form.resetFields([...cuepointFormFields, ...cueponts]);
			setCuepointFields(initialCuepointFields);
			setDistributionCheckedList([]);
			setCuePointEditId(undefined);
			setIsCuePointFormOpen(false);
			setIsDuplicateCuePointFormOpen(false);
			setCreate5SecOffset(true);
		} catch (error) {
			console.error(error);
		}
	};

	const handleThumbnailCancel = () => {
		try {
			form.resetFields([
				"thumbnail_url",
				"thumbnail_width",
				"thumbnail_height",
				"thumbnail_ref_id",
				"thumbnail_tags",
				"thumbnail_file_name",
			]);
			setDistributionCheckedList([]);
			setIsThumbnailFormOpen(false);
			setThumbnailEditId(undefined);
		} catch (error) {
			console.error(error);
		}
	};

	// save/edit methods
	const saveUpdateEpisodeData = async () => {
		try {
			setLoading(true);
			const values = await form.validateFields(episodeFormFields);
			const id = uuidV4();
			const data: any = {
				episodeData: {
					id,
					...values,
					original_air_date: values.original_air_date
						? dayjs(values.original_air_date).format("YYYY-MM-DD")
						: "",
					currentEpisodeTagsData,
				},
			};

			if (episodeEditId) {
				data["episodeData"]["id"] = episodeEditId;
			}
			const config = {
				method: episodeEditId ? "put" : "post",
				url: `${process.env.REACT_APP_BACK_END_API}/snowflake/episode`,
				headers: {
					"Content-Type": "application/json",
				},
				data: JSON.stringify(data),
			};
			const response = await authAxios(config);
			if (response.status === 200) {
				if (episodeEditId) {
					setEpisodeEditId(null);
					setVideoEditId(null);
					setIsEpisodeFormOpen(false);
					form.resetFields(episodeFormFields);
					navigate("/episode");
				} else {
					setEpisodeEditId(id);
					navigate(`/episode/${values.ref_id}`);
				}
				message.success(
					response.data.message ??
						(episodeEditId
							? "Episode data updated successfully!"
							: "Episode data created successfully!")
				);
				fetchEpisodeDataHandler();
			}
		} catch (error: any) {
			// Catch clause variable type annotation must be 'any' or 'unknown' if specified.
			console.error("", error);
			setLoading(false);
			if (error?.response?.status === 409) {
				notification.error({
					message: "Duplicate Ref ID",
					description: error.response.data.message ?? "This episode already exists",
				});
			}
		}
	};

	const videoDataHandler = async (formArray: any) => {
		try {
			const data = await form.validateFields([
				"video_ref_id",
				"audience_language",
				"video_url",
				"video_file_name",
				"video_language",
				"dub_language",
				"sub_language",
				"video_tags",
				"meta_id",
				"duration",
				"gracenote_episodic_tms_id",
				"video_type",
				"video_size",
				"video_size",
				"video_height",
				"video_width",
				"video_lists",
				"zype_category_values",
				"0_metaLanguage",
				"0_title",
				"0_clean_title",
				"0_keywords",
				"0_description_100",
				"0_description_250",
				"0_description_full",
			]);
			const { video_ref_id } = data;
			setLoading(true);
			const response = await saveUpdateVideoData(
				formArray,
				data,
				metadataEditId,
				episodeEditId,
				currentTagsData,
				distributionCheckedList,
				videoEditId,
				activeDatesList
			);
			if (!response.data.err) {
				message.success(response.data.message);
				await getRelatedMetadata(video_ref_id);
				const videoData = await getEpisodeRelatedVideos(video_ref_id);
				setEpisodeDataTab({
					...episodeDataTabs,
					data: { ...episodeDataTabs.data, videos: videoData },
				});
				setLoading(false);
				setIsVideoFormOpen(false);
				setFormArray(["1"]);
				setVideoEditId(null);
				setMetadataEditId(undefined);
				setDurationValue("");
				setIsAddNewMetadata(false);
				setRefId(undefined);
				resetVideoFormFields(form);
				setDistributionCheckedList([]);
			}
			setLoading(false);
		} catch (err) {
			setLoading(false);
			console.error("error in saving data in episod hook", err);
		}
	};
	const captionDataHandler = async () => {
		try {
			const values = await form.validateFields([
				"caption_ref_id",
				"caption_type",
				"caption_language",
				"caption_offset_time",
				"caption_url",
				"captionFileName",
			]);
			setLoading(true);
			const response = await saveUpdateCaptionsData(
				values,
				episodeEditId as string,
				distributionCheckedList,
				captionEditId
			);
			if (!response.data.err) {
				const captionData = await getEpisodeRelatedCaptions(episodeEditId as string);
				setEpisodeDataTab({
					...episodeDataTabs,
					data: { ...episodeDataTabs.data, captions: captionData },
				});
				setIsOpenCaptionsForm(false);
				message.success(response.data.message);
				cancelCaptionFormHandler();
			}
			setLoading(false);
		} catch (err) {
			console.error("error in saving capiton data", err);
			setLoading(false);
		}
	};
	const saveCuePoint = async () => {
		try {
			const values = await form.validateFields([
				"ref_id",
				"cuepoint_ref_id",
				"cuepoint_offset_time",
				"offset_for_clone",
				...cuepointFields.map(key => "cue_points" + key),
			]);
			setLoading(true);
			await saveUpdateCuePoints(
				values,
				episodeEditId,
				distributionCheckedList,
				cuepointFields,
				create5SecOffset,
				cuePointEditId
			);
			const cuepointsData = await getEpisodeRelatedCuePoints(episodeEditId as string);
			setEpisodeDataTab({
				...episodeDataTabs,
				data: { ...episodeDataTabs.data, cuepoints: cuepointsData },
			});
			setLoading(false);
			setCuePointEditId(undefined);
			handleCuePointCancel();
		} catch (error) {
			console.error(error);
			setLoading(false);
		}
	};

	const saveEditThumbnail = async () => {
		try {
			const values = await form.validateFields([
				"ref_id",
				"thumbnail_url",
				"thumbnail_height",
				"thumbnail_width",
				"thumbnail_tags",
				"thumbnail_file_name",
			]);
			setLoading(true);
			await saveUpdateThumbnails(values, episodeEditId, distributionCheckedList, thumbnailEditId);
			const thumbnailData = await getEpisodeRelatedThumbnails(episodeEditId as string);
			setEpisodeDataTab({
				...episodeDataTabs,
				data: { ...episodeDataTabs.data, thumbnails: thumbnailData },
			});
			handleThumbnailCancel();
			setLoading(false);
		} catch (error) {
			console.error(error);
			setLoading(false);
		}
	};

	const metadataSelector = (value: string) => {
		metadataSelectHandler(
			value,
			form,
			setMetadataEditId,
			setIsAddNewMetadata,
			setFormArray,
			relatedMetadata
		);
	};

	const addNewInputHelper = () => {
		const tempArray: any = formArray.map(item => item);
		tempArray.push((formArray.length + 1).toString());
		setFormArray(tempArray);
	};

	const handleAddCustomTag = async (
		customTagValue: string,
		related_to: "video" | "thumbnail",
		insertValue: (tagId: string) => void
	) => {
		try {
			setLoading(true);
			const tagId = uuidV4();
			const values = {
				id: tagId,
				title: customTagValue,
				related_to,
			};
			let tempTagsObj = tagsObj;
			if (!tagsObj) {
				const tempObj: { [key: string]: boolean } = {};
				tagsList.forEach((tag: TagOption) => {
					tempObj[`${tag.title.toLowerCase()}-${tag.related_to}`] = true;
				});
				tempTagsObj = tempObj;
				setTagsObj(tempObj);
			}
			await saveTagsData(values, tempTagsObj);
			setTagsList([...tagsList, values]);
			setLoading(false);
			insertValue(tagId);
		} catch (error) {
			console.error(error);
			setLoading(false);
		}
	};

	const changeHandler = () => {
		const ref_id = form.getFieldValue("ref_id");
		setRefId(ref_id);
	};

	// open edit form methods
	const editVideoHelper = async (record: any) => {
		try {
			const { id, ref_id, metadata_id } = record;
			setVideoEditId(id);
			setMetadataEditId(metadata_id);
			setRefId(ref_id);
			setIsAddNewMetadata(Boolean(record.metadata_id));
			videoEditHelper(
				record,
				setDurationValue,
				setCurrentTagsData,
				form,
				setDistributionCheckedList,
				setActiveDatesList
			);
			setFormArray(record.metadata_id ? ["1"] : []);
			setIsVideoFormOpen(true);
		} catch (error) {
			console.error("error in editVideoHelper", error);
		}
	};

	const captionEditHandler = (record: any) => {
		try {
			editCaptionHelper(
				record,
				form,
				setCaptionEditId,
				setIsOpenCaptionsForm,
				setDistributionCheckedList
			);
		} catch (error) {
			console.error("error in caption edit handler", error);
		}
	};

	const cueEditHandler = (record: any, isCloneEditor?: boolean) => {
		try {
			editHelper(
				record,
				form,
				setCuePointEditId,
				isCloneEditor ? setIsDuplicateCuePointFormOpen : setIsCuePointFormOpen,
				setDistributionCheckedList,
				setCuepointFields
			);
		} catch (error) {
			console.error("error in caption edit handler", error);
		}
	};

	const thumbnailEditHandler = (record: any) => {
		try {
			editThumbnailHelper(
				record,
				form,
				setThumbnailEditId,
				setIsThumbnailFormOpen,
				setDistributionCheckedList
			);
		} catch (error) {
			console.error("error in caption edit handler", error);
		}
	};

	// filter methods
	const getFileteredData = async () => {
		try {
			const { series_filter, season_filter, episode_tags_filter, episode_not_tagged } =
				await form.getFieldsValue([
					"series_filter",
					"season_filter",
					"episode_tags_filter",
					"episode_not_tagged",
				]);
			setFilterEpisodeModalOpen(false);
			if (
				series_filter?.length ||
				season_filter?.length ||
				episode_tags_filter?.length ||
				episode_not_tagged?.length
			) {
				setIsFilteredData(true);
				setEpisodeFilterObj({
					season: season_filter,
					series: series_filter,
					episode_tags: episode_tags_filter,
					not_tagged: episode_not_tagged,
					showHiddenEpisodes: user.show_hidden_episodes,
				});
			}
		} catch (err) {
			console.error("error in filte", err);
		}
	};

	const clearFilter = () => {
		setLoading(true);
		setIsFilteredData(false);
		setEpisodeFilterObj({ showHiddenEpisodes: user.show_hidden_episodes });
		form.resetFields([
			"series_filter",
			"episode_tags_filter",
			"episode_not_tagged",
			"season_filter",
		]);
	};

	const searchData = async () => {
		try {
			setPagination({ ...initialPaginationObj, pageSize: pagination.pageSize });
			searchHandler(form, timeoutId, setTimeoutId, setSearchQuery);
		} catch (err) {
			console.error({ err });
		}
	};

	// export all the data according to filters selected
	const exportCSV = async () => {
		try {
			const {
				series_filter,
				season_filter,
				episode_tags_filter,
				episode_not_tagged,
				format,
				secondary_platforms,
			} = await form.getFieldsValue([
				"series_filter",
				"season_filter",
				"episode_tags_filter",
				"episode_not_tagged",
				"format",
				"secondary_platforms",
			]);

			if (!format)
				return notification.error({
					message: "Error!",
					description: "Please select a format",
				});

			const data = {
				series: series_filter ?? [],
				season: season_filter ? [season_filter] : [],
				episode_tags: episode_tags_filter ?? [],
				episode_not_tagged: episode_not_tagged ?? [],
				include_distribution_platforms: Boolean(format === "videos-distribution"),
				includeInactiveEpisodes: episodeFilterObj.showHiddenEpisodes,
				secondary_platforms,
			};
			const exportType = format === "videos-distribution" ? "videos" : format;
			await exportCSVWithLambda(data, exportType, user.name, "Export");
		} catch (err) {
			console.error("error in exportCSV", err);
		}
	};

	const hiddenEpisodesHandler = () => {
		updateHiddenEpisodesHandler(user.user_id, user.show_hidden_episodes);
		setUser({ ...user, show_hidden_episodes: !user.show_hidden_episodes });
		setEpisodeFilterObj({
			...episodeFilterObj,
			showHiddenEpisodes: !user.show_hidden_episodes,
		});
	};

	const [episodeDataTabs, setEpisodeDataTab] =
		useState<EpisodeRelatedDataTabs>(initialEpisodeRelatedData);

	const getEpisodeTabsObj = (type: string, refId?: string) => {
		const episodeData: any = {
			videos: {
				method: getEpisodeRelatedVideos,
				param: refId,
				addButtonText: "+ Add Video",
				editHelper: editVideoHelper,
				addHelper: addVideoFormHelper,
				column: getEpisodeVideColumns(editVideoHelper, deleteVideoData, loading, playVideo),
			},
			captions: {
				method: getEpisodeRelatedCaptions,
				param: episodeEditId,
				addButtonText: "+ Add Caption",
				editHelper: captionEditHandler,
				addHelper: addCaptionFormHelper,
				column: getCaptionDataColumns(deleteCaptionHandler, captionEditHandler, loading),
			},
			cuepoints: {
				method: getEpisodeRelatedCuePoints,
				param: episodeEditId,
				addButtonText: "+ Add Cue Point",
				editHelper: cueEditHandler,
				addHelper: addCuePointHelper,
				column: cuePointsColumn(cueEditHandler, deleteCuePointHandler),
			},
			thumbnails: {
				method: getEpisodeRelatedThumbnails,
				param: episodeEditId,
				addButtonText: "+ Add Thumbnail",
				editHelper: thumbnailEditHandler,
				addHelper: addThumbnailHelper,
				column: getThumbnailColumns(thumbnailEditHandler, delteThumbnailHandler, true),
			},
		};
		return episodeData[type];
	};
	const episodeRelatedDataTabHandler = async (value: EpisodeDataTabKeys, ref_id?: string) => {
		setLoading(true);
		const refId = ref_id?.length ? ref_id : form.getFieldValue("ref_id");

		const tabDataObj = getEpisodeTabsObj(value, refId);
		const getDataPromiseList = [tabDataObj["method"](tabDataObj["param"])];
		if (value === "videos") getDataPromiseList.push(getRelatedMetadata(refId));
		const [tabData] = await Promise.all(getDataPromiseList);
		setEpisodeDataTab({
			key: value,
			addButtonText: tabDataObj["addButtonText"],
			addHelper: tabDataObj["addHelper"],
			editHelper: tabDataObj["editHelper"],
			addHandler: tabDataObj["addHandler"],
			column: tabDataObj["column"],
			data: { ...episodeDataTabs.data, [value]: tabData },
		});
		setLoading(false);
	};

	// delete methods
	const deleteEpisodeData = async (record: any) => {
		try {
			setLoading(true);
			const { episode_id } = record;
			const config = {
				method: "delete",
				url: `${process.env.REACT_APP_BACK_END_API}/snowflake/episode/${episode_id}`,
			};

			const response = await authAxios(config);
			message.success(response.data.message);
			fetchEpisodeDataHandler();
		} catch (err) {
			setLoading(false);
			console.error("error in deleteEpisodeData in episode", err);
		}
	};

	const deleteVideoData = async (record: any) => {
		try {
			setLoading(true);
			const config = {
				method: "delete",
				url: `${process.env.REACT_APP_BACK_END_API}/snowflake/video/${record.id}`,
			};
			const response = await authAxios(config);
			if (!response.data.err) {
				message.success(response.data.message);
				episodeRelatedDataTabHandler("videos");
			}
		} catch (err) {
			setLoading(false);
			console.error("error in deleteVidesData in episode", err);
		}
	};

	const deleteCaptionHandler = async (id: string) => {
		try {
			setLoading(true);
			const response = await deleteCaption(id);
			if (response.status === 200) {
				message.success(response.data.message);
				episodeRelatedDataTabHandler("captions");
			}
		} catch (err) {
			console.error("erro", err);
			setLoading(true);
		}
	};

	const deleteCuePointHandler = async (id: string) => {
		try {
			setLoading(true);
			const response = await deleteCuePoint(id);
			if (response.status === 200) {
				message.success(response.data.message);
				episodeRelatedDataTabHandler("cuepoints");
			}
		} catch (error) {
			console.error(error);
			setLoading(false);
		}
	};

	const delteThumbnailHandler = async (id: string) => {
		try {
			setLoading(true);
			const response = await deleteThumbnail(id);
			if (response.status === 200) {
				message.success(response.data.message);
				episodeRelatedDataTabHandler("thumbnails");
			}
		} catch (error) {
			console.error(error);
			setLoading(false);
		}
	};

	const playVideo = async (record: any) => {
		const signedURL = await getSignedURL(record.url);
		if (signedURL)
			setVideoPlayerDetails({ signedURL, fileName: record.file_name, url: record.url });
		else
			notification.error({
				message: "Invalid URL",
				description: "The URL is not found. Please update URL and try again.",
			});
	};

	const closeVideo = async () => {
		setVideoPlayerDetails(undefined);
	};

	const clearEpisodeSelection = () => {
		const selectedEpisodeKeys = selectedEpisodeData.map((item: any) => item.key);
		handleSelectedRowStyling(selectedEpisodeKeys, true);
		sessionStorage.removeItem("selectedEpisodeData");
		setSelectedEpisodeData([]);
	};

	let columnsWithBulkSelect: ColumnsType<any> = getBulkSelectColumns(
		episodeData,
		selectedEpisodeData,
		setSelectedEpisodeData,
		handleSelectedRowStyling,
		"selectedEpisodeData"
	);
	columnsWithBulkSelect = columnsWithBulkSelect.concat(
		getColumnEpisodes(deleteEpisodeData, loading, episodeFilterObj)
	);

	return {
		columnEpisode: columnsWithBulkSelect, //getColumnEpisodes(deleteEpisodeData, loading, episodeFilterObj),
		episodeData,
		isLoading: loading,
		setIsLoading: setLoading,
		isAddEpisdoeFormOpen,
		setIsEpisodeFormOpen,
		form,
		episodeEditId,
		setEpisodeEditId,
		seriesData: seriesList,
		saveUpdateEpisodeData,
		cancelEpisodeFormHandler,
		isAddVideoFormOpen,
		cancelVideoFormHandler,
		metadataSelector,
		isAddNewMetadata,
		durationValue,
		setDurationValue,
		addNewInputHelper,
		videoEditId,
		setFormArray,
		formArray,
		relatedMetadata,
		languages: languagesList,
		tagsData: tagsList,
		handleAddCustomTag,
		ref_id,
		saveUpdateVideoData: videoDataHandler,
		changeHandler,
		getFileteredData,
		filterEpisodeModalOpen,
		setFilterEpisodeModalOpen,
		filterModalCancelHandler,
		isFilteredData,
		setIsFilteredData,
		clearFilter,
		pagination,
		handleTableChange,
		exportCSV,
		distributionPlatform: distributionPlatformData,
		isExportModalOpen,
		setIsExportModalOpen,
		captionDataHandler,
		isOpenCaptionsForm,
		cancelCaptionFormHandler,
		captionEditId,
		metadataEditId,
		searchData,
		ratings,
		hiddenEpisodesHandler,
		episodeFilterObj,

		// cuepoint vars
		saveCuePoint,
		handleCuePointCancel,
		cuePointEditId,
		isAddCuePointFormOpen,
		isDuplicateCuePointFormOpen,
		cuepointFields,
		setCuepointFields,
		create5SecOffset,
		setCreate5SecOffset,

		// thumbnail vars
		saveEditThumbnail,
		handleThumbnailCancel,
		thumbnailEditId,
		isAddThumbnailFormOpen,
		episodeRelatedDataTabHandler,
		episodeDataTabs,

		// player details
		videoPlayerDetails,
		closeVideo,

		distributionCheckedList,
		distributionChange,
		onDistributionCheckAllChange,
		checkAll,
		indeterminate,
		videoLists,
		exportFormat,
		setExportFormat,
		activeDatesList,
		setActiveDatesList,
		setIsAddNewMetadata,
		setMetadataEditId,
		selectedEpisodeData,
		isChangeStatusModalOpen,
		setIsChangeStatusModalOpen,
		changeStatusModalCancelHandler,
		statusModalSubmitHandler,
		clearEpisodeSelection,
	};
};
