import React, { Fragment, useState, useEffect } from "react";
import { Form, Popconfirm, Tag, message } from "antd";
import { ColumnsType } from "antd/lib/table/interface";
import { DeleteOutlined, EditOutlined } from "@ant-design/icons";
import { useOutletContext } from "react-router-dom";

import { SETTINGS_DATA, getTagsData, updateUserPageSizeSettings } from "../../utils";
import { CustomBtn } from "../../series/StyledComponent";
import { authAxios } from "../../utils/session_utils";
import { initialPaginationObj } from "../../episode/hooks/useEpisodeHook";
import { PaginationProps } from "antd/lib/pagination";
import { TagOption, TagsFormValues, saveTagsData } from "./utils";

export const useTagsHooks = () => {
	const { tagsList, setTagsList, user, setUser }: any = useOutletContext();
	const [isLoading, setIsLoading] = useState<boolean>(false);
	const [form] = Form.useForm();
	const [isAddTagsFormOpen, setIsTagsFormOpen] = useState<boolean>(false);
	const [editId, setEditId] = useState<string | undefined>();
	const [tagsObj, setTagsObj] = useState<any>({});
	const [pagination, setPagination] = useState<PaginationProps>({
		...initialPaginationObj,
		pageSize: user.page_sizes?.tags ?? 10,
		total: tagsList.length,
	});
	useEffect(() => {
		setPagination({ ...pagination, total: tagsList.length });
		const tempObj: { [key: string]: boolean } = {};
		tagsList.forEach((tag: TagOption) => {
			tempObj[`${tag.title.toLowerCase()}-${tag.related_to}`] = true;
		});
		setTagsObj(tempObj);
	}, [tagsList]);

	const handleTableChange = (pagination: PaginationProps, _: any, __: any, { action }: any) => {
		if (action === "paginate") {
			if (pagination.pageSize !== user.page_sizes.tags) {
				const updatedSettingsData: SETTINGS_DATA = {
					user_id: user.user_id,
					page_sizes: { ...user.page_sizes, tags: pagination.pageSize },
				};
				updateUserPageSizeSettings(updatedSettingsData);
				setUser({ ...user, ...updatedSettingsData });
			}
			setPagination(pagination);
		}
	};
	const saveTagsDataHandler = async () => {
		try {
			setIsLoading(true);
			const values: TagsFormValues = await form.validateFields();
			await saveTagsData(values, tagsObj, editId);
			handleCancel();
			await getTagsData(setTagsList, setIsLoading);
			setIsLoading(false);
		} catch (err) {
			setIsLoading(false);
			console.error("erro while saving tags data", err);
		}
	};

	const deleteTagsData = async (record: TagOption) => {
		try {
			const config = {
				method: "delete",
				url: `${process.env.REACT_APP_BACK_END_API}/snowflake/tags/${record.id}`,
				headers: {
					"Content-Type": "application/json",
				},
			};
			const response = await authAxios(config);

			if (!response.data.err) {
				message.success(response.data.message);
				getTagsData(setTagsList, setIsLoading);
			}
		} catch (err) {
			console.error("erro", err);
		}
	};
	const handleCancel = () => {
		form.resetFields();
		setIsTagsFormOpen(false);
		setEditId(undefined);
	};

	const editHelper = async (record: TagOption) => {
		setIsTagsFormOpen(true);
		form.setFieldsValue({ ...record });
		setEditId(record.id);
	};
	const tagsSeries: ColumnsType<object> = [
		{
			title: "Tag",
			dataIndex: "title",
			key: "title",
			align: "center",
			render: value => {
				return <Tag color="green">{value}</Tag>;
			},
		},
		{
			title: "For",
			dataIndex: "related_to",
			key: "related_to",
			align: "center",
			sorter: (a: any, b: any) => a.related_to.localeCompare(b.related_to),
			render: value => {
				return value === "video" ? "video/episode" : value;
			},
		},
		{
			title: "Action",
			dataIndex: "action",
			key: "action",
			align: "center",
			render: (value, record: any) => {
				return (
					<Fragment>
						<CustomBtn
							icon={<EditOutlined />}
							type={"link"}
							onClick={e => {
								e.stopPropagation();
								editHelper(record);
							}}
						/>
						<CustomBtn
							onClick={e => e.stopPropagation()}
							icon={
								<Popconfirm
									okButtonProps={{ danger: true }}
									title="Delete Tag?"
									description={`Are you sure you want to delete this tag? It will delete all relationship between ${
										record.related_to === "thumbnail" ? "thumbnails" : "videos and episodes"
									}`}
									cancelText="Cancel"
									// onCancel={() => deleteVideosData(record)}
									okText="Delete Tag"
									onConfirm={() => deleteTagsData(record)}
								>
									<DeleteOutlined title="Delete" style={{ color: "#D10000" }} />
								</Popconfirm>
							}
							type={"link"}
						/>
					</Fragment>
				);
			},
		},
	];
	return {
		tagsSeries,
		tagsData: tagsList,
		isLoading,
		setIsLoading,
		getTagsData,
		saveTagsData: saveTagsDataHandler,
		isAddTagsFormOpen,
		setIsTagsFormOpen,
		form,
		deleteTagsData,
		handleCancel,
		editId,
		editHelper,
		pagination,
		handleTableChange,
	};
};
