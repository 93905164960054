import axios, { AxiosRequestConfig } from "axios";

export function getEmailFromSessionStorage(): string | undefined {
	const encodedEmail = window.sessionStorage.getItem("mvmgsid");
	if(encodedEmail) {
		return encodedEmail;
	} else 
	return "";

}

export async function getAPIToken(google_credential: any): Promise<any> {
	const response = await axios.post(`${process.env.REACT_APP_BACK_END_API}/token`, {
		credential: google_credential,
	});
	return response.data;
}

export function getTokenFromSessionStorage(): string | undefined {
	const token = window.sessionStorage.getItem("mvmtoken") as string | null;
	if (token === null) {
		return "NoTokenFoundOnWindowSessionStorage";
	} else {
		return token;
	}
}
// Custom fetch wrapper function
export function fetchWithAuthorization(url: string, options: RequestInit = {}): Promise<Response> {
	// Ensure options.headers exists
	options.headers = options.headers || {};
	// Create a Headers object
	const headers = new Headers(options.headers);
	// Add the Authorization header with the Bearer Token
	headers.append("Authorization", `Bearer ${getTokenFromSessionStorage()}`);
	// Set the headers in the options
	options.headers = headers;
	// Call the original fetch function
	return fetch(url, options);
}

export const authAxios = (config: AxiosRequestConfig = {}) => {
	const headers = config.headers ?? {};
	const authHeader = {
		Authorization: `Bearer ${getTokenFromSessionStorage()}`,
	};
	config.headers = { ...headers, ...authHeader };
	return axios.request(config);
};



export const sendJsonToServer = async (jsonData: string) => {
    const url = `${process.env.REACT_APP_BACK_END_API}/snowflake/upload`;
    const headers = {
        'Authorization': `Bearer ${getTokenFromSessionStorage()}`,
        'Content-Type': 'application/json'
    };

    try {
        const response = await axios.post(url, jsonData, { headers });
        return response.data;
    } catch (error) {
        console.error('Error sending JSON to server:', error);
        throw error;
    }
};