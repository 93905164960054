import { FormInstance, PaginationProps, notification } from "antd";
import { Dispatch, SetStateAction } from "react";
import moment from "moment-timezone";
import dayjs from "dayjs";

import { ActiveDatesObj, MetadataType, SortObj, VideoFilterObj } from "../videos/videos";
import { MetadataFilter } from "../metadata/hooks/useMetadataHook";
import { EpisodeFilterObj } from "../episode/hooks/useEpisodeHook";
import { authAxios, fetchWithAuthorization, getEmailFromSessionStorage } from "./session_utils";
import { USERDATA, USER_PAGE_SIZE } from "../../App";
import { CheckboxValueType } from "antd/es/checkbox/Group";
import { getFileNameWithURL } from "../videos/modals/AddVideosMetaDataForm";
import { ThumbnailsFilterObj } from "../thumbnails/hooks/useThumbnailsHook";
import { videoFormFields } from "../videos/hooks/useVideosHooks";

export const getDigitsOnly = (string: string, withPlusMinusSign?: boolean) => {
	const pattern = withPlusMinusSign ? /^(-?\d*\.?\d*)[\s\S]*$/ : /\D/g; // Matches any non-digit character
	const numbersOnly = string.replace(pattern, withPlusMinusSign ? "$1" : "");
	return numbersOnly;
};

export const getDurationOnly = (string: string) => {
	const pattern = /[^0-9:.]+$/g; // Matches any non-digit character
	const durationOnly = string.replace(pattern, "");
	return durationOnly;
};

// This method will accept following formats:
// - "0:10:09"
// - "10:10"
// - "00:10:10.999"
// - "10:10.999"
// - "10.999"
// - "50"
// and will return a string as "hh:mm:ss.SSS"
export const durationCoverter = (duration: string) => {
	let hrs = "00",
		min = "00",
		sec = "00",
		ms = "000";
	if (!duration?.trim()) return `${hrs}:${min}:${sec}.${ms}`;
	const durationList = duration.split(":");
	if (durationList.length > 2) {
		[hrs, min, sec] = durationList;
	} else if (durationList.length > 1) {
		[min, sec] = durationList;
	} else {
		sec = durationList[0];
	}

	if (sec.includes(".")) {
		const [seconds, milliseconds] = sec.split(".");
		sec = seconds;
		ms = milliseconds || "000";
	}

	return `${hrs}:${min}:${sec}.${ms}`;
};

export const durationToMilliseconds = (duration: any) =>
	duration?.trim() ? moment.duration(durationCoverter(duration)).asMilliseconds() : 0;

export interface LowerCaseKeyInterFace {
	[key: string]: any;
}
export const makeKeyInLowerCaseHelper = async (data: any): Promise<LowerCaseKeyInterFace[]> => {
	return data.map((series: LowerCaseKeyInterFace, index: number) => {
		const lowecaseObject: LowerCaseKeyInterFace = {};
		Object.keys(series).forEach(key => {
			lowecaseObject[key.toLowerCase()] = series[key] ?? "";
			lowecaseObject["key"] = `${series["ID"]}-${index}`;
		});
		return lowecaseObject;
	});
};

export const getLanguageList = (setLanguageList: (value: any[]) => void) => {
	authAxios({
		method: "GET",
		url: `${process.env.REACT_APP_BACK_END_API}/snowflake/language`,
	})
		.then((response: any) => {
			const languageList = response.data.data.map((data: any) => {
				return { ...data, key: data.CODE };
			});
			setLanguageList(languageList);
		})
		.catch((err: any) => {
			setLanguageList([]);
			console.error("error while fetching language data", err);
		});
};

export const handleSelectedRowStyling = (selectedVideoKeys: string[], remove?: boolean) => {
	try {
		if (!remove) {
			selectedVideoKeys.forEach(key => {
				const selectedRowList = document.querySelectorAll(`[data-row-key="${key}"]`);
				const selectedRow = selectedRowList[0];
				selectedRow?.classList?.add("ant-table-row-selected");
			});
		} else {
			selectedVideoKeys.forEach(key => {
				const selectedRowList = document.querySelectorAll(`[data-row-key="${key}"]`);
				const selectedRow = selectedRowList[0];
				selectedRow?.classList?.remove("ant-table-row-selected");
			});
		}
	} catch (error) {
		console.error(error);
	}
};

export const getVideosData = async (
	setVideosData: Dispatch<SetStateAction<any[]>>,
	setIsLoading: Dispatch<SetStateAction<boolean>>,
	pagination: PaginationProps,
	videosSortObj: SortObj,
	searchQuery: string,
	videoFilterObj?: VideoFilterObj
) => {
	return new Promise(resolve => {
		fetchWithAuthorization(
			`${process.env.REACT_APP_BACK_END_API}/snowflake/video?pagination=${JSON.stringify(
				pagination
			)}&sortObj=${JSON.stringify(videosSortObj ?? {})}&filter=${JSON.stringify(
				videoFilterObj ?? {}
			)}&searchQuery=${searchQuery}`
		)
			.then(res => res.json())
			.then(async ({ data }) => {
				const convertedObject = await makeKeyInLowerCaseHelper(data);
				setVideosData(convertedObject);
				setIsLoading(false);
				resolve("Done");
			})
			.catch(() => {
				setIsLoading(false);
				resolve("Error");
			});
	});
};

export const getVideoById = async (id: string) => {
	try {
		const config = {
			method: "get",
			url: `${process.env.REACT_APP_BACK_END_API}/snowflake/video/${id}`,
		};
		const response = await authAxios(config);
		if (!response.data.err) {
			const convertedObject = await makeKeyInLowerCaseHelper(response.data.data);
			return convertedObject;
		}
	} catch (error) {
		console.error("getVideoById ~ error:", error);
		return;
	}
};

export const getTotalDataCount = (
	route: string,
	pagination: PaginationProps,
	setPagination: Dispatch<SetStateAction<PaginationProps>>,
	filterObj?: any,
	searchQuery?: string
) => {
	fetchWithAuthorization(
		`${process.env.REACT_APP_BACK_END_API}/snowflake/${route}/total?filter=${JSON.stringify(
			filterObj ?? {}
		)}&searchQuery=${searchQuery}`
	)
		.then(res => res.json())
		.then(async ({ total }) => {
			setPagination({ ...pagination, total });
		});
};

export const getDataHandler = (
	setData: Dispatch<SetStateAction<any[]>>,
	setTotalData: Dispatch<SetStateAction<number>>,
	setIsLoading: Dispatch<SetStateAction<boolean>>,
	route: string,
	pagination?: PaginationProps,
	sortObj?: SortObj,
	filterObj?: VideoFilterObj
) => {
	fetchWithAuthorization(
		`${process.env.REACT_APP_BACK_END_API}/snowflake/${route}?pagination=${JSON.stringify(
			pagination ?? {}
		)}&sortObj=${JSON.stringify(sortObj ?? {})}&filter=${JSON.stringify(filterObj ?? {})}`
	)
		.then(res => res.json())
		.then(async ({ rows, totalData }) => {
			const convertedObject = await makeKeyInLowerCaseHelper(rows);
			setData(convertedObject);
			setTotalData(totalData);
			setIsLoading(false);
		})
		.catch(() => setIsLoading(false));
};

export const getWholeData = async (
	route: string,
	setData: Dispatch<SetStateAction<any[]>>,
	sortObj?: any,
	setLoading?: any
) => {
	fetchWithAuthorization(
		`${process.env.REACT_APP_BACK_END_API}/snowflake/${route}?sortObj=${JSON.stringify(
			sortObj ?? {}
		)}`
	)
		.then(res => res.json())
		.then(async ({ rows }) => {
			const convertedObject = await makeKeyInLowerCaseHelper(rows);
			setData(convertedObject);
			setLoading && setLoading(false);
		});
};

export const getSeriesData = (
	setSeriesData: Dispatch<SetStateAction<any[]>>,
	setIsLoading?: (value: boolean) => void,
	sortObj?: any
) => {
	setIsLoading && setIsLoading(true);
	fetchWithAuthorization(
		`${process.env.REACT_APP_BACK_END_API}/snowflake/series?sortObj=${JSON.stringify(
			sortObj ?? {}
		)}`
	)
		.then(res => res.json())
		.then(async ({ data }) => {
			const convertedObject = await makeKeyInLowerCaseHelper(data);
			setSeriesData(convertedObject);
			setIsLoading && setIsLoading(false);
		})
		.catch(() => setIsLoading && setIsLoading(false));
};

export const getMetaData = (
	setMetaData: Dispatch<SetStateAction<any[]>>,
	setIsLoading: Dispatch<SetStateAction<boolean>>,
	pagination: PaginationProps,
	setPagination: Dispatch<SetStateAction<PaginationProps>>,
	metadataSortObj: SortObj,
	filterObj?: MetadataFilter
) => {
	fetchWithAuthorization(
		`${process.env.REACT_APP_BACK_END_API}/snowflake/metadata?pagination=${JSON.stringify(
			pagination ?? {}
		)}&sortObj=${JSON.stringify(metadataSortObj ?? {})}&filter=${JSON.stringify(filterObj ?? {})}`
	)
		.then(res => res.json())
		.then(async ({ rows, totalRows }) => {
			const convertedObject = await makeKeyInLowerCaseHelper(rows);
			setPagination({ ...pagination, total: totalRows });
			setMetaData(convertedObject);
			setIsLoading(false);
		})
		.catch(() => setIsLoading(false));
};

export enum HomeTabKeyEnum {
	BASE = "/",
	VIDEOS = "/video",
	METADATA = "/metadata",
	SERIES = "/series",
	ZYPE = "/zype",
	WURL = "/wurl",
	LANGUAGE = "/language",
	SETTINGS_LANGUAGE = "/settings/language",
	SETTINGS_USER = "/settings/user",
	VIDEO_EXPORT = "/export/video",
	ASSETS_EXPORT = "/export/asset",
	ZYPE_EXPORT = "/export/platforms/zype",
	WURL_EXPORT = "/export/platforms/wurl",
	VIDEOS_IMPORT = "/import/video",
	ASSETS_IMPORT = "/import/asset",
	TAGS = "/tag",
	EPISODE = "/episode",
	AUTO_PODDER = "/auto-podder",
	BATCH_JOBS = "/batch-jobs",
	POST_LOG_PROCESSOR = "/post-log-processor",
	CREATE_EPG = "/scheduling/create-epg",
	DISTRIBUTIONR_PLATFORM = "/distribution-platform",
	ALTERNATE_TITLES = "/alternate-title",
	VIDEO_LISTS = "/video-lists",
	CAPTIONS = "/captions",
	CUEPOINTS = "/cuepoints",
	THUMBNAILS = "/thumbnails",
	VIEWERSHIP_DATA = "/data_processing",
	VIEWERSHIP_UPLOADER = "/viewership_uploader",
	INVOICE_UPLOADER = "/invoice_uploader",
	EPISODE_SCORING = "/scoring/episodes"
}

export const titleObj: any = {
	[HomeTabKeyEnum.BASE]: "Home",
	[HomeTabKeyEnum.VIDEOS]: "Videos",
	[HomeTabKeyEnum.METADATA]: "Metadata",
	[HomeTabKeyEnum.SERIES]: "Series",
	[HomeTabKeyEnum.ZYPE]: "Zype",
	[HomeTabKeyEnum.WURL]: "Wurl",
	[HomeTabKeyEnum.LANGUAGE]: "Language",
	[HomeTabKeyEnum.SETTINGS_LANGUAGE]: "Settings | Language",
	[HomeTabKeyEnum.SETTINGS_USER]: "Settings | User",
	[HomeTabKeyEnum.VIDEO_EXPORT]: "Export | Videos",
	[HomeTabKeyEnum.ASSETS_EXPORT]: "Export | Assets",
	[HomeTabKeyEnum.ZYPE_EXPORT]: "Platform Export | Zype",
	[HomeTabKeyEnum.WURL_EXPORT]: "Platform Export | Wurl",
	[HomeTabKeyEnum.VIDEOS_IMPORT]: "Import | Videos",
	[HomeTabKeyEnum.ASSETS_IMPORT]: "Import | Assets",
	[HomeTabKeyEnum.TAGS]: "Tags",
	[HomeTabKeyEnum.EPISODE]: "Episodes",
	[HomeTabKeyEnum.AUTO_PODDER]: "Auto Podder",
	[HomeTabKeyEnum.CREATE_EPG]: "Create EPG",
	[HomeTabKeyEnum.POST_LOG_PROCESSOR]: "Post Log Processor",
	[HomeTabKeyEnum.BATCH_JOBS]: "Batch Jobs",
	[HomeTabKeyEnum.DISTRIBUTIONR_PLATFORM]: "Distribution Platform",
	[HomeTabKeyEnum.CAPTIONS]: "Captions",
	[HomeTabKeyEnum.CUEPOINTS]: "Cue Points",
	[HomeTabKeyEnum.THUMBNAILS]: "Thumbnails",
	[HomeTabKeyEnum.ALTERNATE_TITLES]: "Alternate Titles",
	[HomeTabKeyEnum.VIDEO_LISTS]: "Video Lists",
	[HomeTabKeyEnum.VIEWERSHIP_DATA]: "Vewership Data",
	[HomeTabKeyEnum.VIEWERSHIP_UPLOADER]: "Viewership Uploader",
	[HomeTabKeyEnum.INVOICE_UPLOADER]: "Invoices Uploader",
	[HomeTabKeyEnum.EPISODE_SCORING]: "Scoring | Episode",
};

export const getTagsData = async (
	setTagsData: Dispatch<SetStateAction<any[]>>,
	setIsLoading?: (value: boolean) => void
) => {
	try {
		setIsLoading && setIsLoading(true);
		const config = {
			method: "get",
			url: `${process.env.REACT_APP_BACK_END_API}/snowflake/tags`,
		};

		const response = await authAxios(config);
		if (!response.data.err) {
			const convertedObject = await makeKeyInLowerCaseHelper(response.data.rows);
			setTagsData(convertedObject);
			setIsLoading && setIsLoading(false);
		}
	} catch (err) {
		setIsLoading && setIsLoading(false);
		console.error("erro while fetching tags data", err);
	}
};

export const getAllMetadata = async (setAllMetadata: (data: any) => void) => {
	try {
		const config = {
			method: "get",
			url: `${process.env.REACT_APP_BACK_END_API}/snowflake/metadata`,
		};
		const respones = await authAxios(config);

		if (!respones.data.err) {
			setAllMetadata(respones.data.rows);
			// setIsAddNewMetadata(!respones.data.rows.length);
		}
	} catch (err) {
		console.error(err);
	}
};

export const getEpisodeData = (
	setEpisodeData: Dispatch<SetStateAction<any[]>>,
	setIsLoading: Dispatch<SetStateAction<boolean>>,
	pagination: PaginationProps,
	setPagination: Dispatch<SetStateAction<PaginationProps>>,
	episodeSortObj: SortObj,
	searchQuery: string,
	filterObj?: EpisodeFilterObj
) => {
	fetchWithAuthorization(
		`${process.env.REACT_APP_BACK_END_API}/snowflake/episode?pagination=${JSON.stringify(
			pagination ?? {}
		)}&sortObj=${JSON.stringify(episodeSortObj ?? {})}&filter=${JSON.stringify(
			filterObj ?? {}
		)}&searchQuery=${searchQuery}`
	)
		.then(res => res.json())
		.then(async ({ rows, total }) => {
			const convertedObject = await makeKeyInLowerCaseHelper(rows);
			setPagination({ ...pagination, total });
			setEpisodeData(convertedObject);
			setIsLoading(false);
		})
		.catch(() => setIsLoading(false));
};

export const getEpisodeByRefId = (ref_id: string) => {
	return new Promise<{ episodeData: any }>((resolve, reject) => {
		try {
			fetchWithAuthorization(
				`${process.env.REACT_APP_BACK_END_API}/snowflake/episode?filter=${JSON.stringify({
					ref_id,
					showHiddenEpisodes: true,
				})}`
			)
				.then(res => res.json())
				.then(async ({ rows }) => {
					const convertedObject = await makeKeyInLowerCaseHelper(rows);
					resolve({ episodeData: convertedObject[0] });
				});
		} catch (err) {
			reject({ err });
		}
	});
};

//no-useless-escape
// export const emailRegEx = /^([A-Za-z0-9_+%\-\.])+\@([A-Za-z0-9_+%\-\.])+\.([A-Za-z]{2,4})$/;
export const emailRegEx = /^([A-Za-z0-9_+%.-])+@([A-Za-z0-9_%.-])+\.[A-Za-z]{2,4}$/;

export const checkIfValidEmail = (_: any, value: string) => {
	if (!value || value === "") {
		return Promise.reject("Email Address is required!");
	}
	if (!value.match(emailRegEx)) {
		return Promise.reject("Email address is not valid!");
	}
	return Promise.resolve();
};

export const resetVideoFormFields = (form: FormInstance) => {
	form.resetFields(videoFormFields);
};

export const saveUpdateVideoData = async (
	formArray: any,
	data: any,
	metadataEditId: string | null | undefined,
	episodeId: string | null | undefined,
	currentTagsData: any[],
	distributionCheckedList: CheckboxValueType[],
	videoEditId: string | null | undefined,
	activeDatesList: ActiveDatesObj[]
) => {
	try {
		const metadataArr: any = [];
		const {
			video_ref_id,
			video_language,
			video_tags,
			video_url,
			duration,
			gracenote_episodic_tms_id,
			video_size,
			video_file_name,
		} = data;

		formArray.forEach((element: any, index: number) => {
			const tempObj: any = {
				metadata_language: data[`${index}_metaLanguage`],
				metadata_title: data[`${index}_title`],
				metadata_clean_title: data[`${index}_clean_title`] ?? "",
				metadata_keywords: data[`${index}_keywords`] ?? "",
				metadata_description_100: data[`${index}_description_100`] ?? "",
				metadata_description_250: data[`${index}_description_250`],
				metadata_description_full: data[`${index}_description_full`] ?? "",
			};
			if (metadataEditId) {
				tempObj["id"] = metadataEditId;
			}
			metadataArr.push(tempObj);
		});
		const bodyData: any = {
			videoData: {
				...data,
				ref_id: video_ref_id,
				language: video_language,
				episode_id: episodeId,
				url: video_url ?? "",
				bumper_link: "",
				gracenote_episodic_tms_id: gracenote_episodic_tms_id ? gracenote_episodic_tms_id[0] : "", // only one gracenote id per video
				duration: duration ? moment.duration(durationCoverter(duration)).asSeconds() : 0,
				metadata: metadataArr,
				tags: video_tags,
				size: video_size,
				file_name: video_file_name,
				currentTagsData,
				distribution_platforms_data: activeDatesList,
			},
		};
		if (videoEditId) {
			bodyData["videoData"]["id"] = videoEditId;
		}

		const config = {
			method: videoEditId ? "put" : "post",
			url: `${process.env.REACT_APP_BACK_END_API}/snowflake/video`,
			headers: { "Content-Type": "application/json" },
			data: JSON.stringify(bodyData),
		};

		const response = await authAxios(config);
		return response;
	} catch (err) {
		console.error("error while saving videos data", err);
		return { data: { err } };
	}
};

export const videoEditHelper = (
	record: any,
	setDurationValue: Dispatch<SetStateAction<any>>,
	setCurrentTagsData: Dispatch<SetStateAction<any[]>>,
	form: FormInstance,
	setDistributionCheckedList: Dispatch<SetStateAction<CheckboxValueType[]>>,
	setActiveDatesList: Dispatch<SetStateAction<ActiveDatesObj[]>>
) => {
	const {
		ref_id,
		audience_language,
		language,
		dub_language,
		sub_language,
		url,
		duration,
		gracenote_episodic_tms_id,
		metadata_language,
		title,
		clean_title,
		keywords,
		description_100,
		description_250,
		meta_id,
		tag_ids,
		size,
		type,
		distribution_data,
		description_full,
		file_name,
		video_height,
		video_width,
		zype_category_values,
		list_ids,
		updated_at,
		created_at,
	} = record;

	const durationObj = moment.duration(duration, "seconds");
	const seconds = moment.utc(durationObj.asMilliseconds()).format("HH:mm:ss");
	setDurationValue(seconds);
	setCurrentTagsData(tag_ids?.split(",")?.filter((tag: any) => tag?.trim()?.length));
	const distributionIds = distribution_data.map((data: any) => data.platform_id);
	const activeDatesList = distribution_data.map(
		(data: any): ActiveDatesObj => ({
			platformId: data.platform_id,
			platformName: data.platform_name,
			startDate: data.start_date,
			endDate: data.end_date,
		})
	);
	setDistributionCheckedList(distributionIds);
	setActiveDatesList(activeDatesList);
	form.setFieldsValue({
		"video_ref_id": ref_id,
		audience_language,
		"video_language": language,
		"dub_language": dub_language?.length ? dub_language : undefined,
		"sub_language": sub_language?.length ? sub_language : undefined,
		"video_tags": tag_ids
			?.split(",")
			?.filter((tag: any) => tag?.trim()?.length)
			?.map((tag: any) => tag),
		"video_url": url,
		"duration": seconds,
		"gracenote_episodic_tms_id": gracenote_episodic_tms_id
			? [gracenote_episodic_tms_id]
			: undefined,
		"meta_id": meta_id ?? undefined,
		"video_size": size,
		"video_type": type,
		"video_file_name": file_name ?? getFileNameWithURL(url),
		"video_lists": list_ids?.split(",")?.filter((list: any) => list?.trim()?.length) ?? [],
		video_height,
		video_width,
		zype_category_values,
		"video_updated_at": updated_at ?? created_at,
		"0_metaLanguage": metadata_language,
		"0_title": title,
		"0_clean_title": clean_title,
		"0_keywords": keywords,
		"0_description_100": description_100,
		"0_description_250": description_250,
		"0_description_full": description_full,
	});
};

export const tagsFilterOptions = (inputValue: string, option: any) => {
	return option.props.children.toLowerCase().indexOf(inputValue.toLowerCase()) !== -1;
};

export const videoListfilterOptions = (inputValue: string, option: any) => {
	return option.props.children.toLowerCase().indexOf(inputValue.toLowerCase()) !== -1;
};

export const seriesFilterOptions = (inputValue: string, option: any) => {
	return option.props.children.toLowerCase().indexOf(inputValue.toLowerCase()) !== -1;
};

export const distributionPlatformFilterOptions = (inputValue: string, option: any) => {
	return option.props.children.toLowerCase().indexOf(inputValue.toLowerCase()) !== -1;
};

export const metadataSelectHandler = (
	value: string,
	form: FormInstance,
	setMetadataEditId: (value: any) => void,
	setIsAddNewMetadata: (value: boolean) => void,
	setFormArray: (value: any) => void,
	allMetadata?: MetadataType[]
) => {
	setIsAddNewMetadata(true);
	setFormArray(["1"]);
	if (value === "add_new") {
		form.resetFields([
			"0_metaLanguage",
			"0_title",
			"0_clean_title",
			"0_description_100",
			"0_description_250",
		]);
		form.setFieldsValue({ "0_metaLanguage": form.getFieldValue("audience_language") });
		setMetadataEditId(undefined);
	} else {
		setMetadataEditId(value);
		allMetadata
			?.filter(metadata => metadata.ID === value)
			?.forEach(metadata => {
				const { CLEAN_TITLE, DESCRIPTION_100, DESCRIPTION_250, LANGUAGE, TITLE } = metadata;
				form.setFieldsValue({
					"0_metaLanguage": LANGUAGE,
					"0_title": TITLE,
					"0_clean_title": CLEAN_TITLE,
					"0_description_100": DESCRIPTION_100,
					"0_description_250": DESCRIPTION_250,
				});
			});
	}
};

export type ExportApiData = {
	series?: string[];
	distribution_platforms?: string[];
	audience_language?: string[];
	video_tags?: string[];
	video_not_tagged?: string[];
	episode_tags?: string[];
	episode_not_tagged?: string[];
	include_distribution_platforms?: boolean;
	ref_id?: string;
};

type ExportType =
	| "series"
	| "videos"
	| "assets"
	| "assets/captions"
	| "assets/cuepoints"
	| "assets/thumbnails"
	| "platforms/zype"
	| "platforms/wurl";

export const exportCSVHandler = async (
	data: ExportApiData,
	message: string,
	description: string,
	exportType: ExportType
) => {
	try {
		notification.info({
			message,
			description,
		});
		const email = getEmailFromSessionStorage();
		const response: any = await authAxios({
			method: "GET",
			url: `${process.env.REACT_APP_BACK_END_API
				}/snowflake/export/csv/${exportType}?data=${JSON.stringify({ ...data, email })}`,
			responseType: "blob",
		});

		if (response.status === 200) {
			const headers: any = response.headers;
			const contentDisposition = headers?.get("content-disposition");
			const filenameMatch = contentDisposition?.match(/filename="(.+)"/);
			const filename = filenameMatch ? filenameMatch[1] : "exports.csv";
			const href = URL.createObjectURL(response.data);
			const a = document.createElement("a");
			a.href = href;
			a.download = filename;
			document.body.appendChild(a);
			a.click();
			window.URL.revokeObjectURL(href);
			document.body.removeChild(a);
			notification.success({
				message: "Export",
				description: "CSV file has been downloaded. Please check in downloads.",
			});
		} else {
			notification.error({
				message: "Export",
				description: "CSV file couldn't be downloaded. Please try again.",
			});
			throw new Error("Export job could not be started.");
		}
	} catch (error) {
		console.error(error);
	}
};

export const exportCSVWithLambda = async (
	data: any,
	assetType: ExportType,
	name: string,
	message?: string
) => {
	try {
		const email = getEmailFromSessionStorage();
		const response: any = await authAxios({
			method: "GET",
			url: `${process.env.REACT_APP_BACK_END_API
				}/snowflake/export/csv/${assetType}?data=${JSON.stringify({ ...data, email, name })}`,
		});
		const notificationDesc =
			response.status === 200
				? "You'll be emailed a CSV file once it's ready."
				: "Export job couldn't be started. Please try again.";
		notification.success({
			message: message ?? "Export",
			description: notificationDesc,
		});
	} catch (error) {
		console.error("error in export csv with lambda", error);
		throw new Error("export csv error");
	}
};

export const searchHandler = (
	form: FormInstance,
	timeoutId: NodeJS.Timeout | undefined,
	setTimeoutId: Dispatch<SetStateAction<NodeJS.Timeout | undefined>>,
	setSearchQuery: Dispatch<SetStateAction<string>>
) => {
	try {
		clearTimeout(timeoutId);
		const newTimeoutId = setTimeout(async () => {
			const searchText = await form.getFieldValue("search");
			setSearchQuery(searchText?.trim());
		}, 1000);

		setTimeoutId(newTimeoutId);
	} catch (err) {
		console.error({ err });
	}
};

export interface SETTINGS_DATA {
	user_id: string;
	show_public_lists?: boolean;
	page_sizes: USER_PAGE_SIZE;
}

export const updateUserPageSizeSettings = async (settingsData: SETTINGS_DATA) => {
	try {
		const config: any = {
			method: "PUT",
			url: `${process.env.REACT_APP_BACK_END_API}/snowflake/settings/updatePageSizesData`,
			headers: {
				"Content-Type": "application/json",
			},
			data: JSON.stringify({ userId: settingsData.user_id, pageSizes: settingsData.page_sizes }),
		};
		authAxios(config);
	} catch (err) {
		console.error({ err });
	}
};

export const getUserData = async (
	email: string,
	setUserData: Dispatch<SetStateAction<USERDATA | undefined>>
) => {
	try {
		const config: any = {
			method: "GET",
			url: `${process.env.REACT_APP_BACK_END_API}/snowflake/user/${email}`,
			headers: {
				"Content-Type": "application/json",
			},
		};
		const response = await authAxios(config);
		const convertedObject = await makeKeyInLowerCaseHelper([response.data]);
		const pageSizes = JSON.parse(convertedObject[0]["page_sizes"]);
		setUserData({ ...convertedObject[0], page_sizes: pageSizes } as unknown as USERDATA);
		return convertedObject[0];
	} catch (err) {
		console.error({ err });
	}
};

export const getUserPageSizes = async (
	userId: string,
	setPageSizeUserSettings: Dispatch<SetStateAction<USER_PAGE_SIZE | undefined>>
) => {
	try {
		const config: any = {
			method: "GET",
			url: `${process.env.REACT_APP_BACK_END_API}/snowflake/settings/${userId}`,
			headers: {
				"Content-Type": "application/json",
			},
		};
		const response = await authAxios(config);
		const convertedObject = await makeKeyInLowerCaseHelper([response.data]);
		const pageSizes = JSON.parse(convertedObject[0]["page_sizes"]);
		setPageSizeUserSettings(pageSizes as unknown as USER_PAGE_SIZE);
		return convertedObject[0];
	} catch (err) {
		console.error({ err });
	}
};

export const updateHiddenEpisodesHandler = async (userId: string, showHiddenEpisodes: boolean) => {
	try {
		const config = {
			method: "PUT",
			url: `${process.env.REACT_APP_BACK_END_API}/snowflake/settings/updateHiddenEpisodes`,
			headers: {
				"Content-Type": "application/json",
			},
			data: JSON.stringify({ userId: userId, showHiddenEpisodes: !showHiddenEpisodes }),
		};
		authAxios(config);
	} catch (error) {
		console.error({ error });
	}
};

export const getCuePointsData = async (
	pagination: PaginationProps,
	setPagination: Dispatch<SetStateAction<PaginationProps>>,
	setCuePointssData: Dispatch<SetStateAction<any>>,
	setLoading: Dispatch<SetStateAction<boolean>>,
	fileterObj: any,
	episode_id?: string | null
) => {
	try {
		const config: any = {
			method: "GET",
			url: `${process.env.REACT_APP_BACK_END_API}/snowflake/cuepoints${episode_id ? "/" + episode_id : ""
				}?filter=${fileterObj ? JSON.stringify(fileterObj) : ""}&pagination=${pagination ? JSON.stringify(pagination) : ""
				}`,
			headers: {
				"Content-Type": "application/json",
			},
		};
		const response = await authAxios(config);
		setPagination({ ...pagination, total: response.data.totalCuepoints })
		const convertedObject = await makeKeyInLowerCaseHelper(response.data.rows);
		setCuePointssData(convertedObject);
		setLoading(false);
	} catch (error) {
		console.error({ error });
		setLoading(false);
	}
};

export const getThumbnailsData = async (
	setThumbnailsData: Dispatch<SetStateAction<any>>,
	setLoading: Dispatch<SetStateAction<boolean>>,
	pagination: PaginationProps,
	setPagination: Dispatch<SetStateAction<PaginationProps>>,
	thumbnailsFilterObj?: ThumbnailsFilterObj,
	episode_id?: string | null
) => {
	try {
		// if episode is available just pass the episode id, else pass other filteres and pagination
		const url = `${process.env.REACT_APP_BACK_END_API}/snowflake/thumbnails${episode_id
			? "/" + episode_id
			: `?pagination=${JSON.stringify(pagination)}&filter=${JSON.stringify(
				thumbnailsFilterObj ?? {}
			)}`
			}`;
		const config: any = {
			method: "GET",
			url,
			headers: {
				"Content-Type": "application/json",
			},
		};
		const response = await authAxios(config);
		const convertedObject = await makeKeyInLowerCaseHelper(response.data.rows);
		setPagination({ ...pagination, total: response.data.totalThumbnails })
		setThumbnailsData(convertedObject);
		setLoading(false);
	} catch (error) {
		console.error({ error });
	}
};

export const getBatchJobsData = async (
	setBatchJobsData: Dispatch<SetStateAction<any>>,
	setLoading: Dispatch<SetStateAction<boolean>>,
	pagination: PaginationProps
) => {
	try {
		// if episode is available just pass the episode id, else pass other filteres and pagination
		const url = `${process.env.REACT_APP_BACK_END_API
			}/snowflake/batch-jobs?pagination=${JSON.stringify(pagination)}`;
		const config: any = {
			method: "GET",
			url,
			headers: {
				"Content-Type": "application/json",
			},
		};
		const response = await authAxios(config);
		const convertedObject = await makeKeyInLowerCaseHelper(response.data.rows);
		setBatchJobsData(convertedObject);
		setLoading(false);
	} catch (error) {
		console.error({ error });
	}
};

export const getBucketKeyWithURL = (
	videoURL: string
): { Key?: string; Bucket?: string; type?: string } => {
	if (videoURL?.trim()?.includes("s3://")) {
		// eslint-disable-next-line
		const [_, url] = videoURL.split("//");
		if (url) {
			let videoType = "";
			const [bucket, ...key] = url.split("/");
			const fileKey = key.join("/");
			if (fileKey) {
				const tempArr = fileKey.split(".");
				videoType = tempArr[tempArr.length - 1];
			}
			if (videoType) {
				return { Key: fileKey, Bucket: bucket, type: videoType };
			}
		}
	} else if (videoURL?.trim()?.includes("http://") || videoURL?.trim()?.includes("https://")) {
		const decodedURL = decodeURIComponent(videoURL.replace(/\+/g, " "));
		// eslint-disable-next-line
		const [_, url] = decodedURL.split("//");
		const [bucket, fileKey] = url.split(".s3.amazonaws.com/");
		let videoType = "";
		if (fileKey) {
			const tempArr = fileKey.split(".");
			videoType = tempArr[tempArr.length - 1];
		}
		return { Key: fileKey, Bucket: bucket, type: videoType };
	}
	return {};
};

export const getSignedURL = async (videoURL: string) => {
	try {
		const { Key, Bucket } = getBucketKeyWithURL(videoURL);
		const config: any = {
			method: "GET",
			url: `${process.env.REACT_APP_BACK_END_API}/snowflake/video/signed-url?Key=${Key}&Bucket=${Bucket}`,
			headers: {
				"Content-Type": "application/json",
			},
		};
		const response = await authAxios(config);
		return response.data.url;
	} catch (error) {
		console.error({ error });
		return null;
	}
};

export const getUploadSignedURL = async (contentType: string, fileName: string) => {
	try {
		const config: any = {
			method: 'GET',
			url: `${process.env.REACT_APP_BACK_END_API}/snowflake/generate-presigned-url`,
			params: {
				contentType,
				fileName,
			},
			headers: {
				'Content-Type': 'application/json',
			},
		};
		const response = await authAxios(config);
		return response.data;
	} catch (error) {
		console.error({ error });
		return null;
	}
};


export const copyString = async (value: string) => {
	if (value && navigator) {
		await navigator.clipboard.writeText(value);
		notification.success({
			message: undefined,
			description: "Copied Successfully",
			placement: "bottomLeft",
		});
	}
};

export const getVideoDataByGracenoteId = async (gracenoteId: string) => {
	const config: any = {
		method: "GET",
		url: `${process.env.REACT_APP_BACK_END_API}/snowflake/video/gracenote/${gracenoteId}`,
		headers: {
			"Content-Type": "application/json",
		},
	};
	return authAxios(config);
};

// const updateData = (currentData: any, field: string, newData: any) => {};

export const updateDataWithGracenoteData = (form: FormInstance, gracenoteData: any) => {
	const { description, episode, original_air_date, season, title, videos } = gracenoteData;
	const updatedGracenoteData: any = {
		episode,
		season,
		original_air_date,
		"0_title": title,
		"0_description_full": description,
		"video_url": videos?.[0]?.url ?? "",
	};
	const formFields = [
		"season",
		"episode",
		"original_air_date",
		"0_title",
		"0_description_full",
		"video_url",
	];
	const currentData = form.getFieldsValue(formFields);
	const updatedFormData: any = {};
	formFields.forEach((field: string) => {
		updatedFormData[field] =
			currentData[field] && String(currentData[field]).length
				? currentData[field]
				: field === "original_air_date"
					? dayjs(updatedGracenoteData[field])
					: updatedGracenoteData[field];
	});
	form.setFieldsValue(updatedFormData);
};