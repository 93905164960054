import { Checkbox, Form, notification } from "antd";
import { useOutletContext } from "react-router-dom";
import { exportCSVWithLambda } from "../../../utils";
import { useState } from "react";
import { GetProp } from "antd/lib";

const useAssetsExportHook = () => {
	const [form] = Form.useForm();
	const { languagesList, loading, distributionPlatformData, seriesList, user, videoLists }: any =
		useOutletContext();
	const [isLoading, setIsLoading] = useState<boolean>();
	const [assetTypes, setAssetTypes] = useState<GetProp<typeof Checkbox.Group, "value">>([
		"captions",
		"cue-points",
		"thumbnails",
	]);

	const exportAssetsCSV = async () => {
		try {
			if (assetTypes.length === 0)
				return notification.error({
					message: "Incorrect data",
					description: "Please select atleast one asset type",
					// placement: "bottom",
				});
			setIsLoading(true);
			const values = await form.getFieldsValue();
			const data = {
				...values,
				video_lists: values.video_lists_filter,
				assetTypes,
				include_distribution_platforms: true,
			};
			await exportCSVWithLambda(data, "assets", user.name, "Assets Export");
			setIsLoading(false);
		} catch (err) {
			setIsLoading(false);
			notification.error({
				message: "Export",
				description: "Export job could not be started. Please try again.",
			});
			console.error(err);
		}
	};
	return {
		form,
		languagesList,
		loading,
		exportAssetsCSV,
		distributionPlatformData,
		isLoading,
		seriesList,
		languages: languagesList,
		videoLists,
		assetTypes,
		setAssetTypes,
	};
};

export default useAssetsExportHook;
