import React from "react";
import { useThumbnailsHook } from "./hooks/useThumbnailsHook";
import { Button, Col, Row, MenuProps, Space, Dropdown } from "antd";
import { CloseCircleOutlined, DownOutlined } from "@ant-design/icons";
import {
	ComponentTitle,
	PageTitleLastCol,
	SearchForm,
	SelectedCountTag,
} from "../custom-components";
import { CustomTable } from "../series/StyledComponent";
import useScreenSizeHook from "../utils/useScreenSizeHook";
import ThumbnailFormModal from "./modals/ThumbnailFormModal";
import { ClearFilterButton } from "../metadata";
import ThumbnailFilterModal from "./modals/ThumbnailFilterModal";
import AddItemsToDistributionPlatformModal from "../custom-components/AddItemsToDistributionPlatformModal";
import BulkItemsTaggingModal from "../custom-components/BulkItemsTaggingModal";

const ThumbnailsComponent = () => {
	const { height } = useScreenSizeHook();
	const {
		thumbnailsData,
		pagination,
		handleTableChange,
		isLoading,
		columnThumbnails,
		isAddThumbnailFormOpen,
		form,
		editThumbnail,
		handleCancel,
		thumbnailEditId,
		editHelper,
		distributionPlatformData,
		distributionCheckedList,
		setDistributionCheckedList,
		distributionChange,
		onDistributionCheckAllChange,
		checkAll,
		indeterminate,
		tagsList,
		handleAddCustomTag,
		exporThumbnailsCSV,
		searchData,
		isThumbnailsFilterModalOpen,
		thumbnailsFilterObj,
		setIsThumbnailsfilterModalOpen,
		clearFilter,
		getFilteredThumbnail,
		seriesList,
		filterModalCancelHandler,
		isAddToDistributorsModalOpen,
		setIsAddToDistributorsModalOpen,
		selectedThumbnailsData,
		clearThumbnailsSelection,
		getDataHandler: getThumbnailsDataHandler,
		isBulkTaggingModalOpen,
		setIsBulkTaggingModalOpen,
		tagsData,
		videoLists,
	} = useThumbnailsHook();

	const bulkActionItems: MenuProps["items"] = [
		{
			label: <span>Distribution</span>,
			key: "distribution-platform",
			onClick: () => setIsAddToDistributorsModalOpen(true),
		},
		{
			label: <span>Tags</span>,
			key: "tags",
			onClick: () => setIsBulkTaggingModalOpen(true),
		},
	];

	return (
		<>
			<Row justify="space-between" align="middle">
				<Col>
					<ComponentTitle>Thumbnails</ComponentTitle>
				</Col>
				<Col>
					{selectedThumbnailsData.length > 0 && (
						<Space>
							<SelectedCountTag color="green">
								<span>{selectedThumbnailsData.length} Selected</span>
								<span title="Clear Selection" onClick={clearThumbnailsSelection} id="close-icon">
									X
								</span>
							</SelectedCountTag>
							<Dropdown menu={{ items: bulkActionItems }} trigger={["click"]}>
								<Button>
									Actions <DownOutlined />
								</Button>
							</Dropdown>
						</Space>
					)}
				</Col>
				<PageTitleLastCol>
					<Button onClick={exporThumbnailsCSV}>Export</Button>
					&nbsp;
					<Button onClick={() => setIsThumbnailsfilterModalOpen(true)}>Filter</Button>
					{thumbnailsFilterObj && (
						<ClearFilterButton
							onClick={clearFilter}
							title="Remove Filter"
							icon={<CloseCircleOutlined />}
						/>
					)}
					<SearchForm form={form} searchData={searchData} placeholder="Search by Ref ID" />
				</PageTitleLastCol>
			</Row>
			<CustomTable
				onChange={handleTableChange}
				columns={columnThumbnails}
				dataSource={thumbnailsData}
				scroll={{ y: height - 265 }}
				loading={isLoading}
				pagination={pagination}
				onRow={(record: any) => {
					return {
						onClick: () => {
							editHelper(record);
						},
					};
				}}
			/>
			<ThumbnailFormModal
				handleAddCustomTag={handleAddCustomTag}
				thumbnailEditId={thumbnailEditId}
				isOpen={isAddThumbnailFormOpen}
				saveEditThumbnailData={editThumbnail}
				handleCancel={handleCancel}
				isLoading={isLoading}
				form={form}
				distributionPlatformData={distributionPlatformData}
				distributionCheckedList={distributionCheckedList}
				distributionChange={distributionChange}
				onDistributionCheckAllChange={onDistributionCheckAllChange}
				checkAll={checkAll}
				indeterminate={indeterminate}
				tagsList={tagsList}
			/>
			<ThumbnailFilterModal
				thumbnailFilterModalOpen={isThumbnailsFilterModalOpen}
				form={form}
				seriesData={seriesList}
				getFilteredThumbnail={getFilteredThumbnail}
				tagsData={tagsList}
				distributionPlatformData={distributionPlatformData}
				thumbnailFilterModalCancelHandler={filterModalCancelHandler}
				videoLists={videoLists}
			/>
			<AddItemsToDistributionPlatformModal
				setDistributionCheckedList={setDistributionCheckedList}
				isAddToDistributorsModalOpen={isAddToDistributorsModalOpen}
				setIsAddToDistributorsModalOpen={setIsAddToDistributorsModalOpen}
				distributionPlatformData={distributionPlatformData}
				distributionCheckedList={distributionCheckedList}
				distributionChange={distributionChange}
				onDistributionCheckAllChange={onDistributionCheckAllChange}
				checkAll={checkAll}
				indeterminate={indeterminate}
				selectedItemData={selectedThumbnailsData}
				selectedItemType="Thumbnail"
				getItemsDataHandler={getThumbnailsDataHandler}
			/>
			<BulkItemsTaggingModal
				isBulkTaggingModalOpen={isBulkTaggingModalOpen}
				setIsBulkTaggingModalOpen={setIsBulkTaggingModalOpen}
				tagsList={tagsData}
				selectedItemData={selectedThumbnailsData}
				itemType="thumbnail"
				getItemsDataHandler={getThumbnailsDataHandler}
			/>
		</>
	);
};

export default ThumbnailsComponent;
