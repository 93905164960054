import React, { Dispatch, SetStateAction, useState } from "react";
import { Button, Col, Row, Spin, message } from "antd";
import { useOutletContext } from "react-router-dom";
import { CheckboxChangeEvent } from "antd/es/checkbox";
import { Checkbox } from "antd/lib";

import {
	VideoListItem,
	getVideoLists,
	saveUpdateVideoListHandler,
} from "../../../video-lists/utils";
import { BulkAddDataModal, CreateNewListCol, VideoSearchItem } from "../../../custom-components";
import { authAxios } from "../../../utils/session_utils";
import { SearchOutlined } from "@ant-design/icons";
import { SelectedVideoData } from "../../videos";

interface AddVideosToListModalInterface {
	videoLists: VideoListItem[];
	selectedVideoData: SelectedVideoData[];
	isAddToListModalOpen: boolean;
	setIsAddToListModalOpen: Dispatch<SetStateAction<boolean>>;
	getVideosDataHandler: () => void;
}

const AddVideosToListModal: React.FC<AddVideosToListModalInterface> = ({
	selectedVideoData,
	videoLists,
	isAddToListModalOpen,
	setIsAddToListModalOpen,
	getVideosDataHandler,
}) => {
	const { user, setVideoLists }: any = useOutletContext();
	const [isLoading, setIsLoading] = useState<boolean>(false);
	const [searchText, setSearchText] = useState<string>("");
	const [videoListCheckedList, setVideoListCheckedList] = useState<string[]>([]);
	const videoListPlainOptions = videoLists.map(list => ({
		value: list.id,
		label: list.name,
	}));
	const videoListIds = videoLists.map(list => list.id);
	const checkAll = videoListPlainOptions.length === videoListCheckedList.length;
	const indeterminate =
		videoListCheckedList.length > 0 && videoListCheckedList.length < videoListPlainOptions.length;

	const videoListChange = (list: string[]) => {
		setVideoListCheckedList(list);
	};

	const onVideoListCheckAllChange = (e: CheckboxChangeEvent) => {
		setVideoListCheckedList(e.target.checked ? videoListIds : []);
	};

	const createNewVideoList = async () => {
		try {
			setIsLoading(true);
			const data = {
				name: searchText,
				is_private: true,
				user_id: user.user_id,
			};
			await saveUpdateVideoListHandler(data);
			await getVideoLists(setVideoLists, null, {}, { showAllLists: true });
			setIsLoading(false);
		} catch (error) {
			setIsLoading(false);
			message.error("Could not save video list");
			console.error("error", error);
		}
	};

	const addBulkVideosToList = async () => {
		try {
			setIsLoading(true);
			const data = {
				video_ids: selectedVideoData.map(videoData => videoData.id),
				list_ids: videoListCheckedList,
			};
			const config = {
				method: "POST",
				url: `${process.env.REACT_APP_BACK_END_API}/snowflake/bulk/videos-to-list`,
				data: JSON.stringify(data),
				headers: { "Content-Type": "application/json" },
			};
			const response = await authAxios(config);
			message.success(response.data.message);
			await getVideosDataHandler();
			setIsLoading(false);
			closeHandler();
		} catch (error) {
			setIsLoading(false);
			message.error("Could not save video list");
			console.error("error addBulkVideosToList", error);
		}
	};

	const removeBulkVideosFromList = async () => {
		try {
			setIsLoading(true);
			const data = {
				video_ids: selectedVideoData.map(videoData => videoData.id),
				list_ids: videoListCheckedList,
			};
			const config = {
				method: "POST",
				url: `${process.env.REACT_APP_BACK_END_API}/snowflake/bulk/remove-videos-from-list`,
				data: JSON.stringify(data),
				headers: { "Content-Type": "application/json" },
			};
			const response = await authAxios(config);
			message.success(response.data.message);
			await getVideosDataHandler();
			setIsLoading(false);
			closeHandler();
		} catch (error) {
			setIsLoading(false);
			message.error("Selected videos could not be removed from selected lists.");
			console.error("error removeBulkVideosFromList", error);
		}
	};

	const closeHandler = () => {
		setIsAddToListModalOpen(false);
		setVideoListCheckedList([]);
	};

	return (
		<BulkAddDataModal
			open={isAddToListModalOpen}
			title="Add or Remove from List(s):"
			centered
			onCancel={closeHandler}
			footer={
				<>
					<Button
						onClick={removeBulkVideosFromList}
						disabled={!videoListCheckedList?.length || isLoading}
						danger
					>
						Remove
					</Button>
					<Button
						onClick={addBulkVideosToList}
						disabled={!videoListCheckedList?.length || isLoading}
						type="primary"
					>
						Add
					</Button>
				</>
			}
		>
			<Spin spinning={isLoading}>
				<VideoSearchItem
					placeholder="Search Video List or Create One"
					onChange={e => setSearchText(e.target.value)}
					suffix={<SearchOutlined />}
				/>
				{!searchText && (
					<Checkbox
						indeterminate={indeterminate}
						onChange={onVideoListCheckAllChange}
						checked={checkAll}
					>
						{checkAll ? "De-Select All" : "Select All"}
					</Checkbox>
				)}
				<Checkbox.Group onChange={videoListChange} value={videoListCheckedList}>
					<Row gutter={[0, 4]}>
						{videoLists
							.filter(list =>
								searchText ? list.name.toLowerCase().includes(searchText?.toLowerCase()) : true
							)
							.map((list: any) => {
								return (
									<Col span={24} key={list.key}>
										<Checkbox value={list.id} key={list.key}>
											<b>{list.name}</b>
										</Checkbox>
									</Col>
								);
							})}

						{searchText?.trim()?.length ? (
							<CreateNewListCol onClick={createNewVideoList} span={24}>
								<span>
									<b>&quot;{searchText}&quot;</b>
								</span>{" "}
								&nbsp;
								<span>(Create new)</span>
							</CreateNewListCol>
						) : null}
					</Row>
				</Checkbox.Group>
			</Spin>
		</BulkAddDataModal>
	);
};

export default AddVideosToListModal;
