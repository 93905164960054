import { Form } from "antd";
import { useOutletContext } from "react-router-dom";
import { exportCSVWithLambda } from "../../../utils";

const useVideoExportHook = () => {
	const { languagesList, tagsList, seriesList, loading, distributionPlatformData }: any =
		useOutletContext();
	const [form] = Form.useForm();

	const exportCSV = async () => {
		try {
			const values = await form.validateFields();
			const {
				series,
				audience_language,
				video_tags,
				episode_tags,
				include_distribution_platforms,
				episode_not_tagged,
				video_not_tagged,
				distribution_platforms,
				type_filter,
			} = values;
			const data = {
				series: series ?? [],
				audience_language: audience_language ?? [],
				video_tags: video_tags ?? [],
				episode_tags: episode_tags ?? [],
				video_not_tagged: video_not_tagged ?? [],
				episode_not_tagged: episode_not_tagged ?? [],
				distribution_platforms,
				include_distribution_platforms: Boolean(include_distribution_platforms?.length),
				video_type: type_filter,
			};
			await exportCSVWithLambda(data, "videos", "Videos Export");
		} catch (err) {
			console.error(err);
		}
	};
	return {
		languagesList,
		seriesList,
		tagsList,
		loading,
		form,
		exportCSV,
		distributionPlatformData,
	};
};

export default useVideoExportHook;
