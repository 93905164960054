import React, { useState, useEffect } from "react";
import { useOutletContext } from "react-router-dom";
import { Form, PaginationProps, message, Popconfirm, notification } from "antd";
import { EditOutlined, DeleteOutlined } from "@ant-design/icons";
import { AlternateTitleData, AlternateTitleFilter } from "./alternate.title";
import { initialPaginationObj } from "../../episode/hooks/useEpisodeHook";
import {
	SETTINGS_DATA,
	getTotalDataCount,
	searchHandler,
	updateUserPageSizeSettings,
} from "../../utils";
import { SortObj } from "../../videos/videos";
import { deleteAlternateTitle, getAlternateTitlesData, saveUpdateAlternateTitle } from "./utils";
import { CustomBtn } from "../../series/StyledComponent";
import { TextWithCopyButton } from "../../cue-points/hooks/cuePointColumns";
import { authAxios, getEmailFromSessionStorage } from "../../utils/session_utils";
import { orderObj } from "../../videos/hooks/useVideosHooks";
import { refIdColumn } from "../../utils/table-columns";

const useAlternateTitles = () => {
	const [form] = Form.useForm();
	const { user, setUser }: any = useOutletContext();
	const [pagination, setPagination] = useState<PaginationProps>({
		...initialPaginationObj,
		pageSize: user.page_sizes?.alternate_titles ?? 10,
	});
	const [isLoading, setIsLoading] = useState<boolean>(false);
	const [alternateTitleData, setAlternateTitleData] = useState<AlternateTitleData[]>([]);
	const [alternateTitleEditId, setAlternateTitleEditId] = useState<string>();
	const [isAlternateTitleFormOpen, setIsAlternateTitleFormOpen] = useState<boolean>(false);
	const [titleSortObj, setTitleoSortObj] = useState<SortObj>({ column: "ref_id", order: "asc" });
	const [titleFilterObj, setTitleFilterObj] = useState<AlternateTitleFilter>();
	const [searchQuery, setSearchQuery] = useState<string>("");
	const [timeoutId, setTimeoutId] = useState<NodeJS.Timeout>();
	const [searchWithBlankRefId, setSearchWithBlankRefId] = useState<boolean>(false);
	const [titleFilterModalOpen, setTitleFilterModalOpen] = useState<boolean>(false);
	const [isFilteredAlternateTitleData, setIsFilteredAlternateTitleData] = useState<boolean>(false);
	useEffect(() => {
		getTotalDataCount("alternate-title", pagination, setPagination, titleFilterObj, searchQuery);
	}, [searchQuery, titleFilterObj]);

	useEffect(() => {
		setIsLoading(true);
		getDataHandler();
	}, [pagination.current, pagination.pageSize, titleSortObj, titleFilterObj, searchQuery]);

	const getDataHandler = async () => {
		await getAlternateTitlesData(
			setAlternateTitleData,
			setIsLoading,
			pagination,
			titleSortObj,
			searchQuery,
			titleFilterObj
		);
	};

	const handleTableChange = (pagination: PaginationProps, _: any, sorter: any, { action }: any) => {
		switch (action) {
			case "paginate": {
				if (pagination.pageSize !== user.page_sizes.alternate_titles) {
					const updatedSettingsData: SETTINGS_DATA = {
						user_id: user.user_id,
						page_sizes: { ...user.page_sizes, alternate_titles: pagination.pageSize },
					};
					setUser({ ...user, ...updatedSettingsData });
					updateUserPageSizeSettings(updatedSettingsData);
				}
				setPagination(pagination);
				break;
			}
			case "sort": {
				if (!sorter.order) return;
				const titleSortObj = { column: sorter.field, order: orderObj[sorter.order] };
				setTitleoSortObj(titleSortObj);
				break;
			}
		}
	};

	const saveUpdateDataHandler = async () => {
		try {
			const values = await form.validateFields();
			setIsLoading(true);
			const response = await saveUpdateAlternateTitle(values, alternateTitleEditId);
			message.success(
				response.status === 201 ? "Data created successfully" : "Data updated successfully"
			);
			await getDataHandler();
			setIsAlternateTitleFormOpen(false);
			setAlternateTitleEditId(undefined);
			form.resetFields();
		} catch (error) {
			console.error(error);
			setIsLoading(false);
		}
	};

	const editHandler = (record: AlternateTitleData) => {
		form.setFieldsValue({
			alternate_title_ref_id: record.ref_id,
			alternate_title: record.title,
			notes: record.notes,
		});
		setAlternateTitleEditId(record.id);
		setIsAlternateTitleFormOpen(true);
	};

	const deleteDataHandler = async (record: AlternateTitleData) => {
		try {
			setIsLoading(true);
			if (record.id) await deleteAlternateTitle(record.id);
			getDataHandler();
		} catch (err) {
			console.error("eror", err);
			setIsLoading(false);
		}
	};

	const handleCancel = () => {
		form.resetFields();
		setIsAlternateTitleFormOpen(false);
		setAlternateTitleEditId(undefined);
	};

	const searchData = async () => {
		try {
			setPagination({ ...initialPaginationObj, pageSize: pagination.pageSize });
			searchHandler(form, timeoutId, setTimeoutId, setSearchQuery);
		} catch (err) {
			console.error({ err });
		}
	};

	const filterModalCancelHandler = () => {
		setTitleFilterModalOpen(false);
	};

	const getFileteredAlternateData = () => {
		setTitleFilterObj({ searchWithBlankRefId });
		setTitleFilterModalOpen(false);
		setIsFilteredAlternateTitleData(true);
	};

	const clearFilter = () => {
		setTitleFilterObj({ searchWithBlankRefId: false });
		setIsFilteredAlternateTitleData(false);
		setSearchWithBlankRefId(false);
	};

	const syncData = async () => {
		try {
			const email = getEmailFromSessionStorage();
			const config = {
				method: "GET",
				url: `${
					process.env.REACT_APP_BACK_END_API
				}/snowflake/alternate-title/sync-data?data=${JSON.stringify({ email, name: user.name })}`,
				headers: {},
			};
			await authAxios(config);
			notification.info({
				message: "Data Syncing Started",
				description:
					"Data syncing has been started successfully. You will be sent a mail once this job is done.",
			});
		} catch (error) {
			notification.error({
				message: "Data Syncing Failed",
				description: "Data syncing has been failed. Please try again later.",
			});
			console.error({ error });
		}
	};

	const alternateTitleColumns = [
		{
			...refIdColumn,
			width: "90px",
		},
		{
			title: "Alternate Title",
			dataIndex: "title",
			key: "title",
			width: "120px",
			render: (value: string) => <TextWithCopyButton value={value} />,
		},
		{
			title: "Notes",
			dataIndex: "notes",
			key: "notes",
			width: "220px",
			render: (value: string) => <TextWithCopyButton value={value} />,
		},
		{
			title: "Actions",
			dataIndex: "action",
			key: "action",
			width: "50px",
			render: (value: string, record: any) => {
				return (
					<>
						<CustomBtn
							icon={<EditOutlined />}
							type={"link"}
							onClick={e => {
								e.stopPropagation();
								editHandler(record);
							}}
						/>
						<CustomBtn
							onClick={e => e.stopPropagation()}
							icon={
								<Popconfirm
									okButtonProps={{ danger: true }}
									title="Delete Thumbnail?"
									description="Are you sure you want to delete this thumbnail?"
									cancelText="Cancel"
									okText="Delete"
									onConfirm={() => deleteDataHandler(record)}
								>
									<DeleteOutlined title="Delete" style={{ color: "#D10000" }} />
								</Popconfirm>
							}
							type={"link"}
						/>
					</>
				);
			},
		},
	];
	return {
		isLoading,
		alternateTitleData,
		isAlternateTitleFormOpen,
		handleTableChange,
		saveUpdateDataHandler,
		searchData,
		alternateTitleColumns,
		editHandler,
		setIsAlternateTitleFormOpen,
		form,
		alternateTitleEditId,
		handleCancel,
		pagination,
		searchWithBlankRefId,
		setSearchWithBlankRefId,
		titleFilterModalOpen,
		filterModalCancelHandler,
		setTitleFilterModalOpen,
		getFileteredAlternateData,
		isFilteredAlternateTitleData,
		clearFilter,
		syncData,
	};
};

export default useAlternateTitles;
