import React from "react";
import { Form, Input, FormInstance, Spin } from "antd";
import { CustomModal } from "../../utils/StyledComponents";

interface Props {
	isOpen: boolean;
	saveEditData: () => void;
	form: FormInstance;
	isLoading: boolean;
	handleCancel: () => void;
	editId?: string;
}

const AlternateTitleForm: React.FC<Props> = ({
	isOpen,
	saveEditData,
	form,
	isLoading,
	handleCancel,
	editId,
}) => {
	return (
		<CustomModal
			centered
			width={600}
			open={isOpen}
			maskClosable={false}
			title={editId ? "Edit Alternate Title" : "Add Alternate Title"}
			okText={editId ? "Update" : "Add"}
			onOk={saveEditData}
			onCancel={handleCancel}
			okButtonProps={{ disabled: isLoading }}
			cancelButtonProps={{ disabled: isLoading }}
		>
			<Spin spinning={isLoading}>
				<Form form={form} requiredMark={false} layout="vertical">
					<Form.Item label="Ref ID" name="alternate_title_ref_id">
						<Input placeholder="Ref ID" />
					</Form.Item>
					<Form.Item
						label="Alternate Title"
						name="alternate_title"
						rules={[{ required: true, message: "This is required field" }]}
					>
						<Input placeholder="Alternate Title" />
					</Form.Item>
					<Form.Item label="Notes" name="notes">
						<Input.TextArea placeholder="This containes notes for alternate tiltle...." />
					</Form.Item>
					{/* <Typography.Title level={3}>Distribution Platforms</Typography.Title>
					<Checkbox
						indeterminate={indeterminate}
						onChange={onDistributionCheckAllChange}
						checked={checkAll}
						style={{ marginBottom: "4px" }}
					>
						{checkAll ? "De-Select All" : "Select All"}
					</Checkbox>
					<Checkbox.Group onChange={distributionChange} value={distributionCheckedList}>
						<Row id="cuepoint_distribution" gutter={[16, 4]} style={{ width: "70% " }}>
							{distributionPlatformData.map((platform: any) => {
								return (
									<Col span={8} key={platform.key}>
										<Checkbox value={platform.id} key={platform.key}>
											{platform.name}
										</Checkbox>
									</Col>
								);
							})}
						</Row>
					</Checkbox.Group> */}
				</Form>
			</Spin>
		</CustomModal>
	);
};

export default AlternateTitleForm;
