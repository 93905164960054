import React, { FC } from "react";
import { Button, Card, Col, Row, Typography, Upload } from "antd";
import styled from "@emotion/styled";
import { UploadOutlined } from "@ant-design/icons";

import useVideosImportHook from "./hooks/useVideosImportHook";
import { TaskRow } from "../../zype/styled-components";
import { ComponentTitle, StledSpin } from "../../custom-components";
import { TabComponentProps } from "../../utils/common.interface";
import { HomeTabKeyEnum } from "../../utils";
// import { checkIfValidEmail } from "../utils";

const { Dragger } = Upload;

export const StyledDragger = styled(Dragger)`
	padding: 10px;
	& .ant-upload-drag {
		padding: 10px;
	}
`;

export const uploaderContent: any = {
	[HomeTabKeyEnum.VIDEOS_IMPORT]: {
		title: "Import Videos",
		btnText: "Import Videos Data.",
		apiEndpoint: "/import/videos",
		description: "Please select a CSV file to import data into database",
	},
	[HomeTabKeyEnum.ASSETS_IMPORT]: {
		title: "Assets Videos",
		btnText: "Import Assets Data.",
		apiEndpoint: "/import/assets",
		description: "Please select a CSV file to import data into database",
	},
	[HomeTabKeyEnum.EPISODE_SCORING]: {
		title: "Episode Scoring",
		btnText: "Score Episodes",
		apiEndpoint: "/scoring/episodes",
		description:
			"Please select a CSV file containing episode Start Time, End Time, Ref-ID and HOV.",
	},
};

const FileUploaderPageContent: FC<TabComponentProps> = () => {
	const { pathname, loading, handleUpload, uploadProps } = useVideosImportHook();
	return (
		<StledSpin spinning={loading}>
			<Row justify="center">
				<Col>
					<ComponentTitle>{uploaderContent[pathname]["title"]}</ComponentTitle>
				</Col>
			</Row>
			<TaskRow>
				<Card
					actions={[
						<Button key="import-btn" type="primary" onClick={handleUpload}>
							{uploaderContent[pathname]["btnText"]}
						</Button>,
					]}
				>
					<StyledDragger {...uploadProps}>
						<Typography.Paragraph>{uploaderContent[pathname]["description"]}</Typography.Paragraph>
						<Button icon={<UploadOutlined />}>Select a File</Button>
					</StyledDragger>
					{/* <Form form={form}>
						<Form.Item name={"email"} rules={[{ validator: (rule, value) => checkIfValidEmail(rule, value) }]}>
							<Input placeholder="Email to send the import status" />
						</Form.Item>
					</Form> */}
				</Card>
			</TaskRow>
		</StledSpin>
	);
};

export default FileUploaderPageContent;
