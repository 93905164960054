import { Col, Form, FormInstance, Input, Row, Select } from "antd";
import React, { FC } from "react";
import { StyledFilterModal } from "../../episode/modals/EpisodeFilterModal";
import { LanguageData } from "./AddVideosMetaDataForm";
import {
	tagsFilterOptions,
	getDigitsOnly,
	seriesFilterOptions,
	distributionPlatformFilterOptions,
	videoListfilterOptions,
} from "../../utils";
import { TagOption } from "../../tags/hooks/utils";
import { CloseIcon } from "../../custom-components";

const { Item } = Form;

interface VideoFilterModal {
	videoFilterModalOpen: boolean;
	form: FormInstance;
	getFileteredVideoData: () => void;
	filterModalCancelHandler: () => void;
	seriesData: any[];
	languageData: any[];
	tagsData: any[];
	distributionPlatformData: any[];
	videoLists: any[];
}

const VideoFilterModal: FC<VideoFilterModal> = ({
	videoFilterModalOpen,
	form,
	getFileteredVideoData,
	filterModalCancelHandler,
	seriesData,
	languageData,
	tagsData,
	distributionPlatformData,
	videoLists,
}) => {
	return (
		<StyledFilterModal
			open={videoFilterModalOpen}
			okText="Filter"
			cancelText="Close"
			onOk={getFileteredVideoData}
			title="Filter Videos By"
			maskClosable={false}
			centered
			onCancel={filterModalCancelHandler}
		>
			<Form form={form} layout="vertical">
				<Row gutter={[8, 8]}>
					<Col xs={24} sm={12}>
						<Item name="series_filter" label="Series">
							<Select
								filterOption={seriesFilterOptions}
								showSearch
								mode="multiple"
								placeholder="Series"
								allowClear={{ clearIcon: <CloseIcon /> }}
							>
								{seriesData?.map((series: any) => (
									<Select.Option value={series.id} key={series.id}>
										{series.titles}
									</Select.Option>
								))}
							</Select>
						</Item>
					</Col>
					<Col xs={24} sm={12}>
						<Item name="audience_language_filter" label="Audience Language">
							<Select placeholder="Native" allowClear={{ clearIcon: <CloseIcon /> }}>
								{languageData?.map((language: LanguageData) => (
									<Select.Option value={language.NAME} key={language.ID}>
										{language.NAME}
									</Select.Option>
								))}
							</Select>
						</Item>
					</Col>
				</Row>
				<Item label="Distribution Platforms" name="distribution_platforms_filter">
					<Select
						mode="multiple"
						allowClear={{ clearIcon: <CloseIcon /> }}
						placeholder="Distribution Platforms"
						filterOption={distributionPlatformFilterOptions}
					>
						{distributionPlatformData?.map((platform: any) => (
							<Select.Option value={platform.id} key={platform.id}>
								{platform.name}
							</Select.Option>
						))}
					</Select>
				</Item>
				<Item label="Not On Distribution Platforms" name="not_distribution_platforms_filter">
					<Select
						mode="multiple"
						allowClear={{ clearIcon: <CloseIcon /> }}
						placeholder="Isn't distributed on"
						filterOption={distributionPlatformFilterOptions}
					>
						{distributionPlatformData?.map((platform: any) => (
							<Select.Option value={platform.id} key={platform.id}>
								{platform.name}
							</Select.Option>
						))}
					</Select>
				</Item>
				<Item name="video_tags_filter" label="Has Tags">
					<Select
						allowClear={{ clearIcon: <CloseIcon /> }}
						mode="multiple"
						placeholder="Have video tags"
						filterOption={tagsFilterOptions}
					>
						{tagsData
							.filter((tag: TagOption) => tag.related_to === "video")
							.map((tag: TagOption) => (
								<Select.Option value={tag.id} key={tag.id}>
									{tag.title}
								</Select.Option>
							))}
					</Select>
				</Item>
				<Item name="video_not_tagged" label="Not Tagged">
					<Select
						allowClear={{ clearIcon: <CloseIcon /> }}
						mode="multiple"
						placeholder="Don't have video tags"
						filterOption={tagsFilterOptions}
					>
						{tagsData
							.filter((tag: TagOption) => tag.related_to === "video")
							.map((tag: TagOption) => (
								<Select.Option value={tag.id} key={tag.id}>
									{tag.title}
								</Select.Option>
							))}
					</Select>
				</Item>
				<Row gutter={[8, 8]}>
					<Col xs={24} sm={12}>
						<Item name="ref_id_filter" label="Ref ID">
							<Input placeholder="Ref ID" />
						</Item>
					</Col>
					<Col xs={24} sm={12}>
						<Item name="season_filter" label="Season">
							<Input
								placeholder="Season"
								onChange={e => form.setFieldValue("season_filter", getDigitsOnly(e.target.value))}
							/>
						</Item>
					</Col>
				</Row>
				<Item label="Video Lists" name="video_list_ids">
					<Select
						placeholder="Select Video List"
						allowClear
						mode="multiple"
						filterOption={videoListfilterOptions}
					>
						{videoLists.map((videoList: any) => {
							return (
								<Select.Option key={videoList.id} value={videoList.id}>
									{videoList.name}
								</Select.Option>
							);
						})}
					</Select>
				</Item>
				<Item name="type_filter" label="Type">
					<Input placeholder="Video Type (Works case in-sensitive)" />
				</Item>
			</Form>
		</StyledFilterModal>
	);
};

export default VideoFilterModal;
