import React, { Fragment, useEffect, useState } from "react";
import { Button, Form, Popconfirm, Tag, Tooltip, message, notification } from "antd";
import { ColumnsType } from "antd/es/table";
import { DeleteOutlined, EditFilled, EditOutlined, PlayCircleOutlined } from "@ant-design/icons";
import moment from "moment";
import { v4 as uuidV4 } from "uuid";
import { useNavigate, useOutletContext, useParams, useSearchParams } from "react-router-dom";

import {
	HomeTabKeyEnum,
	SETTINGS_DATA,
	exportCSVWithLambda,
	getSignedURL,
	getTotalDataCount,
	getVideoById,
	getVideosData,
	handleSelectedRowStyling,
	metadataSelectHandler,
	resetVideoFormFields,
	saveUpdateVideoData,
	searchHandler,
	updateHiddenEpisodesHandler,
	updateUserPageSizeSettings,
	videoEditHelper,
} from "../../utils";
import { CustomBtn } from "../../series/StyledComponent";
import { PaginationProps } from "antd/lib/pagination";
import { authAxios } from "../../utils/session_utils";
import { initialPaginationObj } from "../../episode/hooks/useEpisodeHook";
import styled from "@emotion/styled";
import useDistributionData from "../../distribution_partner/hooks/useDistributionData";
import { TagOption, saveTagsData } from "../../tags/hooks/utils";
import { TextWithCopyButton } from "../../cue-points/hooks/cuePointColumns";
import {
	ActiveDatesObj,
	MetadataType,
	SelectedVideoData,
	SortObj,
	VideoFilterObj,
	VideoRecordType,
	VideosInterFace,
} from "../videos";
import getBulkSelectColumns from "../../custom-components/BulkSelectColumn";
import { lastModifiedCol, refIdColumn } from "../../utils/table-columns";

export const PlayVideoIcon = styled(PlayCircleOutlined)`
	margin-right: 4px;
	color: #47cb38;
	background: #d9f1d6;
	padding: 6px;
	font-size: 16px;
	border-radius: 50%;
`;

export const orderObj: any = {
	ascend: "asc",
	descend: "desc",
};

export const videoFormFields = [
	"video_ref_id",
	"audience_language",
	"video_url",
	"video_file_name",
	"video_language",
	"dub_language",
	"sub_language",
	"duration",
	"gracenote_episodic_tms_id",
	"video_tags",
	"meta_id",
	"video_type",
	"video_size",
	"video_height",
	"video_width",
	"zype_category_values",
	"0_metaLanguage",
	"0_title",
	"0_clean_title",
	"0_keywords",
	"0_description_100",
	"0_description_250",
	"0_description_full",
	"video_lists",
	"video_updated_at",
];

export const useVideosHooks = () => {
	const {
		languagesList,
		tagsList,
		setTagsList,
		seriesList,
		loading,
		setLoading,
		distributionPlatformData,
		user,
		setUser,
		videoLists,
	}: any = useOutletContext();
	const {
		distributionCheckedList,
		setDistributionCheckedList,
		distributionChange,
		onDistributionCheckAllChange,
		checkAll,
		indeterminate,
	} = useDistributionData();
	const [searchParams] = useSearchParams();
	const param = useParams();
	const navigate = useNavigate();
	const [tagsObj, setTagsObj] = useState<any>();
	const [timeoutId, setTimeoutId] = useState<NodeJS.Timeout | undefined>(undefined);
	const [searchQuery, setSearchQuery] = useState<string>("");
	const [videosData, setVideosData] = useState<VideosInterFace[]>([]);
	const [isOpenVideosForm, setIsOpenVideosForm] = useState<boolean>(false);
	const [isMetadataInfoModalOpen, setIsMetadataInfoModalOpen] = useState<boolean>(false);
	const [formArray, setFormArray] = useState(["1"]);
	const [videoTitle, setVideoTitle] = useState<string>("");
	const [metadataInfo, setMetadataInfo] = useState<any[]>([]);
	const [videoEditId, setVideoEditId] = useState<string | undefined>();
	const [metadataEditId, setMetadataEditId] = useState<string | undefined>();
	const [durationValue, setDurationValue] = useState<string>("");
	const [relatedMetadata, setRelatedMetadata] = useState<MetadataType[]>([]);
	const [isAddNewMetadata, setIsAddNewMetadata] = useState<boolean>(false);
	const [form] = Form.useForm();
	const [ref_id, setRefId] = useState<string | undefined>();
	const [currentTagsData, setCurrentTagsData] = useState<string[]>([]);
	const [episodeId, setEpisodeId] = useState<null | string | undefined>(null);
	const [isFilterVideoModalOpen, setIsFilterVideoModalOpen] = useState<boolean>(false);
	const [videosSortObj, setVideoSortObj] = useState<SortObj>({
		column: "ref_id",
		order: "asc",
	});
	const [videoFilterObj, setVideoFilterObj] = useState<VideoFilterObj>({
		showHiddenEpisodes: user.show_hidden_episodes,
	});
	const [isFilteredData, setIsFilteredData] = useState<boolean>(false);
	const [isExportModalOpen, setIsExportModalOpen] = useState<boolean>(false);
	const [editVideoData, setEditVideoData] = useState<VideosInterFace[]>();
	const [videoPlayerDetails, setVideoPlayerDetails] = useState<{
		url: string;
		signedURL: string;
		fileName: string;
	}>();
	const [pagination, setPagination] = useState<PaginationProps>({
		...initialPaginationObj,
		pageSize: user.page_sizes?.videos ?? 10,
	});
	const [selectedVideoData, setSelectedVideoData] = useState<SelectedVideoData[]>([]);
	const [isAddToListModalOpen, setIsAddToListModalOpen] = useState<boolean>(false);
	const [isAddToDistributorsModalOpen, setIsAddToDistributorsModalOpen] = useState<boolean>(false);
	const [isBulkTaggingModalOpen, setIsBulkTaggingModalOpen] = useState<boolean>(false);
	const [exportFormat, setExportFormat] = useState<string>("videos");
	const [isBulkDeleteModalOpen, setIsBulkDeleteModalOpen] = useState<boolean>(false);
	const [activeDatesList, setActiveDatesList] = useState<ActiveDatesObj[]>([]);

	useEffect(() => {
		setLoading(true);
		if (param.videoId) {
			paramDataHandler(param.videoId);
		} else {
			getVideosDataHandler();
			// set selectedVideoData from local storage
			const selectedVideoData = JSON.parse(sessionStorage.getItem("selectedVideoData") || "[]");
			setSelectedVideoData(selectedVideoData);
		}
	}, [
		pagination.current,
		pagination.pageSize,
		videosSortObj,
		videoFilterObj,
		searchQuery,
		searchParams,
	]);

	const getVideosDataHandler = async () => {
		const filterObj = handleSavedFilterData();
		await getVideosData(
			setVideosData,
			setLoading,
			pagination,
			videosSortObj,
			searchQuery,
			filterObj
		);
	};

	useEffect(() => {
		const filterObj = handleSavedFilterData();
		getTotalDataCount("video", pagination, setPagination, {
			...filterObj,
			searchQuery,
		});
	}, [videoFilterObj, searchQuery, searchParams]);

	useEffect(() => {
		const selectedVideoData = JSON.parse(sessionStorage.getItem("selectedVideoData") || "[]");
		const selectedVideoKeys = selectedVideoData.map((video: any) => video.key);
		handleSelectedRowStyling(selectedVideoKeys);
	}, [videosData]);

	const paramDataHandler = async (videoId: string) => {
		const videoData = await getVideoById(videoId);
		const record: any = videoData?.[0];
		enableEditForm(record);
	};

	const handleSavedFilterData = () => {
		// Parse the URL to get the search parameters
		const searchParams = new URLSearchParams(window.location.search);
		// Get the value of the 'list_id' parameter
		const listId = searchParams.get("list_id");
		let filterObj = videoFilterObj ?? {};
		if (listId) {
			filterObj["video_list_ids"] = [listId];
			form.setFieldValue("video_list_ids", [listId]);
			setVideoFilterObj(filterObj);
			setIsFilteredData(true);
		}
		const tempFilter = sessionStorage.getItem("videoFilter");
		if (!tempFilter) return filterObj;
		setIsFilteredData(true);
		const savedFilterObj = JSON.parse(tempFilter);
		filterObj = { ...filterObj, ...savedFilterObj, showHiddenEpisodes: user.show_hidden_episodes };
		const {
			series,
			season,
			audience_language,
			video_tags,
			ref_id,
			not_tagged,
			distribution_platforms,
			not_distribution_platforms,
			video_type,
		} = savedFilterObj;
		form.setFieldsValue({
			series_filter: series,
			season_filter: season,
			audience_language_filter: audience_language,
			video_tags_filter: video_tags,
			ref_id_filter: ref_id,
			video_not_tagged: not_tagged,
			distribution_platforms_filter: distribution_platforms,
			not_distribution_platforms_filter: not_distribution_platforms,
			type_filter: video_type,
		});
		sessionStorage.removeItem("videoFilter");
		return filterObj;
	};

	const handleTableChange = (pagination: PaginationProps, _: any, sorter: any, { action }: any) => {
		switch (action) {
			case "paginate": {
				if (pagination.pageSize !== user.page_sizes.videos) {
					const updatedSettingsData: SETTINGS_DATA = {
						user_id: user.user_id,
						page_sizes: { ...user.page_sizes, videos: pagination.pageSize },
					};
					updateUserPageSizeSettings(updatedSettingsData);
					setUser({ ...user, ...updatedSettingsData });
				}
				setPagination(pagination);
				break;
			}
			case "sort": {
				if (!sorter.order) return;
				const videosSortObj = { column: sorter.field, order: orderObj[sorter.order] };
				setVideoSortObj(videosSortObj);
				break;
			}
		}
	};

	const metadataSelector = (value: string) => {
		metadataSelectHandler(
			value,
			form,
			setMetadataEditId,
			setIsAddNewMetadata,
			setFormArray,
			relatedMetadata
		);
	};

	const changeHandler = () => {
		const ref_id = form.getFieldValue("video_ref_id");
		setRefId(ref_id);
	};

	const goToVideoInitialPage = () => {
		navigate(HomeTabKeyEnum.VIDEOS);
		setLoading(true);
		getVideosDataHandler();
	};

	const handleCancel = () => {
		if (param) {
			goToVideoInitialPage();
		}
		setFormArray(["1"]);
		resetVideoFormFields(form);
		setIsOpenVideosForm(false);
		setVideoEditId(undefined);
		setMetadataEditId(undefined);
		setDurationValue("");
		setIsAddNewMetadata(false);
		setRefId(undefined);
		setDistributionCheckedList([]);
		setEditVideoData([]);
		setActiveDatesList([]);
	};

	const addNewInputHelper = () => {
		const tempArray: any = formArray.map(item => item);
		tempArray.push((formArray.length + 1).toString());
		setFormArray(tempArray);
	};

	const enableEditForm = async (record: VideoRecordType) => {
		const { id, metadata_id, ref_id, episode_id } = record;
		message.loading("Fetching Video Related Metadata...");
		form.setFieldValue("video_ref_id", ref_id);
		setRefId(ref_id);
		setIsOpenVideosForm(true);
		setLoading(true);
		const response = await authAxios({
			method: "GET",
			url: `${process.env.REACT_APP_BACK_END_API}/snowflake/metadata/?filter=${JSON.stringify({
				ref_id,
			})}`,
		});
		message.success("Data fetched successfully");
		setRelatedMetadata(response.data.rows);
		setEpisodeId(episode_id);
		setIsAddNewMetadata(Boolean(record.metadata_id));
		setVideoEditId(id);
		setMetadataEditId(metadata_id);
		setEditVideoData([record]);
		videoEditHelper(
			record,
			setDurationValue,
			setCurrentTagsData,
			form,
			setDistributionCheckedList,
			setActiveDatesList
		);
		setFormArray(record.metadata_id ? ["1"] : []);
		setIsOpenVideosForm(true);
		setLoading(false);
	};

	const videoDataHandler = async (formArray: any) => {
		try {
			const data = await form.validateFields();
			setLoading(true);
			const response = await saveUpdateVideoData(
				formArray,
				data,
				metadataEditId,
				episodeId,
				currentTagsData,
				distributionCheckedList,
				videoEditId,
				activeDatesList
			);
			if (!response.data.err) {
				message.success(response.data.message);
				getVideosDataHandler();
				setIsOpenVideosForm(false);
				// form.resetFields();
				setFormArray(["1"]);
				setVideoEditId(undefined);
				setMetadataEditId(undefined);
				setDurationValue("");
				setIsAddNewMetadata(false);
				setEpisodeId(null);
				setRefId(undefined);
				setDistributionCheckedList([]);
				setEditVideoData([]);
			} else {
				setLoading(false);
			}
		} catch (err) {
			setLoading(false);
			console.error("error in saving video data in video hook", err);
		}
	};

	const deleteVideosData = async (record: VideoRecordType, deleteVideo?: boolean) => {
		try {
			setLoading(true);
			const config = deleteVideo
				? {
						method: "delete",
						url: `${process.env.REACT_APP_BACK_END_API}/snowflake/video/${record.id}`,
						headers: {},
				  }
				: {
						method: "patch",
						url: `${process.env.REACT_APP_BACK_END_API}/snowflake/video`,
						data: JSON.stringify({ record }),
						headers: { "Content-Type": "application/json" },
				  };

			const response = await authAxios(config);
			if (!response.data.err) {
				message.success(response.data.message);
				getVideosDataHandler();
			}
		} catch (err) {
			console.error("eror", err);
			setLoading(false);
		}
	};

	const handleAddCustomTag = async (
		customTagValue: string,
		related_to: "video" | "thumbnail",
		insertValue: (tagId: string) => void
	) => {
		try {
			setLoading(true);
			const tagId = uuidV4();
			const values = {
				id: tagId,
				title: customTagValue,
				related_to,
			};
			let tempTagsObj = tagsObj;
			if (!tagsObj) {
				const tempObj: { [key: string]: boolean } = {};
				tagsList.forEach((tag: TagOption) => {
					tempObj[`${tag.title.toLowerCase()}-${tag.related_to}`] = true;
				});
				tempTagsObj = tempObj;
				setTagsObj(tempObj);
			}
			await saveTagsData(values, tempTagsObj);
			setTagsList([...tagsList, values]);
			setLoading(false);
			insertValue(tagId);
		} catch (error) {
			console.error("useVideosHooks ~ error:", error);
			setLoading(false);
		}
	};

	const handleCloseMetadataModal = () => {
		setIsMetadataInfoModalOpen(false);
	};

	const getFileteredVideoData = async () => {
		try {
			const {
				series_filter,
				season_filter,
				audience_language_filter,
				video_tags_filter,
				ref_id_filter,
				video_not_tagged,
				distribution_platforms_filter,
				not_distribution_platforms_filter,
				type_filter,
				video_list_ids,
			} = await form.validateFields();
			setIsFilterVideoModalOpen(false);
			if (
				series_filter?.length ||
				season_filter?.length ||
				audience_language_filter?.length ||
				video_tags_filter?.length ||
				ref_id_filter?.length ||
				video_not_tagged?.length ||
				type_filter?.length ||
				distribution_platforms_filter?.length ||
				not_distribution_platforms_filter?.length ||
				video_list_ids?.length
			) {
				setIsFilteredData(true);
				setVideoFilterObj({
					showHiddenEpisodes: user.show_hidden_episodes,
					audience_language: audience_language_filter,
					ref_id: ref_id_filter,
					season: season_filter,
					series: series_filter,
					video_tags: video_tags_filter,
					distribution_platforms: distribution_platforms_filter,
					not_distribution_platforms: not_distribution_platforms_filter,
					not_tagged: video_not_tagged,
					video_type: type_filter,
					video_list_ids,
				});
			}
		} catch (err) {
			console.error("error in filte", err);
		}
	};

	const filterModalCancelHandler = () => {
		setIsFilterVideoModalOpen(false);
	};

	const clearFilter = () => {
		const searchParams = new URLSearchParams(window.location.search);
		// Get the value of the 'list_id' parameter
		const listId = searchParams.get("list_id");
		form.resetFields([
			"series_filter",
			"audience_language_filter",
			"video_tags_filter",
			"video_not_tagged",
			"ref_id_filter",
			"season_filter",
			"distribution_platforms_filter",
			"not_distribution_platforms_filter",
			"video_list_ids",
		]);
		setIsFilteredData(false);
		sessionStorage.removeItem("videoFilter");
		setVideoFilterObj({ showHiddenEpisodes: user.show_hidden_episodes });
		if (listId) {
			goToVideoInitialPage();
		}
	};

	const exportCSV = async () => {
		try {
			const {
				series_filter,
				audience_language_filter,
				video_tags_filter,
				ref_id_filter,
				season_filter,
				video_not_tagged,
				distribution_platforms_filter,
				type_filter,
				not_distribution_platforms_filter,
				video_list_ids,
				format,
				secondary_platforms,
			} = await form.getFieldsValue();

			if (!format)
				return notification.error({
					message: "Error!",
					description: "Please select a format",
				});

			const searchParams = new URLSearchParams(window.location.search);
			const listId = searchParams.get("list_id");

			const data = {
				series: series_filter ?? [],
				audience_language: audience_language_filter ? [audience_language_filter] : [],
				video_tags: video_tags_filter ?? [],
				video_not_tagged: video_not_tagged ?? [],
				distribution_platforms: distribution_platforms_filter ?? [],
				ref_id: ref_id_filter ? ref_id_filter : undefined,
				season: season_filter ? [season_filter] : [],
				include_distribution_platforms: Boolean(format === "videos-distribution"),
				video_type: type_filter,
				not_distribution_platforms: not_distribution_platforms_filter ?? [],
				video_list_ids: video_list_ids ?? (listId ? [listId] : []),
				secondary_platforms,
			};
			const exportType = format === "videos-distribution" ? "videos" : format;
			await exportCSVWithLambda(data, exportType, user.name, "Export");
		} catch (err) {
			console.error("error in exportCSV", err);
		}
	};

	const searchData = async () => {
		try {
			setPagination({ ...initialPaginationObj, pageSize: pagination.pageSize });
			searchHandler(form, timeoutId, setTimeoutId, setSearchQuery);
		} catch (err) {
			console.error({ err });
		}
	};

	const playVideo = async (record: any) => {
		const signedURL = await getSignedURL(record.url);
		if (signedURL)
			setVideoPlayerDetails({ signedURL, fileName: record.file_name, url: record.url });
		else
			notification.error({
				message: "Invalid URL",
				description: "The URL is not found. Please update URL and try again.",
			});
	};

	const closeVideo = async () => {
		setVideoPlayerDetails(undefined);
	};

	const hiddenEpisodesHandler = () => {
		updateHiddenEpisodesHandler(user.user_id, user.show_hidden_episodes);
		setUser({ ...user, show_hidden_episodes: !user.show_hidden_episodes });
		setVideoFilterObj({
			...videoFilterObj,
			showHiddenEpisodes: !user.show_hidden_episodes,
		});
	};

	const clearVideosSelection = () => {
		const selectedVideoKeys = selectedVideoData.map(video => video.key);
		handleSelectedRowStyling(selectedVideoKeys, true);
		sessionStorage.removeItem("selectedVideoData");
		setSelectedVideoData([]);
	};

	const columns: ColumnsType<any> = [
		{
			...refIdColumn,
			width: "90px",
			sorter: (a: any, b: any) => a.ref_id.localeCompare(b.ref_id),
			defaultSortOrder: "ascend",
		},
		lastModifiedCol(),
		{
			title: "Episode Status",
			dataIndex: "episode_status",
			key: "episode_status",
			width: "125px",
			// sorter: (a: any, b: any) => a.ref_id.localeCompare(b.ref_id),
		},
		{
			title: "Title",
			dataIndex: "title",
			key: "title",
			width: "150px",
			render: (value: string) => <TextWithCopyButton value={value} />,
			// defaultSortOrder: "ascend",
			// sorter: (a: any, b: any) => a.ref_id.localeCompare(b.ref_id),
		},
		{
			title: "Internal Rating",
			dataIndex: "internal_rating",
			key: "internal_rating",
			width: "125px",
		},
		{
			title: "Language",
			dataIndex: "language",
			key: "language",
			width: "100px",
		},
		{
			title: "Dub Language",
			dataIndex: "dub_language",
			key: "dub_language",
			width: "130px",
		},
		{
			title: "Sub Language",
			dataIndex: "sub_language",
			key: "sub_language",
			width: "130px",
		},
		{
			title: "Audience Language",
			dataIndex: "audience_language",
			key: "audience_language",
			width: "160px",
		},
		{
			title: "Video Url",
			dataIndex: "url",
			key: "url",
			width: "280px",
			className: "video-url",
			render: (value: string, record: any) => {
				return value?.trim()?.length ? (
					<span
						onClick={e => {
							e.stopPropagation();
							playVideo(record);
						}}
					>
						<PlayVideoIcon />
						{value}
					</span>
				) : null;
			},
		},
		{
			title: "File Name",
			dataIndex: "file_name",
			key: "file_name",
			width: "180px",
			// render: (value: string, record: any) => {
			// 	const file_name = getFileNameWithURL(record.url);
			// 	return <span>{value ?? file_name}</span>;
			// },
		},
		{
			title: "Season Number",
			dataIndex: "season",
			key: "season",
			width: "160px",
			align: "center",
			sorter: (a: any, b: any) => a.season - b.season,
		},
		{
			title: "Episode Number",
			dataIndex: "episode",
			key: "episode",
			width: "160px",
			align: "center",
			sorter: (a: any, b: any) => a.episode - b.episode,
		},
		{
			title: "Duration",
			dataIndex: "duration",
			key: "duration",
			width: "130px",
			render: (value: string) => {
				const duration = moment.duration(value, "seconds");
				const seconds = moment.utc(duration.asMilliseconds()).format("HH:mm:ss");
				return <span>{seconds}</span>;
			},
		},
		{
			title: "Tags",
			dataIndex: "tags",
			key: "tags",
			width: "220px",
			render: (value: any) => {
				return value
					? value?.split(",").map((title: string) => (
							<Tag key={title} color="success" title={title}>
								{title}
							</Tag>
					  ))
					: "";
			},
		},
		{
			title: "Distribution Platforms",
			dataIndex: "distribution_data",
			key: "distribution_data",
			width: "220px",
			render: (value: any) => {
				return value.map((platformData: any) => (
					<Tag key={platformData.id} title={platformData.platform_name}>
						{platformData.platform_name}
					</Tag>
				));
			},
		},
		{
			title: "Size(in bytes)",
			dataIndex: "size",
			key: "size",
			width: "220px",
		},
		{
			title: "Width",
			dataIndex: "video_width",
			key: "video_width",
			width: "80px",
		},
		{
			title: "Height",
			dataIndex: "video_height",
			key: "video_height",
			width: "80px",
		},
		{
			title: "Type",
			dataIndex: "type",
			key: "type",
			width: "80px",
		},
		{
			title: "Zype Category Values",
			dataIndex: "zype_category_values",
			key: "zype_category_values",
			width: "180px",
		},
		{
			title: "Lists",
			dataIndex: "list_names",
			key: "list_names",
			width: "180px",
			render: (value: any) => {
				return (
					value
						?.split(",")
						?.map((listName: string, i: number) => <Tag key={"list-name" + i}>{listName}</Tag>) ??
					""
				);
			},
		},
		{
			title: "Meta Info",
			dataIndex: "title",
			key: "title",
			width: "100px",
			align: "center",
			render: (value, record: any) => {
				const { metadata_id } = record;
				return (
					<Tooltip title={!metadata_id ? "No metadata attached yet" : null}>
						<Button
							disabled={!metadata_id}
							onClick={e => {
								e.stopPropagation();
								setIsMetadataInfoModalOpen(true);
								setVideoTitle(record.ref_id);
								setMetadataInfo([
									{
										...record,
										key: metadata_id,
									},
								]);
							}}
						>
							View
						</Button>
					</Tooltip>
				);
			},
		},
		{
			title: "Action",
			dataIndex: "action",
			key: "action",
			width: "120px",
			align: "right",
			render: (value, record: VideoRecordType) => {
				return (
					<Fragment>
						<CustomBtn
							icon={
								<Tooltip title="Edit Episode">
									<EditFilled />
								</Tooltip>
							}
							type={"link"}
							onClick={e => {
								e.stopPropagation();
								if (e.metaKey || e.ctrlKey) {
									const newTab = window.open(
										HomeTabKeyEnum.EPISODE + "/" + record.ref_id,
										"_blank"
									);
									newTab?.focus();
								} else {
									sessionStorage.setItem("videoFilter", JSON.stringify(videoFilterObj));
									navigate(HomeTabKeyEnum.EPISODE + "/" + record.ref_id);
								}
							}}
						/>
						<CustomBtn
							icon={
								<Tooltip title="Edit Video">
									<EditOutlined />
								</Tooltip>
							}
							type={"link"}
							onClick={e => {
								e.stopPropagation();
								if (e.metaKey || e.ctrlKey) {
									const newTab = window.open(HomeTabKeyEnum.VIDEOS + "/" + record.id, "_blank");
									newTab?.focus();
								} else {
									enableEditForm(record);
								}
							}}
						/>
						<CustomBtn
							onClick={e => e.stopPropagation()}
							icon={
								<Popconfirm
									okButtonProps={{ danger: true }}
									title="Delete Video?"
									description="Are you sure you want to delete this video?"
									cancelText="Cancel"
									// onCancel={() => deleteVideosData(record)}
									okText="Delete Video"
									onConfirm={() => deleteVideosData(record, true)}
								>
									<Tooltip title="Delete Video">
										<DeleteOutlined style={{ color: "#D10000" }} />
									</Tooltip>
								</Popconfirm>
							}
							type={"link"}
						/>
					</Fragment>
				);
			},
		},
	];

	const columnBulkSelect: ColumnsType<any> = getBulkSelectColumns(
		videosData,
		selectedVideoData,
		setSelectedVideoData,
		handleSelectedRowStyling,
		"selectedVideoData"
	);

	const columnVideos = columnBulkSelect.concat(columns);

	return {
		videosData,
		setIsLoading: setLoading,
		isLoading: loading,
		columnVideos,
		isOpenVideosForm,
		setIsOpenVideosForm,
		form,
		saveVideosData: videoDataHandler,
		deleteVideosData,
		formArray,
		setFormArray,
		isMetadataInfoModalOpen,
		metadataInfo,
		handleCloseMetadataModal,
		videoTitle,
		videoEditId,
		metadataEditId,
		addNewInputHelper,
		handleCancel,
		durationValue,
		setDurationValue,
		relatedMetadata,
		metadataSelector,
		isAddNewMetadata,
		ref_id,
		languages: languagesList,
		changeHandler,
		tagsData: tagsList,
		handleAddCustomTag,
		handleTableChange,
		isFilterVideoModalOpen,
		getFileteredVideoData,
		filterModalCancelHandler,
		clearFilter,
		setIsFilterVideoModalOpen,
		seriesData: seriesList,
		exportCSV,
		distributionPlatform: distributionPlatformData,
		isExportModalOpen,
		setIsExportModalOpen,
		enableEditForm,
		searchData,
		pagination,
		videoPlayerDetails,
		closeVideo,
		distributionCheckedList,
		distributionChange,
		onDistributionCheckAllChange,
		checkAll,
		indeterminate,
		editVideoData,
		videoLists,
		videoFilterObj,
		selectedVideoData,
		isAddToListModalOpen,
		setIsAddToListModalOpen,
		setSelectedVideoData,
		isAddToDistributorsModalOpen,
		setIsAddToDistributorsModalOpen,
		getVideosDataHandler,
		setDistributionCheckedList,
		isBulkTaggingModalOpen,
		setIsBulkTaggingModalOpen,
		exportFormat,
		setExportFormat,
		hiddenEpisodesHandler,
		isFilteredData,
		isBulkDeleteModalOpen,
		setIsBulkDeleteModalOpen,
		clearVideosSelection,
		activeDatesList,
		setActiveDatesList,
		setIsAddNewMetadata,
		setMetadataEditId,
	};
};
